<style scoped>
   * {
            box-sizing: border-box;
        }
        body {
            margin: 0;
            padding: 0;
        }
        th.column {
            padding: 0
        }
        a[x-apple-data-detectors] {
            color: inherit !important;
            text-decoration: inherit !important;
        }
        #MessageViewBody a {
            color: inherit;
            text-decoration: none;
        }
        p {
            line-height: inherit
        }
        @media (max-width:660px) {
            .icons-inner {
                text-align: center;
            }
            .icons-inner td {
                margin: 0 auto;
            }
            .row-content {
                width: 100% !important;
                
            }
            .stack .column {
                width: 100%;
                display: block;
            }
        }
    
    .row{
        margin-left: auto;
    }
    .row-1{
        margin-top: 20px;
    }

    .email_header{
        background-color: v-bind('theme?.email_header?.background_color?.value') !important;
    }

    .email_body{
        background-color: v-bind('theme?.email_body?.background_color?.value') !important;
        color: v-bind('theme?.email_body?.font_color?.value') !important;
    }

    .email_footer{
        background-color: v-bind('theme?.email_footer?.background_color?.value') !important;
    }

    .email_title{
        color: v-bind('theme?.email_title?.font_color?.value') !important;
    }

    .email_login{
        background-color: v-bind('theme?.email_login?.background_color?.value') !important;
        border-color: v-bind('theme?.email_login?.background_color?.value') !important;
        color: v-bind('theme?.email_login?.font_color?.value') !important;
    }

</style>
<template>
   
<body style="background-color: #bfd5ba; margin-top: 30px; margin-bottom: 30px; padding-bottom: 30px; -webkit-text-size-adjust: none; text-size-adjust: none;">
<table border="0" cellpadding="0" cellspacing="0" class="nl-container" role="presentation" style="mso-table-lspace: 0pt; mso-table-rspace: 0pt; background-color: #bfd5ba;" width="100%">
    <tbody>
    <tr>
        <td>
            <table align="center" border="0" cellpadding="0" cellspacing="0" class=" row row-1" role="presentation" style="mso-table-lspace: 0pt; mso-table-rspace: 0pt;" width="100%">
                <tbody>
                <tr>
                    <td>
                        <table align="center" border="0" cellpadding="0" cellspacing="0" class="email_header row-content stack" role="presentation" style="mso-table-lspace: 0pt; mso-table-rspace: 0pt; background-color: #ffffff;" width="640">
                            <tbody>
                            <tr>
                                <th class="column" style="mso-table-lspace: 0pt; mso-table-rspace: 0pt; font-weight: 400; text-align: left; vertical-align: top; padding-top: 25px; padding-bottom: 30px;" width="100%">
                                    <table border="0" cellpadding="0" cellspacing="0" class="image_block" role="presentation" style="mso-table-lspace: 0pt; mso-table-rspace: 0pt;" width="100%">
                                        <tbody>
                                        <tr>
                                            <td style="padding-bottom:15px;width:100%;padding-right:0px;padding-left:0px;">
                                                <div align="center" style="line-height:10px"><a href="#" style="outline:none" tabindex="-1" target="_blank">
                                                    
                                                    <template v-if="theme?.logo2" v-for="(logo, main_key, main_index) in theme.logo2">
                                                        <template v-if="main_key == 'email_logo'" v-for="(logo_value, key, index) in logo.value">
                                                            
                                                            <img alt="Logo" v-if="logo_value.is_selected" :src="logo_value?.default == '1' ? default_logo : logo_value.url" style="display: block; height: auto; border: 0; width: 128px; max-width: 100%;" title="Logo" width="128"/>
                                                        </template>
                                                    </template>
                                                
                                                </a></div>
                                            </td>
                                        </tr>
                                        </tbody>
                                    </table>
                                </th>
                            </tr>
                            </tbody>
                        </table>
                    </td>
                </tr>
                </tbody>
            </table>
            <table align="center" border="0" cellpadding="0" cellspacing="0" class="row row-2" role="presentation" style="mso-table-lspace: 0pt; mso-table-rspace: 0pt;" width="100%">
                <tbody>
                <tr>
                    <td>
                        <table align="center" border="0" cellpadding="0" cellspacing="0" class=" row-content stack" role="presentation" style="mso-table-lspace: 0pt; mso-table-rspace: 0pt; background-color: #ebf4f7;" width="640">
                            <tbody>
                            <tr>
                                <th class="column" style="mso-table-lspace: 0pt; mso-table-rspace: 0pt; font-weight: 400; text-align: left; vertical-align: top; padding-left: 20px; padding-right: 20px; padding-top: 60px; padding-bottom: 0px;" width="100%">
                                    <table border="0" cellpadding="10" cellspacing="0" class="divider_block" role="presentation" style="mso-table-lspace: 0pt; mso-table-rspace: 0pt;" width="100%">
                                        <tbody>
                                        <tr>
                                            <td>
                                                <div align="center">
                                                    <table border="0" cellpadding="0" cellspacing="0" role="presentation" style="mso-table-lspace: 0pt; mso-table-rspace: 0pt;" width="100%">
                                                    <tbody>
                                                        <tr>
                                                            <td class="divider_inner" style="font-size: 1px; line-height: 1px; border-top: 1px solid #BBBBBB;"><span></span></td>
                                                        </tr>
                                                    </tbody>
                                                    </table>
                                                </div>
                                            </td>
                                        </tr>
                                        </tbody>
                                    </table>
                                    <table border="0" cellpadding="0" cellspacing="0" class="heading_block" role="presentation" style="mso-table-lspace: 0pt; mso-table-rspace: 0pt;" width="100%">
                                    <tbody>
                                        <tr>
                                            <td style="padding-top:10px;text-align:center;width:100%;">
                                                <h1 class="email_title" style="margin: 0; color: #111111; direction: ltr; font-family: 'Oswald', Arial, 'Helvetica Neue', Helvetica, sans-serif; font-size: 32px; font-weight: normal; letter-spacing: normal; line-height: 120%; text-align: center; margin-top: 0; margin-bottom: 0;">
                                                    <strong id="email_title_text">{{ theme?.email_title?.text?.value }}</strong></h1>
                                            </td>
                                        </tr>
                                    </tbody>
                                    </table>
                                    <table border="0" cellpadding="10" cellspacing="0" class="divider_block" role="presentation" style="mso-table-lspace: 0pt; mso-table-rspace: 0pt;" width="100%">
                                    <tbody>
                                        <tr>
                                            <td>
                                                <div align="center">
                                                    <table border="0" cellpadding="0" cellspacing="0" role="presentation" style="mso-table-lspace: 0pt; mso-table-rspace: 0pt;" width="100%">
                                                    <tbody>
                                                        <tr>
                                                            <td class="divider_inner" style="font-size: 1px; line-height: 1px; border-top: 1px solid #BBBBBB;"><span></span></td>
                                                        </tr>
                                                    </tbody>
                                                    </table>
                                                </div>
                                            </td>
                                        </tr>
                                    </tbody>
                                    </table>
                                    <table border="0" cellpadding="0" cellspacing="0" class="text_block" role="presentation" style="mso-table-lspace: 0pt; mso-table-rspace: 0pt; word-break: break-word;" width="100%">
                                    <tbody>
                                        <tr>
                                            <td style="padding-bottom:25px;padding-top:25px;">
                                                <div style="font-family: sans-serif">
                                                    <div class="" style="font-size: 12px; line-height: 1.5; font-family: Arial, Helvetica Neue, Helvetica, sans-serif;">
                                                        <div style="padding: 50px;">
                                                            Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages, and more recently with desktop publishing software like Aldus PageMaker including versions of Lorem Ipsum.
                                                        </div>
                                                    </div>
                                                </div>
                                            </td>
                                        </tr>
                                    </tbody>
                                    </table>
                                    <table border="0" cellpadding="10" cellspacing="0" class="divider_block" role="presentation" style="mso-table-lspace: 0pt; mso-table-rspace: 0pt;" width="100%">
                                    <tbody>
                                        <tr>
                                            <td>
                                                <div align="center">
                                                    <table border="0" cellpadding="0" cellspacing="0" role="presentation" style="mso-table-lspace: 0pt; mso-table-rspace: 0pt;" width="100%">
                                                    <tbody>
                                                        <tr>
                                                            <td class="divider_inner" style="font-size: 1px; line-height: 1px; border-top: 1px solid #BBBBBB;"><span></span></td>
                                                        </tr>
                                                    </tbody>
                                                    </table>
                                                </div>
                                            </td>
                                        </tr>
                                    </tbody>
                                    </table>
                                    <table border="0" cellpadding="0" cellspacing="0" class="button_block" role="presentation" style="mso-table-lspace: 0pt; mso-table-rspace: 0pt;margin-bottom: 15px" width="100%">
                                    <tbody>
                                        <tr>
                                            <td style="padding-top:5px;text-align:center;">
                                                <div align="center">
                                                    <!--[if mso]>
                                                    <v:roundrect xmlns:v="urn:schemas-microsoft-com:vml" xmlns:w="urn:schemas-microsoft-com:office:word" href="@{outputs('Get_item')?['body/SharepointAddress']}" style="height:54px;width:269px;v-text-anchor:middle;" arcsize="0%" strokeweight="1.5pt" strokecolor="#167000" fillcolor="#167000">
                                                       <w:anchorlock/>
                                                       <v:textbox inset="0px,0px,0px,0px">
                                                          <center style="color:#ffffff; font-family:Arial, sans-serif; font-size:20px">
                                                             <![endif]-->
                                                    <a class="email_login" href="https://toolbox.cpras.co.uk/login" style="text-decoration:none;display:inline-block;border-radius:0px;width:auto;border-top:2px solid #167000;border-right:2px solid #167000;border-bottom:2px solid #167000;border-left:2px solid #167000;padding-top:5px;padding-bottom:5px;font-family:Arial, Helvetica Neue, Helvetica, sans-serif;text-align:center;mso-border-alt:none;word-break:keep-all;" target="_blank"><span style="padding-left:40px;padding-right:40px;font-size:20px;display:inline-block;letter-spacing:normal;"><span style="font-size: 16px; line-height: 2; word-break: break-word; mso-line-height-alt: 32px;"><span data-mce-style="font-size: 20px; line-height: 40px;" style="font-size: 20px; line-height: 40px;">Login to CPRAS Procurement Platform</span></span></span></a>
                                                    <!--[if mso]>
                                                    </center>
                                                    </v:textbox>
                                                    </v:roundrect>
                                                    <![endif]-->
                                                    <p style="margin: 0; font-size: 16px; mso-line-height-alt: 18px;"> </p>
                                                </div>
                                            </td>
                                        </tr>
                                    </tbody>
                                    </table>
                                </th>
                            </tr>
                            </tbody>
                        </table>
                    </td>
                </tr>
                </tbody>
            </table>
            <table align="center" border="0" cellpadding="0" cellspacing="0" class="row row-3" role="presentation" style="mso-table-lspace: 0pt; mso-table-rspace: 0pt;" width="100%">
                <tbody>
                <tr>
                    <td>
                        <table align="center" border="0" cellpadding="0" cellspacing="0" class="email_footer row-content stack" role="presentation" style="mso-table-lspace: 0pt; mso-table-rspace: 0pt; background-color: #ffffff;" width="640">
                            <tbody>
                            <tr>
                                <th class="column" style="mso-table-lspace: 0pt; mso-table-rspace: 0pt; font-weight: 400; text-align: left; vertical-align: top; padding-top: 0px; padding-bottom: 0px;" width="100%">
                                    <div class="spacer_block" style="line-height:60px;">
                                        <div align="center" style="line-height:10px"><a href="#" style="outline:none" tabindex="-1" target="_blank">
                                            
                                            <template v-if="theme?.logo2" v-for="(logo, main_key, main_index) in theme.logo2">
                                                        <template v-if="main_key == 'email_logo'" v-for="(logo_value, key, index) in logo.value">
                                                            
                                                            <img alt="Logo" v-if="logo_value.is_selected" :src="logo_value?.default == '1' ? default_logo : logo_value.url" style="display: block; height: auto; border: 0; width: 128px; max-width: 100%;" title="Logo" width="128"/>
                                                        </template>
                                                    </template>

                                            </a></div>
                                    </div>
                                </th>
                            </tr>
                            </tbody>
                        </table>
                    </td>
                </tr>
                </tbody>
            </table>
            <table align="center" border="0" cellpadding="0" cellspacing="0" class="row row-4" role="presentation" style="mso-table-lspace: 0pt; mso-table-rspace: 0pt;" width="100%">
                <tbody>
                <tr>
                    <td>
                        <table align="center" border="0" cellpadding="0" cellspacing="0" class="email_footer row-content stack" role="presentation" style="mso-table-lspace: 0pt; mso-table-rspace: 0pt; background-color: #ebf4f7; background-image: url(''); background-position: center top; background-repeat: no-repeat;" width="640">
                            <tbody>
                            <tr>
                                <th class="column" style="mso-table-lspace: 0pt; mso-table-rspace: 0pt; font-weight: 400; text-align: left; vertical-align: top; padding-left: 20px; padding-right: 20px; padding-top: 35px; padding-bottom: 35px;" width="100%">
                                    <table border="0" cellpadding="0" cellspacing="0" class="image_block" role="presentation" style="mso-table-lspace: 0pt; mso-table-rspace: 0pt;" width="100%">
                                    <tbody>
                                        <tr>
                                            <td style="padding-bottom:10px;width:100%;padding-right:0px;padding-left:0px;">
                                            </td>
                                        </tr>
                                    </tbody>
                                    </table>

                                    <table border="0" cellpadding="0" cellspacing="0" class="text_block" role="presentation" style="mso-table-lspace: 0pt; mso-table-rspace: 0pt; word-break: break-word;" width="100%">
                                    <tbody>
                                        <tr>
                                            <td style="padding-bottom:10px;padding-top:10px;">
                                                <div style="font-family: sans-serif">
                                                    <div style="font-size: 12px; color: #111; line-height: 1.2; font-family: Arial, Helvetica Neue, Helvetica, sans-serif;">
                                                        <p style="margin: 0; font-size: 14px; text-align: center;"><span style="font-size:16px;"> © All rights reserved</span></p>
                                                    </div>
                                                </div>
                                            </td>
                                        </tr>
                                    </tbody>
                                    </table>
                                </th>
                            </tr>
                            </tbody>
                        </table>
                    </td>
                </tr>
                </tbody>
            </table>
            <table align="center" border="0" cellpadding="0" cellspacing="0" class="row row-5" role="presentation" style="mso-table-lspace: 0pt; mso-table-rspace: 0pt;" width="100%">
                <tbody>
                <tr>
                    <td>
                        <table align="center" border="0" cellpadding="0" cellspacing="0" class="email_footer row-content stack" role="presentation" style="mso-table-lspace: 0pt; mso-table-rspace: 0pt;" width="640">
                            <tbody>
                            <tr>
                                <th class="column" style="mso-table-lspace: 0pt; mso-table-rspace: 0pt; font-weight: 400; text-align: left; vertical-align: top; padding-top: 5px; padding-bottom: 5px;" width="100%">
                                    <table border="0" cellpadding="0" cellspacing="0" class="icons_block" role="presentation" style="mso-table-lspace: 0pt; mso-table-rspace: 0pt;" width="100%">
                                    <tbody>
                                        <tr>
                                            <td style="color:#9d9d9d;font-family:inherit;font-size:15px;padding-bottom:5px;padding-top:5px;text-align:center;">
                                                <table cellpadding="0" cellspacing="0" role="presentation" style="mso-table-lspace: 0pt; mso-table-rspace: 0pt;" width="100%">
                                                <tbody>
                                                    <tr>
                                                        <td style="text-align:center;">
                                                            <!--[if vml]>
                                                            <table align="left" cellpadding="0" cellspacing="0" role="presentation" style="display:inline-block;padding-left:0px;padding-right:0px;mso-table-lspace: 0pt;mso-table-rspace: 0pt;">
                                                            <![endif]-->
                                                            <!--[if !vml]><!-->
                                                            <table cellpadding="0" cellspacing="0" class="icons-inner" role="presentation" style="mso-table-lspace: 0pt; mso-table-rspace: 0pt; display: inline-block; margin-right: -4px; padding-left: 0px; padding-right: 0px;">
                                                                <!--<![endif]-->
                                                            </table>
                                                        </td>
                                                    </tr>
                                                </tbody>
                                                </table>
                                            </td>
                                        </tr>
                                    </tbody>
                                    </table>
                                </th>
                            </tr>
                            </tbody>
                        </table>
                    </td>
                </tr>
                </tbody>
            </table>
        </td>
    </tr>
    </tbody>
</table>
</body>
  </template>
  
  <script>

  import CPRAS_logo from '@/assets/cpras_email_logo.jpg'

  export default {
    props: {
        data:{
          type: Object,
          default: null
        }
    },
    computed: {
        
    },
    emits :{
        
    },
    data() {
        return {
            default_logo: CPRAS_logo,
            root_path: process.env.VUE_APP_API_ROOT,
            theme: null,
            user_theme: null
        }
    },
    watch: {
      
    },
    created: function() {
        this.theme = this.data
        this.changeValue()
    },
    methods: {
        changeValue(){
            //document.getElementById('email_title_text').innerText = "haha" //this.theme?.email_title?.text?.value;
        }
    }
  }
  </script>
  