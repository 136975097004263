<template>
    <div>
        <div class="sticky-container">
          <div class="row">
            <div class="col-lg-9">
              <p class="text-white">
                <router-link class="text-white" to="/main/pin_notices"
                  >&#8592; Back to PIN Notice list</router-link
                >
              </p> 
            </div>
          </div>
        </div>
       
        <PinNoticeView v-if="selected" :forum_related_data="forum_related_data" :selected_framework="selected"></PinNoticeView>

    </div>
  </template>
  
  <script>
  import PinNoticeView from './PinNoticeView'
  import { PinNotices } from '@/api_services/pin_notices'

  export default {
    components: {
        PinNoticeView
    },
    data() {
      return {
        selected: null,
        forum_related_data: {}
      }
    },
    created: function() {
        if (this.$route.params.id) {
            this.loadPinNotice(this.$route.params.id)
        } else {
            alert('Page not found')
        }
    },
    methods: { 
        loadPinNotice(id){
          PinNotices.show(id)
          .then(result => {
            if (result.data.pin_notice) {
              this.selected = result.data.pin_notice

              this.forum_related_data["model_info"] = result.data.model_info
              this.forum_related_data["forum_related_info"] = result.data.forum_related_info
              this.forum_related_data["user_forum_related_info"] = result.data.user_forum_related_info
            }
            else{
              this.$toast.error('No data found')
            }
          })
          .catch(error => {
            this.$toast.error('An error occured while fetching request')
            console.error('error:', error.response.data)
          })
        },
    }
  }
  </script>
  
  <style scoped>
  
  .ql-container{
    height: auto !important;
  }
  
  .character-limit-exceeded {
    color: red !important; /* You can use a different color or styling to indicate the limit exceeded */
  }
  .sticky-container {
      position: sticky;
      top: 0; /* Adjust this value based on your layout */
      background-color: #2BCE89; /* Change the background color if needed */
      padding: 10px;
      z-index: 1000; /* Add z-index to ensure it's above other content */
    }
  
  ::v-deep .custom-file,
  ::v-deep .custom-file-input {
    height: auto !important;
  }
  
  ::v-deep .custom-file-label {
    display: none !important;
  }
  </style>