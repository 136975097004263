<template>
    <div>
        <el-button type="primary" @click="getPrevious()" v-if="previous_form">Fetch Previous Data</el-button>
    </div>
    <div class="card-body">
        <el-card>
            <div class="row">
                <div class="col-md-12">
                    <label>Number of contracting authorities</label>
                    <br/><label style="color: #a9a9a9">More than 1 for joint procurements. Do not include others that may use a dynamic market or framework that is being established.</label>
                    <div :class="{ 'has-error-field': v.form.number_of_ca.$invalid }">
                        <el-input v-model="form.number_of_ca" style="width: 100%;"></el-input>
                    </div>
                    <div v-if="v.form.number_of_ca.$invalid" class="error-text" >Number of contracting authorities is required and at least 1 or more</div>
                </div>
                <div class="col-md-12">
                    <br/><label>Are other organisations carrying out the procurement, or part of it, on behalf of the contracting authorities?</label>
                    <br/><label style="color: #a9a9a9">For example, providing assurance services, handling enquiries, assessing tenders or managing contracts.</label>
                    <div :class="{ 'has-error-field': v.form.has_other_organisation.$invalid }">
                        <el-radio-group :disabled="true" v-model="form.has_other_organisation" @change="() => { form.number_other_org = null }" placeholder="Select" style="width: 100%;">
                            <el-radio :label="'Yes'" :value="true"></el-radio>
                            <el-radio :label="'No'" :value="false"></el-radio>
                        </el-radio-group>
                    </div>
                    <div v-if="v.form.has_other_organisation.$invalid" class="error-text" >Yes/No is required.</div>
                </div>
                <div class="col-md-12" v-if="form.has_other_organisation == true">
                    <br/><label>Number of other organisations</label>
                    <br/><label style="color: #a9a9a9">Number of organisations has at least one by default for CPRAS information to be included in the procurement.</label>
                    <div :class="{ 'has-error-field': v.form.number_other_org.$invalid }">
                        <el-input v-model="form.number_other_org" style="width: 100%;"></el-input>
                    </div>
                    <div v-if="v.form.number_other_org.$invalid" class="error-text" >Number of other organisations is required and at least 1 or more</div>
                </div>
            </div>
        </el-card>
    </div>
    <span class="col-md-3 mb-3" v-if="is_not_submitted"><el-button type="success" :disabled="v.form.$invalid" @click="saveForm()">Save and go to Next Section</el-button></span>
 </template>
 
 <script>
    import { mapState } from 'vuex'
     import {
     useVuelidate
     } from '@vuelidate/core'
     import {
     required, requiredIf, numeric
     } from '@vuelidate/validators'
 
     const CONTRACTING_AUTH = 'CONTRACTING_AUTH';

     export default {
         props: ['previous_form', 'user', 'procurement_id', 'saved_form', 'is_not_submitted', 'procurement_status', 'tpp_procurement_type'],
         data() {
             return {
                form: {
                    number_of_ca: null,
                    has_other_organisation: true,
                    number_other_org: 1
                }
             }
         },
        setup: () => ({
             v: useVuelidate()
        }),
        created() {
            if(this.saved_form) this.form = this.saved_form

        },
        computed: {
        ...mapState(['TPPNoticeTypes', 'TPPFormParts']),
        },
        watch: {
            saved_form: {
                handlers(form){
                    this.form = form
                },
                deep: true
            }
        },
        validations() {
            const positive = value => value > 0
            const postiveOpt = value => this.form.has_other_organisation ? value > 0 : true

            let rules = {
                form: {
                    number_of_ca: {
                        required,
                        numeric,
                        positive,
                        $autoDirty: true
                    },
                    has_other_organisation: {
                        required,
                        $autoDirty: true
                    },
                    number_other_org: {
                        required: requiredIf(function() { return this.form.has_other_organisation }),
                        numeric,
                        postiveOpt,
                        $autoDirty: true
                    }
                }
                
            }
            return rules
        },
        methods: {
            saveForm() {
                this.$emit('save', CONTRACTING_AUTH, this.form)
            },
            getPrevious() {
                console.log('previous', this.previous_form)
                delete this.previous_form.id
                this.form = this.previous_form
            }
        }

     }
 </script>
 