import api from './api'

const API = '/api/application_theme'

export const ApplicationTheme = {
    uploadImage: function(params) {
        return api.createFormData(API + "/upload-image", params)
    },
    removeFile: function(params) {
        return api.delete(API+'/remove-file/'+params.id, params)
    },
}