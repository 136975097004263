<template>
  <div class="d-flex align-items-center">

    <div v-if="!reverse_total" class="me-auto text-xs font-weight-bolder">
      {{ meta.total ? 'Total :' + meta.total : '' }}
    </div>

    <vue-awesome-paginate 
      style="margin-bottom: 0px !important;"
      :total-items="meta.total"
      :items-per-page="meta.per_page"
      :max-pages-shown="5"
      v-model="page"
      :on-click="onClickHandler"
      v-if="meta.total"
      paginate-buttons-class="el-button el-button--small "
      active-page-class="btn-secondary"
      :show-jump-buttons="true"
      :show-breakpoint-buttons="false"
      :prev-button-content="prev_button_content"
      :next-button-content="next_button_content"
      :hide-prev-next-when-ends="hide_prev_next_when_ends"
    />

    <div v-if="reverse_total" class="ms-auto text-xs ">
      {{ meta.total ? 'Total Records: ' + meta.total : '' }}
    </div>

  </div>
</template>

<script>
export default {
  name: 'PaginateComponent',
  props: {
    meta: {
      type: Object,
      required: true
    },
    reverse_total: {
      type: Boolean,
      required: false
    },
    prev_button_content: {
      type: String,
      required: false,
      default: "<"
    },
    next_button_content: {
      type: String,
      required: false,
      default: ">"
    },
    hide_prev_next_when_ends: {
      type: Boolean,
      required: false,
      default: false
    },
  },
  data() {
    return {
      page: 1,
      perPage: 1
    }
  },
  watch: {
    page: function () {
      if(this.$parent.meta?.page) this.$parent.meta.page = this.page
      //console.log(this.page)
      // props.meta.page = this.page
      // this.$emit('update')
      this.$emit('update', this.page);
    },
    meta:{
      handler(value){
        this.page = value.page
      },
      deep:true
    }
  },
  created: function () {

  },
  methods: {
    onClickHandler(page){
      let self = this;
      self.meta.page = this.page
      console.log(page)
      this.$emit('update')
    }
  }
}
</script>

<style scoped>
.pagination-container {
    display: flex;
    column-gap: 10px;
  }
  btn {
    height: 40px;
    width: 40px;
    border: none;
    margin-inline: 5px;
    cursor: pointer;
  }
  .back-btn {
    background-color: red;
  }
  .next-btn {
    background-color: red;
  }
  .btn-active {
    background-color: blue !important;
    color: white;
  }
</style>
