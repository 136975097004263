
<template>
    <div class="">
      <div class="pb-0 card-header">
        <h5 class="font-weight-bolder mb-0">{{title}}</h5>
      </div>
      <div class="card-body">
      </br>
        <form @submit.prevent.prevent="" @reset.prevent="onCancel">
  
          <div class="form-group">
            <label class="form-label">Category Name *</label>
            <input type="text" v-model.trim="form.name"
            :class="{ 'is-invalid': v$.form.name.$errors.length, 'form-control form-control-sm': true }">
  
            <div class="input-errors" v-for="error of v$.form.name.$errors" :key="error.$uid">
              <div class="error-text">Category Name {{ error.$message }}</div>
            </div>
          </div>


          <div class="form-group">
            <label class="form-label">Description *</label>
            <input type="text" v-model.trim="form.description"
            :class="{ 'is-invalid': v$.form.description.$errors.length, 'form-control form-control-sm': true }">
  
            <div class="input-errors" v-for="error of v$.form.description.$errors" :key="error.$uid">
              <div class="error-text">Description {{ error.$message }}</div>
            </div>
          </div>

          <div class="form-group">
            <label class="form-label">Rules *</label>

            <quill-editor @ready="onEditorReady($event)" id="rules_quill" contentType="html"
                    v-model:content="form.rules" :options="editorOptions" @change="descChangeEvnt($event)"></quill-editor>
  
            <div class="input-errors" v-for="error of v$.form.rules.$errors" :key="error.$uid">
              <div class="error-text">Rules {{ error.$message }}</div>
            </div>
          </div>

          <!--<div class="form-group">
            <label class="form-label">Parent Category (Optional)</label>
            <el-select filterable v-model="form.parent_id" placeholder="Select or Search a Category" >
                <el-option :key="''" :label="'No parent category'" :value="null" />
                  <el-option
                    v-for="category in parent_categories"
                    :key="'category_'+category.id"
                    :label="category.name"
                    :value="category.id"
                  />
            </el-select>
          </div>-->


  
          <div class="pt-4 text-end form-buttons">
            <button type="reset" class="btn btn-secondary" :disabled="isSaving">Cancel</button>
            <button type="button" @click="onSubmit" class="btn btn-success" :disabled="isSaving">{{isSaving ? 'Saving...' : 'Save'}}</button>
          </div>
        </form>
      </div>
    </div>
    
  </template>
  
  <script>
    import { Forum } from '@/api_services/forum'
    import { useVuelidate } from '@vuelidate/core'
    import { required, email, requiredIf } from '@vuelidate/validators'
    import { quillEditor } from 'vue3-quill'
    import 'quill/dist/quill.core.css';
    import 'quill/dist/quill.snow.css'; 
    import 'quill/dist/quill.bubble.css';

    export default {
      emits: ["save", "cancel"],
      setup () {
        return { v$: useVuelidate() }
      },
      props: {
        selected: Object
      },
      components: {
        quillEditor
      },
      data() {
        return {
          parent_categories: [],
          isSaving: false,
          form:{
            name: null,
            description: null,
            rules: null,
            parent_id: null,
            is_sub: 0
          },
          editorOptions: {
            placeholder: '....',
            theme: 'snow',
            modules: {
              toolbar: [
              ['italic', 'underline', 'strike'],        // toggled buttons
              ['blockquote', 'code-block'],
              ['link', 'formula'],

              [{ 'header': 1 }, { 'header': 2 }],               // custom button values
              [{ 'list': 'ordered'}, { 'list': 'bullet' }, { 'list': 'check' }],
              [{ 'script': 'sub'}, { 'script': 'super' }],      // superscript/subscript
              [{ 'indent': '-1'}, { 'indent': '+1' }],          // outdent/indent
              [{ 'direction': 'rtl' }],                         // text direction

              [{ 'size': ['small', false, 'large', 'huge'] }],  // custom dropdown
              [{ 'header': [1, 2, 3, 4, 5, 6, false] }],

              [{ 'color': [] }, { 'background': [] }],          // dropdown with defaults from theme
              [{ 'font': [] }],
              [{ 'align': [] }],

              ['clean'] 
              ]
            },
          }
        }
      },
      validations() {
        return {
          form: {
            name: {
              required,
              $autoDirty: true
            },
            description: {
              required,
              $autoDirty: true
            },
            rules: {
              required,
              $autoDirty: true
            }
          },
        }
      },
      created: function () {
        if(this.selected){
          this.form = JSON.parse(JSON.stringify(this.selected))
        }
        this.loadCategoriesForParent()
      },
      watch: {
        selected(selected) {
          if(selected){
            this.form = JSON.parse(JSON.stringify(selected))
          }
        },
      },
      computed: {
        title() {
          return this.isNew ? 'Add New Forum Category' : `Manage Forum Category`
        },
        isNew() {
          return !this.selected?.id
        },
      },
      methods: {
        loadCategoriesForParent(){
          this.globalState.loading = true
                Forum.loadForumData({
                    params: {
                        page: null,
                        search_value: "",
                        type: "category",
                        target_id: null,
                        only_main: 0,
                        get_sub: 0,
                        parent_id: 0,
                        order: "name,asc",
                        all_data: 1
                    }
                })
                .then(result => {
                    if (result.data.forum_data){
                        this.parent_categories = result.data.forum_data
                    }
                })
                .catch(error => {
                    console.log(error)
                })
                .finally(() => {
                    this.globalState.loading = false
                })
            
        },
        descChangeEvnt({ quill, html, text }){
          this.form.rules = html
        },
        onEditorReady (e) {
          var this_id = e.container.id
          if(this_id == "rules_quill") e.container.querySelector('.ql-blank').innerHTML = this.form.rules ? this.form.rules : ""
        },
        async onSubmit(evt) {
  
          evt.preventDefault()
  
          const result = await this.v$.$validate()
          if (!result) return

          if(this.form.parent_id && this.form.parent_id > 0){
            this.form.is_sub = 1
          }
          
          this.isSaving = true
          const promise = this.isNew ? Forum.storeCategory(this.form) : Forum.updateCategory(this.form.id, this.form)
          promise
            .then(resp => {
              
              this.$toast.success(resp.data.message || 'Forum Category has been saved successfully')
              this.$emit('save', this.form)
            })
            .catch(error => {
              console.error('error:', error.response.data)
            })
            .finally(() => {
              this.isSaving = false
            })
        },
        onCancel() {
          this.$emit('cancel')
        },
      }
    }
  </script>
  