<template>
    <div>
        <el-button type="primary" @click="getPrevious()" v-if="previous_form">Fetch Previous Data</el-button>
    </div>
    <div class="card-body">
        <el-card>
            <div class="row">
                <h3>Section II.2.</h3>
                <div class="col-md-6">
                    <br/><label>II.2.1. Lot title (optional)</label>
                    <el-input :label="'Title'" v-model="form.lot_title"></el-input>
                </div>
                <div class="col-md-6">
                    <br/><label>Lot number</label>
                    <div :class="{ 'has-error-field': v.form.lot_number.$invalid }">
                        <el-input :label="'Title'" v-model="form.lot_number"></el-input>
                    </div>
                </div>
                <div class="col-md-6">
                    <br/><label>II.2.2. Additional CPV Codes</label>
                    <div :class="{ 'has-error-field': v.form.lot_cpv_code.$invalid }">
                        <el-select v-model="form.lot_cpv_code" filterable clearable remote :remote-method="searchCPV">
                            <!-- <div v-for="categories in cpv_code_choices"> -->
                                <!-- <el-option v-for="(description, code) in categories" :key="code" :label="code + ': ' + description" :value="code">{{ code + ': ' + description }}</el-option> -->
                                <el-option
                                    v-for="item in optionsCpv"
                                    :key="item.value"
                                    :label="item.label"
                                    :value="item.value"
                                    />
                            <!-- </div> -->
                        </el-select>
                    </div>
                    <div v-if="v.form.lot_cpv_code.$invalid" class="error-text" >CPV code is required</div>
                </div>
                <div class="col-md-6" v-if="form.lot_cpv_code">
                    <br/><label>Supplementary Code (optional)</label>
                        <el-select v-model="form.lot_supplementary_code" filterable clearable multiple>
                            <span v-for="(parts, header) in supplementary_code_choices">
                                <el-option-group :label="header">
                                    <el-option-group v-for="(sub_parts, sub_header) in parts" :label="sub_header">
                                        <el-option v-for="(description, code) in sub_parts" :label="code + ': ' + description" :value="code">{{ code + ': ' + description }}</el-option>
                                    </el-option-group>
                                </el-option-group>
                            </span>
                            <el-option  :label="code + ': ' + description" :value="code">{{ code + ': ' + description }}</el-option>
                        </el-select>
                </div>
                <div class="col-md-12">
                        <br/><label>II.2.3. Place of performance</label>
                        <br/><label>NUTS codes</label>
                        <div :class="{ 'has-error-field': v.form.lot_nuts_code.$invalid }">
                            <el-select v-model="form.lot_nuts_code" filterable clearable>
                                <el-option-group v-for="(c_values, c_abbrev) in nuts_code_choices" label="Countries">
                                    <el-option :value="c_abbrev" :label="c_abbrev + ': ' + c_values.name">
                                        <span style="float: left">{{ c_values.name }}</span>
                                        <span class="secondary-selection">
                                            {{ c_abbrev }}
                                        </span>
                                    </el-option>
                                        <el-option-group v-if="'parts' in c_values" :key="c_values.name" :label="'Parts of ' + c_values.name" style="margin-left: 15px">
                                            <span v-for="(c1_values, c1_abbrev) in c_values.parts" style="margin-left: 15px">
                                                <el-option  :value="c1_abbrev" :label="c1_abbrev + ': ' + c1_values.name">
                                                    <span style="float: left">{{ c1_values.name }}</span>
                                                    <span class="secondary-selection">
                                                        {{ c1_abbrev }}
                                                    </span>
                                                </el-option>
                                                <el-option-group v-if="'parts' in c1_values" :key="c1_values.name" :label="'Parts of ' + c1_values.name" style="margin-left: 15px">
                                                    <span v-for="(c2_values, c2_abbrev) in c1_values.parts">
                                                        <el-option :value="c2_abbrev" :label="c2_abbrev + ': ' + c2_values.name" style="margin-left: 15px">
                                                            <span style="float: left">{{ c2_values.name }}</span>
                                                            <span class="secondary-selection">
                                                                {{ c2_abbrev }}
                                                            </span>
                                                        </el-option>
                                                            <el-option-group v-if="'parts' in c2_values" :key="c2_values.name" :label="'Parts of ' + c2_values.name" style="margin-left: 15px">
                                                                <span v-for="(c3_values, c3_abbrev) in c2_values.parts">
                                                                    <el-option :value="c3_abbrev" :label="c3_abbrev + ': ' + c3_values.name" style="margin-left: 15px">
                                                                        <span style="float: left">{{ c3_values.name }}</span>
                                                                        <span class="secondary-selection">
                                                                            {{ c3_abbrev }}
                                                                        </span>
                                                                    </el-option>
                                                                    <el-option-group v-if="'parts' in c3_values" :key="c3_values.name" :label="'Parts of ' + c3_values.name" style="margin-left: 15px">
                                                                        <el-option v-for="(c4_values, c4_abbrev) in c3_values.parts" :value="c4_abbrev" :label="c4_abbrev + ': ' + c4_values.name" style="margin-left: 15px">
                                                                            <span style="float: left">{{ c4_values.name }}</span>
                                                                            <span class="secondary-selection">
                                                                                {{ c3_abbrev }}
                                                                            </span>
                                                                        </el-option>
                                                                    </el-option-group>
                                                                </span>
                                                            </el-option-group>
                                                    </span>
                                                </el-option-group>
                                            </span>
                                    </el-option-group>
                                </el-option-group >
                            </el-select>
                        </div>
                        <div v-if="v.form.lot_nuts_code.$invalid" class="error-text" >NUTS Code is required</div>
                    </div>
                    <div class="col-md-6">
                        <br/><label>Main site or place of performance (optional)</label>
                        <el-input type="textarea" :rows="3" v-model="form.lot_main_site_of_performance"></el-input>
                    </div>
                    <div class="col-md-6">
                        <br/><label>II.2.4 Description of the procurement</label>
                        <div :class="{ 'has-error-field': v.form.lot_description.$invalid }">
                            <el-input type="textarea" :rows="3" v-model="form.lot_description"></el-input>
                        </div>
                        <div v-if="v.form.lot_description.$invalid" class="error-text" >Description is required</div>
                    </div>

                    <!-- F02 -->
                    <div v-if="fat_procurement_type == FATNoticeTypes.F02.value">
                        <div class="col-md-8">
                            <br/><label>II.2.5. Award criteria</label>
                            <div :class="{ 'has-error-field': v.form.lot_award_criteria.$invalid }">
                                <el-radio-group v-model="form.lot_award_criteria">
                                    <el-radio :label="0" :value="0">Criteria below</el-radio>
                                    <el-radio :label="1" :value="1">Price is not the only award criterion and all criteria are stated only in the procurement documents</el-radio>
                                </el-radio-group>
                            </div> 
                            <div v-if="v.form.lot_award_criteria.$invalid" class="error-text" >Award Criteria is required</div>
                        </div>
                        <div class="col-md-12">
                            <el-checkbox v-model="form.lot_quality_criteria">Quality criteria (optional)</el-checkbox>
                        </div>
                        <label>Enter criteria and weighting</label>
                        <div v-if="form.lot_quality_criteria">
                            <div class="col-md-12">
                                <table>
                                   <thead>
                                    <tr>
                                        <th>Quality Criterion</th>
                                        <th>Weighting</th>
                                    </tr>
                                   </thead>
                                    <tbody>
                                        <tr v-for="(data, index) in form.lot_quality_criterion" :key="index">
                                        <td><el-input v-model="form.lot_quality_criterion[index].criteria"></el-input></td>
                                        <td><el-input v-model="form.lot_quality_criterion[index].weighting"></el-input></td>
                                        <td><el-button type="warning" @click="form.lot_quality_criterion.splice(index)">Remove</el-button></td>
                                    </tr>
                                    </tbody>
                                </table>
                                <br/><el-button type="primary" @click="form.lot_quality_criterion.push({criteria: null, weighting: null})">Add</el-button>
                            </div>
                        </div>
                        <div class="col-md-12">
                            <br/><label>Cost criteria/price</label><br/>
                            <el-radio-group v-model="form.lot_cost_criteria">
                                <el-radio :label="0" :value="0">Cost criteria</el-radio>
                                <el-radio :label="1" :value="1">Price</el-radio>
                            </el-radio-group>
                        </div>
                        <div class="col-md-12" v-if="form.lot_cost_criteria == 0">
                            <table>
                                <thead>
                                    <tr>
                                    <th>Cost Criterion</th>
                                    <th>Weighting</th>
                                </tr>
                                </thead>
                                <tbody>
                                    <tr v-for="(data, index) in form.lot_cost_criterion" :key="index">
                                    <td><el-input v-model="form.lot_cost_criterion[index].criteria"></el-input></td>
                                    <td><el-input v-model="form.lot_cost_criterion[index].weighting"></el-input></td>
                                    <td><el-button type="warning" @click="form.lot_cost_criterion.splice(index)">Remove</el-button></td>
                                </tr>
                                </tbody>
                            </table>
                            <br/><el-button type="primary" @click="form.lot_cost_criterion.push({criteria: null, weighting: null})">Add</el-button>
                        </div>

                        <div class="col-md-6">
                            <br/><label>II.2.6. Estimated value (excluding VAT) (optional)</label>
                            <br/><label style="color: #A9A9A9">For example, 200000 or 417709.28. For framework agreements or dynamic purchasing systems - estimated total maximum value for the entire duration of this lot</label>
                            <el-input v-model="form.lot_estimated_value"></el-input>
                            <div v-if="v.form.lot_estimated_value.$invalid" class="error-text" >Invalid format</div>
                        </div>
                        <div class="col-md-6">
                        <br/><label>Currency (optional)</label>
                            <el-select v-model="form.lot_criteria_currency" filterable clearable>
                                <el-option v-for="(full_name, abbrev) in currency_choices" :value="abbrev" :label="abbrev + ': ' + full_name">{{ abbrev + ': ' + full_name }}</el-option>
                            </el-select>
                        </div>

                        <div class="col-md-6">
                            <br/><label>II.2.7. Duration of the contract, framework agreement or dynamic purchasing system</label>
                            <div :class="{ 'has-error-field': v.form.lot_contract_duration.$invalid }">
                                <el-radio-group v-model="form.lot_contract_duration">
                                    <el-radio :label="0" :value="0">Duration</el-radio>
                                    <el-radio :label="1" :value="1">Date range</el-radio>
                                </el-radio-group>
                            </div> 
                            <div v-if="v.form.lot_contract_duration.$invalid" class="error-text" >Contract duration is required</div>
                        </div>
                        <div v-if="form.lot_contract_duration == 0">
                            <div class="col-md-6">
                                <br/><label>Duration type</label>
                                <div :class="{ 'has-error-field': v.form.lot_duration_type.$invalid }">
                                    <el-radio-group v-model="form.lot_duration_type">
                                        <el-radio :label="0" :value="0">Duration in months</el-radio>
                                        <el-radio :label="1" :value="1">Duration in days</el-radio>
                                    </el-radio-group>
                                </div> 
                                <div v-if="v.form.lot_duration_type.$invalid" class="error-text" >Duration type is required</div>
                            </div>
                            <div class="col-md-6">
                                <br/><label>Duration</label>
                                <br/><label style="color: #A9A9A9">The duration must be a number, for example 12</label>
                                <div :class="{ 'has-error-field': v.form.lot_duration.$invalid }">
                                    <el-input v-model="form.lot_duration"></el-input>
                                </div>
                                <div v-if="v.form.lot_duration.$invalid" class="error-text" >Duration is required</div>
                            </div>
                        </div>

                        <div v-if="form.lot_contract_duration == 1">
                            <br/><label>Date Range</label>
                            <br/><label style="color: #A9A9A9">Enter the start date, the end date or both</label>
                            <div class="col-md-6">
                                <label>Start Date</label>
                                <br/><el-date-picker v-model="form.lot_contract_start_date" format="YYYY/MM/DD" value-format="YYYY-MM-DD"></el-date-picker>
                            </div>
                            <div class="col-md-6">
                                <label>End Date</label>
                                <br/><el-date-picker v-model="form.lot_contract_end_date" format="YYYY/MM/DD" value-format="YYYY-MM-DD"></el-date-picker>
                            </div>
                        </div>

                        <div class="col-md-6">
                            <br/><label>This contract is subject to renewal</label>
                            <div :class="{ 'has-error-field': v.form.lot_subject_to_renewal.$invalid }">
                                <el-radio-group v-model="form.lot_subject_to_renewal">
                                    <el-radio :label="1" :value="1">Yes</el-radio>
                                    <el-radio :label="0" :value="0">No</el-radio>
                                </el-radio-group>
                            </div> 
                            <div v-if="v.form.lot_subject_to_renewal.$invalid" class="error-text" >Subject to renewal is required</div>
                        </div>

                        <div class="col-md-6" v-if="form.lot_subject_to_renewal">
                            <br/><label>Description of renewals</label>
                            <div :class="{ 'has-error-field': v.form.lot_renewal_description.$invalid }">
                                <el-input type="textarea" v-model="form.lot_renewal_description" :rows="5"></el-input>
                            </div> 
                            <div v-if="v.form.lot_renewal_description.$invalid" class="error-text" >Renewal description is required</div>
                        </div>
                        
                        <div class="col-md-6">
                            <br/><label>II.2.10. Information about variants</label>
                            <br/><label>Variants will be accepted</label>
                            <br/><label style="color: #A9A9A9">Can the contracting authority accept bids which propose innovative ways of delivering a service that differs from that specified, alternative pricing structures, or risk profiles?</label>
                            <div :class="{ 'has-error-field': v.form.lot_accept_variants.$invalid }">
                                <el-radio-group v-model="form.lot_accept_variants">
                                    <el-radio :label="1" :value="1">Yes</el-radio>
                                    <el-radio :label="0" :value="0">No</el-radio>
                                </el-radio-group>
                            </div> 
                            <div v-if="v.form.lot_accept_variants.$invalid" class="error-text" >Accept variants is required</div>
                        </div>

                        <div class="col-md-6">
                            <label>II.2.11. Information about options</label>
                            <br/><label>Options will be accepted</label>
                            <br/><label style="color: #A9A9A9">Can the term of the agreements be extended at the discretion of the contracting authority?</label>
                            <div :class="{ 'has-error-field': v.form.lot_accept_options.$invalid }">
                                <el-radio-group v-model="form.lot_accept_options">
                                    <el-radio :label="1" :value="1">Yes</el-radio>
                                    <el-radio :label="0" :value="0">No</el-radio>
                                </el-radio-group>
                            </div> 
                            <div v-if="v.form.lot_accept_options.$invalid" class="error-text" >Accept options is required</div>
                        </div>
                        
                        <div class="col-md-12" v-if="form.lot_accept_options">
                            <label>Description of options</label>
                            <br/>
                            <div :class="{ 'has-error-field': v.form.lot_option_description.$invalid }">
                                <el-input v-model="form.lot_option_description" type="textarea" :rows="5"></el-input>
                            </div>
                            <div v-if="v.form.lot_option_description.$invalid" class="error-text" >Accept options is required</div>
                        </div>

                        <div class="col-md-12">
                            <br/><label>II.2.12. Information about electronic catalogues</label>
                            <br/><el-checkbox v-model="form.lot_ecatalogue">Tenders must be presented in the form of an electronic catalogues or include an electronic catalogue (optional)</el-checkbox>
                        </div>
                    </div>

                    <div class="col-md-12">
                        <br/><label>II.2.14. Additional information (optional)</label>
                        <el-input type="textarea" :rows="3" v-model="form.lot_addl_description"></el-input>
                    </div>
            </div>
        </el-card>
    </div>
    <span class="col-md-3 mb-3" v-if="is_not_submitted"><el-button class="mb-3" type="success" :disabled="v.form.$invalid" @click="saveForm()">Save and go to Next Section</el-button></span>
    
 </template>
 
 <script>
    import { mapState } from 'vuex'
     import {
     useVuelidate
     } from '@vuelidate/core'
     import {
     required, requiredIf, numeric
     } from '@vuelidate/validators'
     import * as moment from 'moment'

 
     const SECTION_TWO_LOTS = 2.1;
     
     export default {
         props: ['user', 'fat_procurement_type', 'procurement', 'saved_form', 'preliminary', 'cpv_code_choices', 'supplementary_code_choices', 'nuts_code_choices', 'currency_choices', 'is_not_submitted', 'section_two_id'],
         data() {
             return {
                optionsCpv: [],
                listCpv: [],
                loading: false,
                form: {
                    fat_section_two_questions_id: null,
                    lot_title: null,
                    lot_number: null,
                    lot_cpv_code: null,
                    lot_supplementary_code: [],
                    lot_nuts_code: null,
                    lot_main_site_of_performance: null,
                    lot_description: null,
                    lot_addl_description: null,
                    //F02
                    tenders_submitted_for: null,
                    lot_award_criteria: null,
                    lot_quality_criteria: null,
                    lot_quality_criterion: [{criteria: null, weighting: null}],
                    lot_cost_criteria: null,
                    lot_cost_criterion: [{criteria: null, weighting: null}],
                    lot_estimated_value: null,
                    lot_criteria_currency: null,
                    lot_contract_duration: null,
                    lot_duration_type: null,
                    lot_duration: null,
                    lot_subject_to_renewal: null,
                    lot_renewal_description: null,
                    lot_contract_start_date: null,
                    lot_contract_end_date: null,
                    lot_accept_variants: null,
                    lot_accept_options: null,
                    lot_option_description: null,
                    lot_price_weighting: null,
                    lot_ecatalogue: null
                }
            }
         },
         setup: () => ({
             v: useVuelidate()
          }),
          validations() {
         let rules =  {
             form: {
                lot_number: {
                required,
                $autoDirty: true
                },
                lot_cpv_code: {
                required,
                $autoDirty: true
                },
                lot_nuts_code: {
                    required,
                    $autoDirty: true
                },
                lot_description: {
                    required,
                    $autoDirty: true
                },
                lot_award_criteria: {
                    required: requiredIf(function () {return this.fat_procurement_type == this.FATNoticeTypes.F02.value}),
                    $autoDirty: true
                },
                lot_quality_criterion: {
                    required: requiredIf(function () {return this.fat_procurement_type == this.FATNoticeTypes.F02.value && this.form.lot_quality_criteria}),
                    $autoDirty: true
                },
                lot_cost_criteria: {
                    required: requiredIf(function () {return this.fat_procurement_type == this.FATNoticeTypes.F02.value}),
                    $autoDirty: true
                },
                lot_cost_criterion: {
                    required: requiredIf(function () {return this.fat_procurement_type == this.FATNoticeTypes.F02.value && this.form.lot_cost_criteria == 0}),
                    $autoDirty: true
                },
                lot_contract_duration: {
                    required: requiredIf(function () {return this.fat_procurement_type == this.FATNoticeTypes.F02.value}),
                    $autoDirty: true
                },
                lot_duration_type: {
                    required: requiredIf(function () {return this.fat_procurement_type == this.FATNoticeTypes.F02.value && this.form.lot_contract_duration == 0}),
                    $autoDirty: true
                },
                lot_duration: {
                    required: requiredIf(function () {return this.fat_procurement_type == this.FATNoticeTypes.F02.value && this.form.lot_contract_duration == 0}),
                    $autoDirty: true
                },
                lot_subject_to_renewal: {
                    required: requiredIf(function () {return this.fat_procurement_type == this.FATNoticeTypes.F02.value }),
                    $autoDirty: true
                },
                lot_renewal_description: {
                    required: requiredIf(function () {return this.fat_procurement_type == this.FATNoticeTypes.F02.value && this.form.lot_subject_to_renewal}),
                    $autoDirty: true
                },
                lot_accept_variants: {
                    required: requiredIf(function () {return this.fat_procurement_type == this.FATNoticeTypes.F02.value }),
                    $autoDirty: true
                },
                lot_accept_options: {
                    required: requiredIf(function () {return this.fat_procurement_type == this.FATNoticeTypes.F02.value }),
                    $autoDirty: true
                },
                lot_option_description: {
                    required: requiredIf(function () {return this.fat_procurement_type == this.FATNoticeTypes.F02.value && this.form.lot_accept_options}),
                    $autoDirty: true
                },
                //optionan
                lot_estimated_total_value: {
                    numeric,
                    $autoDirty: true
                },
                lot_estimated_value: {
                    numeric,
                    $autoDirty: true
                },
            }
         }

        return rules
        },
        mounted()  {
            if(this.saved_form){
                this.form = this.saved_form
                if(!this.form.lot_quality_criterion) this.form.lot_quality_criterion = [{criteria: null, weighting: null}]
                if(!this.form.lot_cost_criterion) this.form.lot_cost_criterion = [{criteria: null, weighting: null}]
                this.searchCPV(this.form.lot_cpv_code);
            } 
            else { this.form.fat_section_two_questions_id = this.section_two_id
            }
            
            var self = this
            Object.values(this.cpv_code_choices).map((values) => {
                Object.entries(values).forEach((desc) => {
                    self.listCpv.push({ label: desc[0] + ": " + desc[1], value: desc[0] })
                })
            })
        },
        computed: {
        ...mapState(['FATNoticeTypes', 'FATFormParts']),
        },
        methods: {
            saveForm() {
                if(this.form.lot_divided_into_lots != 1) this.form.lot_quantity_of_lots = null

                let formattedDate = moment(
                    this.form.lot_estimated_date_contract_notice
                ).format('YYYY-MM-DD')

                this.form.lot_estimated_date_contract_notice = formattedDate

                this.form.fat_section_two_questions_id = this.section_two_id
                console.log('section two id: ', this.section_two_id)
                this.$emit('save', SECTION_TWO_LOTS, this.form)
            },
            contractTypeTranslation(type) {
                switch (type) {
                    case 0: return 'Works'
                    case 1: return 'Supplies'
                    case 2: return 'Services'
                }
            },
            searchCPV(query) {
                if (query) {
                this.loading = true
                    setTimeout(() => {
                    this.loading = false
                    this.optionsCpv = this.listCpv.filter((item) => {
                        return item.label.toLowerCase().includes(query.toLowerCase())
                    })
                    this.optionsCpv = this.optionsCpv.slice(0, 10);
                }, 500)
                } else {
                    this.optionsCpv = []
                }
            },
            getPrevious() {
                delete this.previous_form.id
                this.form = this.previous_form;
            },
        }  
    }
 </script>
 