<template>
    <div>
        <el-button type="primary" @click="getPrevious()" v-if="previous_form">Fetch Previous Data</el-button>
    </div>
    <div class="card-body">
        <el-card>
            <div class="row">
                <div class="col-md-12" v-if="tpp_procurement_type === TPPNoticeTypes.UK2.value">
                    <label>Publication date of the tender or transparency notice (estimated) (optional)</label>
                    <br/><el-date-picker v-model="form.publication_date" format="YYYY/MM/DD" value-format="YYYY-MM-DD" :disabled-date="disablePastDates"></el-date-picker>
                </div>
                <div class="row" v-if="tpp_procurement_type === TPPNoticeTypes.UK4.value">
                    <div class="col-md-6">
                        <label>Enquiry deadline (optional)</label>
                        <el-date-picker v-model="form.enquiry_deadline" format="YYYY/MM/DD" value-format="YYYY-MM-DD" :disabled-date="disablePastDates"></el-date-picker>
                    </div>
                    <div class="col-md-6">
                        <label>Tender submission deadline</label>
                        <div :class="{ 'has-error-field': v.form.submission_deadline.$invalid }">
                            <el-date-picker v-model="form.submission_deadline" format="YYYY/MM/DD" value-format="YYYY-MM-DD" :disabled-date="disablePastDates"></el-date-picker>
                        </div>
                        <div v-if="v.form.submission_deadline.$invalid" class="error-text" >Deadline is required</div>
                    </div>
                    <div class="col-md-4">
                        <br/><label>May tenders be submitted electronically?</label>
                        <br/><label style="color: #a9a9a9">Electronic submissions is required if possible.</label>
                        <div :class="{ 'has-error-field': v.form.can_submit_electronically.$invalid }">
                            <el-radio-group v-model="form.can_submit_electronically">
                                <el-radio-button :value="true">Yes</el-radio-button>
                                <el-radio-button :value="false">No</el-radio-button>
                            </el-radio-group>
                        </div>
                        <div v-if="v.form.can_submit_electronically.$invalid" class="error-text" >Yes/No is required</div>
                    </div>
                    <div class="col-md-8">
                        <br/><label>Submission address and any special instructions</label>
                        <br/><label style="color: #a9a9a9">Must include a website if tenders may be submitted electronically. For example, https://www.example.com/.</label>
                        <div :class="{ 'has-error-field': v.form.submission_instructions.$invalid }">
                            <el-input v-model="form.submission_instructions"></el-input>
                        </div>
                        <div v-if="v.form.submission_instructions.$invalid" class="error-text" >Yes/No is required</div>
                    </div>
                    <div class="col-md-12">
                        <el-checkbox v-model="form.use_electronic_auction" :checked="form.use_electronic_auction">An electronic auction will be used (optional)</el-checkbox>
                    </div>
                    <!-- languages                         -->
                     <div class="col-md-4">
                        <br/><label>Award decision date (estimated)</label>
                        <div :class="{ 'has-error-field': v.form.decision_date.$invalid }">
                            <el-date-picker v-model="form.decision_date" format="YYYY/MM/DD" value-format="YYYY-MM-DD" :disabled-date="disablePastDates"></el-date-picker>
                        </div>
                        <div v-if="v.form.decision_date.$invalid" class="error-text" >Award decision date is required</div>
                     </div>
                     <div class="col-md-4">
                        <div :class="{ 'has-error-field': v.form.is_recurring_procurement.$invalid }">
                            <el-radio-group v-model="form.is_recurring_procurement">
                                <el-radio-button :value="true">Yes</el-radio-button>
                                <el-radio-button :value="false">No</el-radio-button>
                            </el-radio-group>
                        </div>
                        <div v-if="v.form.is_recurring_procurement.$invalid" class="error-text" >Yes/No is required</div>
                     </div>
                     <div class="col-md-5">
                        <br/><label>Publication date of next tender notice (estimated)</label>
                        <div :class="{ 'has-error-field': v.form.publication_date.$invalid }">
                            <el-date-picker v-model="form.publication_date" format="YYYY/MM/DD" value-format="YYYY-MM-DD" :disabled-date="disablePastDates"></el-date-picker>
                        </div>
                        <div v-if="v.form.next_publication_date.$invalid" class="error-text" >Publication date is required</div>
                     </div>
                </div>
            </div>
        </el-card>
    </div>
    <span class="col-md-3 mb-3" v-if="is_not_submitted"><el-button type="success" @click="saveForm()">Save and go to Next Section</el-button></span>
 </template>
 
 <script>
    import { mapState } from 'vuex'
     import {
     useVuelidate
     } from '@vuelidate/core'

     const SUBMISSION = 'SUBMISSION';

     export default {
         props: ['previous_form', 'saved_form', 'is_not_submitted', 'tpp_procurement_type', 'contract_start_date', 'engagement_deadline'],
         data() {
             return {
                form: {
                    publication_date: null,
                    // Uk4
                    enquiry_deadline: null
                },
             }
         },
        setup: () => ({
             v: useVuelidate()
        }),
        created() {
            if(this.saved_form) this.form = this.saved_form

            console.log('form', this.form)

        },
        computed: {
        ...mapState(['TPPNoticeTypes', 'TPPFormParts']),
        },
        watch: {
            saved_form: {
                handlers(form){
                    this.form = form
                },
                deep: true
            }
        },
        methods: {
            saveForm() {
                this.$emit('save', SUBMISSION, this.form)
            },
            getPrevious() {
                delete this.previous_form.id
                this.form = this.previous_form
            },
            disablePastDates(date) {
                const startDate = new Date(this.contract_start_date)
                const engagementDeadline = new Date(this.engagement_deadline)
                startDate.setDate(startDate.getDate() - 1)
                engagementDeadline.setDate(engagementDeadline.getDate() - 1)
                return date < engagementDeadline || date > startDate
            },
        }
     }
 </script>