<template>
    <div>
        <el-button type="primary" @click="getPrevious()" v-if="previous_form">Fetch Previous Data</el-button>
    </div>
    <div class="card-body">
        <el-card>
            <div class="row">
                <div class="col-md-12">
                    <label>Which organisation should be contacted with any enquiries</label>
                    <div :class="{ 'has-error-field': v.form.contact_info_id.$invalid }">
                        <el-radio-group v-model="form.contact_info_id">
                            <span v-for="org in organisations"><el-radio-button :value="org">{{ org.authority_name }}</el-radio-button></span><br/>
                        </el-radio-group>
                    </div>
                    <div v-if="v.form.contact_info_id.$invalid" class="error-text" >Contact organisation is required</div>
                </div>
            </div>
        </el-card>
    </div>
    <span class="col-md-3 mb-3" v-if="is_not_submitted"><el-button type="success" :disabled="v.form.$invalid" @click="saveForm()">Save and go to Next Section</el-button></span>
 </template>
 
 <script>
    import { mapState } from 'vuex'
     import {
     useVuelidate
     } from '@vuelidate/core'
     import {
     required
     } from '@vuelidate/validators'
 
     const CONTACT_ORG = 'CONTACT_ORG';

     export default {
         props: ['previous_form', 'saved_form', 'is_not_submitted', 'saved_form', 'organisations'],
         data() {
             return {
                form: {
                    contact_info_id: null
                }
             }
         },
        setup: () => ({
             v: useVuelidate()
        }),
        created() {
            this.organisations.forEach(org => {
                if(org.is_main_contact) {
                    this.form.contact_info_id = org
                    this.$emit('update', org)
                }
            })

        },
        computed: {
        ...mapState(['TPPNoticeTypes', 'TPPFormParts']),
        },
        watch: {
            saved_form: {
                handlers(form){
                    this.form = form
                },
                deep: true
            }
        },
        validations() {
            let rules = {
                form: {
                    contact_info_id: {
                        required,
                        $autoDirty: true
                    }
                }
                
            }
            return rules
        },
        methods: {
            saveForm() {
                this.$emit('save', CONTACT_ORG, this.form)
            },
            getPrevious() {
                delete this.previous_form.id
                this.form = this.previous_form
            }
        }

     }
 </script>
 