<template>
    <div>
        <el-button type="primary" @click="getPrevious()" v-if="previous_form">Fetch Previous Data</el-button>
    </div>
    <div class="card-body">
        <el-card>
            <div class="row">
                <div class="col-md-12">
                    <label>Contracting authority name</label>
                    <div :class="{ 'has-error-field': v.form.authority_name.$invalid }">
                        <el-input :label="'Official Name'" v-model="form.authority_name"></el-input>
                    </div>
                    <div v-if="v.form.authority_name.$invalid" class="error-text" >Contracting authority name is required</div>
                </div>
                <div class="row">
                    <div class="row" v-for="(iden, index) in form.identification" :key="index">
                        <div class="col-md-6">
                            <br/><label>Identification Register</label>
                            <!-- <div :class="{ 'has-error-field': iden.identification_register.$invalid }"> -->
                                <el-select :label="'Identification Register'" v-model="form.identification[index].identification_register">
                                    <el-option :label="'Companies House'" :value="0"></el-option>
                                    <el-option :label="'Charity Commission (England and Wales)'" :value="1"></el-option>
                                    <el-option :label="'Scottish Charity Register'" :value="2"></el-option>
                                    <el-option :label="'Charity Commission for Nothern Ireland'" :value="3"></el-option>
                                    <el-option :label="'NHS Organisation Data Service'" :value="4"></el-option>
                                    <el-option :label="'UK Register of Learning Providers (UKPRN number)'" :value="5"></el-option>
                                    <el-option :label="'Mutuals Public Register'" :value="6"></el-option>
                                    <el-option :label="'Guernsey Registry'" :value="7"></el-option>
                                    <el-option :label="'Jersey Financial Services Commission Registry'" :value="8"></el-option>
                                    <el-option :label="'Isle of Man Companies Registry'" :value="9"></el-option>
                                    <el-option :label="'Public Procurement Organisation Number'" :value="9"></el-option>
                                </el-select>
                            <!-- </div> -->
                            <!-- <div v-if="v.form.identification.$each[index]?.identification_register.$invalid" class="error-text" >Identification Register is required</div> -->
                            <!-- <div v-if="iden.identification_register.$invalid" class="error-text" >Identification Register is required</div> -->
                            <br/>
                        </div>
                        <div class="col-md-4">
                            <br/><label>Identifier</label>
                            <!-- <div :class="{ 'has-error-field': v.form.identification[index].identifier.$invalid }"> -->
                                <el-input v-model="form.identification[index].identifier" :label="'Identifier'"></el-input>
                            <!-- </div> -->
                            <!-- <div v-if="v.form.identification[index].identifier.$invalid" class="error-text" >Identifier is required</div> -->
                        </div>
                        <div class="col-md-2">
                            <div v-if="index != 0">
                                <br/><br/><el-button type="warning" @click="form.identification.splice(index)">Remove</el-button>
                            </div>
                        </div>
                        
                    </div>
                    <div class="col-md-3">
                        <br/><el-button type="primary" @click="form.identification.push({identification_register: null, identifier: null})">Add another identifier</el-button>
                    </div>  
                </div>
                
                <div class="col-md-8">
                    <br/><label>Street address</label>
                        <div :class="{ 'has-error-field': v.form.street_address.$invalid }">
                            <el-input v-model="form.street_address" :label="'Address'"></el-input>
                        </div>
                        <div v-if="v.form.street_address.$invalid" class="error-text" >Street address is required</div>
                </div>
                <div class="col-md-4">
                    <br/><label>Town/City</label>
                    <div :class="{ 'has-error-field': v.form.town_city.$invalid }">
                        <el-input v-model="form.town_city" :label="'Town/City'"></el-input>
                    </div>
                    <div v-if="v.form.town_city.$invalid" class="error-text" >Town/City is required</div>
                </div>
                <div class="col-md-6">
                    <br/><label>Postcode</label>
                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                    <label style="color: #A9A9A9">For UK postcodes, the related region will also be shown on the notice.</label>
                    <el-input v-model="form.post_code" :label="'Postcode'"></el-input>
                </div>
                <div class="col-md-6">
                    <br/><label>Country</label>
                    <div :class="{ 'has-error-field': v.form.country.$invalid }">
                        <el-select v-model="form.country" filterable clearable>
                            <el-option v-for="(full_name, abbrev) in country_choices" :label="abbrev + ': ' + full_name" :value="abbrev">{{ full_name }}</el-option>
                        </el-select>
                    </div>
                    <div v-if="v.form.country.$invalid" class="error-text">Country is required</div>
                </div>
                <div class="col-md-6">
                    <br/><label>Contact name (optional)</label>
                    <el-input v-model="form.contact_name" :label="'Contact name'"></el-input>
                </div>
                <div class="col-md-6">
                    <br/><label>Website (optional)</label>
                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                    <label style="color: #A9A9A9">For example, https://www.example.com/</label>
                    <div :class="{ 'has-error-field': v.form.website.$invalid }">
                        <el-input v-model="form.website" :label="'Main Address'"></el-input>
                    </div>
                    <div v-if="v.form.website.$invalid" class="error-text" >Website format is invalid</div>
                </div>
                <div class="col-md-6">
                    <br/><label>Email</label> 
                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                    <label style="color: #A9A9A9">For example, name@example.com.</label>
                    <div :class="{ 'has-error-field': v.form.email.$invalid }">
                        <el-input v-model="form.email" :label="'Email Address'"></el-input>
                    </div>
                    <div v-if="v.form.email.$invalid" class="error-text" >Email is required/invalid</div>
                </div>
                <div class="col-md-6">
                    <br/><label>Telephone (optional)</label>
                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                    <label style="color: #A9A9A9">Include the country code for non-UK numbers, for example +353 1 234 5678.</label>
                    <el-input v-model="form.telephone" :label="'Telephone (optional)'"></el-input>
                    <div v-if="v.form.telephone.$invalid" class="error-text" >Phone number is invalid</div>
                </div>
            </div>
            <div class="row">
                <div class="col-md-6">
                    <br/><label>Organisation type</label>
                    <div :class="{ 'has-error-field': v.form.organisation_type.$invalid }">
                        <el-radio-group v-model="form.organisation_type">
                            <el-radio :label="0" :value="0">Public authority - central government</el-radio>
                            <el-radio :label="1" :value="1">Public authority - sub-central government</el-radio>
                            <el-radio :label="2" :value="2">Public undertaking <br/><span style="color: #A9A9A9">Commercial organisation subject to public authority oversight</span></el-radio>
                            <br/><el-radio :label="3" :value="3">Private utility</el-radio>
                        </el-radio-group>
                    </div>
                    <div v-if="v.form.organisation_type.$invalid" class="error-text" >Organisation type is required</div>
                </div>
                <div class="col-md-6">
                    <br/><label>Devolved regulations that apply</label>
                    <br/><label style="color: #A9A9A9">Where the contracting authority operates mainly in that part of the UK and its activities are not reserved.</label>
                    <div :class="{ 'has-error-field': v.form.devolved_regulations_apply.$invalid }">
                        <el-radio-group v-model="form.devolved_regulations_apply">
                            <el-radio :label="0" :value="0">Northern Ireland</el-radio>
                            <el-radio :label="1" :value="1">Scotland</el-radio>
                            <el-radio :label="2" :value="2">Wales</el-radio>
                            <el-radio :label="3" :value="3">No devolved regulations apply</el-radio>
                        </el-radio-group>
                    </div>
                    <div v-if="v.form.devolved_regulations_apply.$invalid" class="error-text" >Devolved regulations is required</div>
                </div>
            </div>
        </el-card>
    </div>
    <span class="col-md-3 mb-3" v-if="is_not_submitted"><el-button type="success" class="mb-3" :disabled="v.form.$invalid" @click="saveForm()">Save and go to Next Section</el-button></span>
</template>


<script>
import { mapState } from 'vuex'
 import {
 useVuelidate
 } from '@vuelidate/core'
 import {
 required, requiredIf,
 url, email, helpers,
 numeric
 } from '@vuelidate/validators'

 const CONTRACTING_AUTH_INFO = 'CONTRACTING_AUTH_INFO';

 export default {
     props: ['procurement_id', 'saved_form', 'is_not_submitted', 'country_choices', 'previous_form', 'index'],
     data() {
         return {
            form: {
                authority_name: null,
                identification: [{identification_register: null, identifier: null}],
                street_address: null,
                town_city: null,
                post_code: null,
                country: 'GB',
                contact_name: null,
                email: null,
                telephone: null,
                website: null,
                organisation_type: null,
                devolved_regulations_apply: null
            }
         }
     },
    setup: () => ({
         v: useVuelidate()
    }),
    created()  {
        if(this.saved_form) this.form = this.saved_form
        else if(this.index === 0 && !this.saved_form) this.initData()
    },
    computed: {
    ...mapState(['TPPNoticeTypes', 'TPPFormParts', 'savedUser']),
    },
    watch: {
        saved_form: {
            handlers(form){
                this.form = form
            },
            deep: true
        }
    },
    validations() {
        const phonePattern = /^\+\d{1,4}\s\d{1,4}\s\d{3,4}\s\d{4}$/;
        const phoneValidation = value => !helpers.req(value) || phonePattern.test(value);

        let rules = {
            form: {
                authority_name: {
                    required,
                    $autoDirty: true
                },
                street_address: {
                    required,
                    $autoDirty: true
                },
                town_city: {
                    required,
                    $autoDirty: true
                },
                country: {
                    required,
                    $autoDirty: true
                },
                email: {
                    required,
                    email,
                    $autoDirty: true
                },
                website: {
                    url,
                    $autoDirty: true
                },
                telephone: {
                    phoneValidation,
                    $autoDirty: true
                },
                post_code: {
                    required,
                    $autoDirty: true
                },
                organisation_type: {
                    required,
                    $autoDirty: true
                },
                devolved_regulations_apply: {
                    required,
                    $autoDirty: true
                },
                identification: {
                    required,
                    $each: {
                        identification_register: {
                            required,
                            $autoDirty: true
                        },
                        identifier: {
                            required,
                            $autoDirty: true
                        }
                    }
                }
            }
        }
        
        return rules
    },
    methods: {
        saveForm() {
            
            this.$emit('save', CONTRACTING_AUTH_INFO, this.form)
        },
        getPrevious() {
            delete this.previous_form.id
            this.form = this.previous_form
        },
        initData() {
            let companyDetails = this.savedUser.company;

            if(this.savedUser) {
                this.form.authority_name = companyDetails.name
                this.form.street_address = companyDetails.address1
                this.form.town_city = companyDetails.city
                this.form.post_code = companyDetails.zip
                this.form.country = companyDetails.country
                this.form.email = companyDetails.email_address   
            }
        }
    }

 }
</script>
