import api from './api'

const API = '/api/global_email_template'

export const GlobalEmailTemplate = {
    loadGlobalTemplates: function(params) {
        return api.get(API + "/load-global-email-templates", params)
    },
    loadEmailTemplateSettings: function(params) {
        return api.get(API + "/load-email-template-settings", params)
    },
    show: function(id, params) {
        return api.get(API + '/' + id, params)
    },
    updateGlobalEmailTemplate: (id, params) => {
        return api.update(`${API}/update-global-email-template/${id}`, params)
    },
    updateEmailTemplateSettings: (id, params) => {
        return api.update(`${API}/update-email-template-settings/${id}`, params)
    },
}
