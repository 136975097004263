import api from './api'

const API = '/api/email_template'

export const EmailTemplate = {
    storeEmailTemplate: (params) => {
        return api.create(API + "/store-email-template", params)
    },
    sendTestMail: (params) => {
        return api.create(API + "/send-test-mail", params)
    },
    showEmailTemplate: function(id, params) {
        return api.get(API + '/show-email-template/' + id, params)
    },
    updateSelection: (params) => {
        return api.update(`${API}/update-selection`, params)
    },
    updateEmailTemplate: (id, params) => {
        return api.update(`${API}/update-email-template/${id}`, params)
    },
}
