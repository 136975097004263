<template>
  <div v-if="lot" class="container">
    <supplierhub-specs-list 
    @reOpenSpec="reOpenSpec"
    :lot="lot" 
    :itt_framework="itt_framework"
    :pin_notice_id="pin_notice_id"
    :itt_framework_id="itt_framework_id"
    :kind="kind"
    :if_pin_notice="if_pin_notice"></supplierhub-specs-list>
  </div>
</template>

<script>
import { mapState } from 'vuex'
import SupplierhubSpecsList from '../SupplierhubSpecs/SupplierhubSpecsList.vue'

export default {
  emits: ["reOpenSpec"],
  components: { SupplierhubSpecsList  },
  props: {
    itt_framework: {
      type: Object,
      required: false,
      default: null
    },
    lot: {
      type: Object,
      required: false,
      default: null
    },
    if_pin_notice: {
      type: Number,
      required: false
    },
    pin_notice_id: {
      type: Number,
      required: false
    },
    itt_framework_id: {
      type: Number,
      required: false
    },
    kind: {
      type: Number,
      required: false,
      default: 0
    }
  },
  data() {
    return {
     
    }
  },
  computed: {
    ...mapState(['savedUser'])
  },
  created: function () {
  },
  methods: {
    reOpenSpec(){
      this.$emit('reOpenSpec', this.kind)
    }
  }
}
</script>

<style scoped>
.completed {
  background: #d3ffe3;
}
</style>
