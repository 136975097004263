<template>
    <div v-if="form" class="" v-loading="loading">
        <el-form label-position="top">

            <div class="pb-0 card-header" style="text-align:center">
                <h5 class="font-weight-bolder mb-0">{{ title }}</h5>
                <hr>
            </div>

            <div v-if="!form.id">
                <el-steps style="margin-bottom: 50px" :active="step" align-center>
                    <el-step title="Action" description="Determine the contract type" />
                    <el-step title="Workflow" description="" />
                    <el-step title="Details" description="Provide the project details" />
                    <el-step title="Final" description="Review the details" />

                </el-steps>

                <div v-if="step == 0" style="text-align: center;">
                    <div class="" style="padding-top: 20px">
                        <h5>What do you want to do ?</h5>

                        <div :class="{ 'has-error-field': v.form.project_category.$errors.length }">
                            <el-radio-group v-model="form.project_category">
                                <el-radio :value="0" size="large">Public Contracts</el-radio>
                            </el-radio-group>
                        </div>
                        <div v-if="v.form.project_category.$errors.length" class="error-text">Select a category</div>
                    </div>
                </div>

                <div v-if="step == 1" style="text-align: center;">
                    <div class="" style="padding-top: 20px">
                        <h5>Is it a Framework ?</h5>
                        <el-checkbox v-model="form.is_framework" :label="form.is_framework ? 'Yes' : 'No'" size="large"
                            @change="handleChangeCategory" />
                    </div>

                    <!-- <div class="" v-if="form.is_framework">
                        <h5></h5>
                        <el-checkbox v-model="form.is_framework_tender" :label="'Framework Tender '+(form.is_framework_tender ? '(Yes)' : '(No)')" size="large"
                            @change="handleChangeCategory" />
                    </div> -->

                    <div class="" style="padding-top: 20px" v-if="form.is_framework">
                        <div>
                            <label>Framework Options</label>
                            <div :class="{ 'has-error-field': v.form.framework_category.$errors.length }">
                                <el-radio-group v-model="form.framework_category" @change="changeFrameworkCategory">
                                    <el-radio :value="0" size="large">Closed Framework</el-radio>
                                    <el-radio :value="1" size="large">Open Framework</el-radio>
                                    <el-radio :value="2" size="large">Framework Tender</el-radio>
                                </el-radio-group>
                            </div>
                            <div v-if="v.form.framework_category.$errors.length" class="error-text">Select framework option</div>
                        </div>

                        <div v-if="form.framework_category == 2">
                            <label>Framework Tender Options</label>
                            <div :class="{ 'has-error-field': v.form.category.$errors.length }">
                                <el-radio-group v-model="form.category">
                                    <el-radio :value="0" size="large">Closed Framework</el-radio>
                                    <el-radio :value="1" size="large">Open Framework</el-radio>
                                </el-radio-group>
                            </div>
                            <div v-if="v.form.category.$errors.length" class="error-text">Select framework option</div>
                        </div>

                        <div class="row" v-if="showRecurringInterval()">
                            <div class="col-md-4"></div>
                            <div class="col-md-4">
                                <div class="">
                                    <label class="form-label">Recurring Interval (In months)</label>
                                    <select class="form-control form-control-sm" v-model="recurring_interval">
                                        <option :value="1">Every Month</option>
                                        <option :value="3">Every 3 Months</option>
                                        <option :value="6">Every 6 Months</option>
                                        <option :value="12">Yearly</option>
                                        <option :value="0">Custom</option>
                                        <option :value="99">I'll decide later</option>
                                    </select>
                                </div>
                            </div>
                            <div class="col-md-4"></div>
                            <div class="col-md-4"></div>
                            <div class="col-md-4" v-if="!recurring_interval">
                                <div class="form-group">
                                    <label class="form-label">Custom Interval in months</label><br>
                                    <el-input-number v-model="form.recurring_interval" controls-position="right"
                                        :min="1" :max="60" step-strictly></el-input-number>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div v-if="step == 1" style="text-align:center">
                    <div class="" style="padding-top: 20px" v-if="!form.is_framework">
                        <label>Tender Options</label>
                        <div :class="{ 'has-error-field': v.form.procedure_type.$errors.length }">
                            <el-radio-group v-model="form.procedure_type">
                                <el-radio :value="0" size="large">Open Procedure</el-radio>
                                <el-radio :value="1" size="large">Restricted Procedure</el-radio>
                            </el-radio-group>
                        </div>
                        <div v-if="v.form.procedure_type.$errors.length" class="error-text">Select tender option</div>
                    </div>
                </div>

                <div v-if="step == 2">
                    <procurement-projects-form :selected="form"
                        @update="detail_form = $event"></procurement-projects-form>
                </div>

                <div v-if="step > 2 && detail_form" style="margin-left: 50px;margin-right: 50px">
                    <div class="row">
                        <div class="col-md-12">
                            <div><strong>Project Name:</strong><br> {{ detail_form.name }}</div>
                            <div v-if="form.is_framework">
                                <strong>Framework Option:</strong><br> {{ form.framework_category < 2 ? ( form.framework_category == 1 ? 'Open Framework':'Closed Framework' ) : form.category == 1 ? 'Open Framework' :
                                    'Closed Framework' }} <span v-if="form.framework_category == 2">(Framework Tender)</span>
                                <div v-if="form.category == 1">
                                    <strong>Recurring Interval: </strong> <span>{{ form.recurring_interval < 99 ? form.recurring_interval : "I'll decide later" }}</span>
                                </div>
                            </div>
                            <div v-if="!form.is_framework">Tender Option: {{ form.category == 1 ? 'Restricted Procedure'
                                :
                                'Open Procedure' }}</div>
                            <div>{{ detail_form.description }}</div>
                            <hr>
                        </div>
                        <div class="col-md-4">
                            <div><strong>Main Description</strong></div>
                            <div v-html="detail_form.main_description"></div>

                            <div><strong>Common Description</strong></div>
                            <div v-html="detail_form.common_description"></div>
                        </div>
                        <div class="col-md-4">
                            <div><strong>My Files Description</strong></div>
                            <div v-html="detail_form.my_files_description"></div>
                            <div><strong>My Questions Description</strong></div>
                            <div v-html="detail_form.my_questions_description"></div>
                        </div>
                        <div class="col-md-4">
                            <div><strong>Specification Description</strong></div>
                            <div v-html="detail_form.spec_description"></div>
                            <div><strong>Q&A Description</strong></div>
                            <div v-html="detail_form.qa_description"></div>
                        </div>
                    </div>
                </div>

                <div style="text-align: center">
                    <br>
                    <el-button-group>
                        <el-button type="primary" v-if="step > 0" @click="handleBack"><i
                                class="fa fa-arrow-left"></i>Back</el-button>
                        <el-button type="primary" @click="handleValidate" v-if="step <= 2">
                            Next <i class="fa fa-arrow-right"></i>
                        </el-button>
                        <el-button type="primary" @click="handleValidate" v-if="step > 2">
                            Submit
                        </el-button>
                    </el-button-group>
                </div>
            </div>
        </el-form>
    </div>

</template>

<script>
import {
    useVuelidate
} from '@vuelidate/core'
import {
    required
} from '@vuelidate/validators'
import { f } from 'vue-search-select';
import ProcurementProjectsForm from './ProcurementProjectsForm.vue';
import { ProcurementProjects } from '@/api_services/procurement_projects';
import Swal from 'sweetalert2';


export default {
    setup: () => ({
        v: useVuelidate()
    }),
    props: {
        selected: {
            type: Object,
            required: false
        }
    },
    components: {
        ProcurementProjectsForm
    },
    data() {
        return {
            first_step_1: true,
            step: 0,
            form: {
                recurring_interval: 1,
                is_framework: true
            },
            procurement_types: [],
            detail_form: {},
            recurring_interval: 1,
            submit: 0,
            loading: false
        }
    },
    validations() {
        let form = {
            project_category: {
                required,
                $autoDirty: true
            },
        }

        if (this.step == 1 && this.form.is_framework) {
            form.framework_category = {
                required,
                $autoDirty: true
            }

            if (this.form.framework_category == 2) {
                form.category = {
                    required,
                    $autoDirty: true
                }
            }
        }

        if (this.step == 1 && !this.form.is_framework) {
            form.procedure_type = {
                required,
                $autoDirty: true
            }
        }

        return { form }
    },
    created: function () {
        this.init()
        this.getProcurementProjectTypes()
        if (this.selected) this.form = Object.assign({}, this.selected)
        if (!this.form.id) {
            this.form.recurring_interval = 1
            this.form.is_framework = true
        }
    },
    watch: {
        selected() {
            this.form = {}
            if (this.selected) this.form = Object.assign({}, this.selected)
            if (!this.form.id) this.form.recurring_interval = 1
        },
        recurring_interval() {
            this.form.recurring_interval = this.recurring_interval
        }
    },
    computed: {
        title() {
            return this.isNew ? 'New Project' : `Manage Project`
        },
        isNew() {
            return !this.selected?.id
        },
        typeLabel() {
            let label = ""
            let self = this
            this.procurement_types.forEach(function (type) {
                if (self.form.type == type.type_code) label = type.type
                if (self.detail_form && self.detail_form.type == type.type_code) label = type.type
            })
            return label
        }
    },
    methods: {
        changeFrameworkCategory(){
            if (this.form.framework_category < 2) this.form.category = this.form.framework_category
        },
        showRecurringInterval(){
            if (this.form.framework_category == 1) return true
            if (this.form.framework_category == 2 && this.form.category == 1) return true

            return false
        },
        init(){
            this.form = { is_framework: true}
        },
        getProcurementProjectTypes() {
            ProcurementProjects.getProcurementProjectTypes()
                .then(resp => {
                    this.procurement_types = resp.data.procurement_project_types
                })
                .catch(error => {
                    this.$toast.error('An error occured while getting procurement project types')
                    console.error('error:', error.response.data)
                })
        },
        handleChangeCategory() {
            this.first_step_1 = false
        },
        handleBack() {
            if (this.step > 1) this.first_step_1 = false
            this.step -= 1
            if (this.step == 2) {
                this.form = Object.assign({}, this.detail_form)
            }
        },
        async handleValidate(evt) {

            evt.preventDefault()

            const result = await this.v.$validate()
            if (!result) return

            if (this.step > 2) {
                this.beforeSubmit()
                return
            }

            this.step += 1
        },
        beforeSubmit(){
            Swal.fire({
            title: 'Review',
            text: 'You are about to create a new project. Please verify that all the details are accurate. Would you like to proceed?',
            icon: 'info',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Submit',
            cancelButtonText: 'Cancel',
          }).then((result) => {
            if (result.isConfirmed) {
                this.handleSubmit()
            }
          });
        },
        handleSubmit(){
            this.loading = true
            let form = Object.assign({}, this.detail_form)
            form.recurring_interval = this.form.recurring_interval
            form.is_framework = this.form.is_framework
            form.category = this.form.framework_category < 2 ? this.form.framework_category : this.form.category
            form.procedure_type = this.form.procedure_type
            form.project_category = this.form.project_category
            form.is_framework_tender = this.form.framework_category == 2

            ProcurementProjects.create(form)
            .then(resp => {
                this.step = 0
                this.form = {}
                this.detail_form = null
                this.$toast.success(resp.data.message || 'Procurement Project has been saved successfully')
                this.init()
                this.$emit('save', this.form)
            })
            .catch(error => {
              console.error('error:', error.response.data)
            })
            .finally(() => {
              this.loading = false
            })
        }
    }
}
</script>