<template>
    <div>
        <el-button type="primary" @click="getPrevious()" v-if="previous_form">Fetch Previous Data</el-button>
    </div>
    <div class="card-body">
        <el-card>
            <div class="row">
                <div class="col-md-12">
                    <label>Is this procurement under a special regime? (optional)</label>
                    <br/><label style="color: #a9a9a9">Select all that apply.</label>
                    <span class="row"><el-checkbox v-model="form.is_concession" :checked="form.is_concession" @change="concessionChange()" :key="update">Concession <br/><span style="color: #a9a9a9">Main procurement category must be services or works.</span></el-checkbox></span>
                    <span class="row"><el-checkbox v-model="form.is_defense_and_security" :checked="form.is_defense_and_security" @change="defenseChange()" :key="update">Defence and security</el-checkbox></span>
                    <span class="row"><el-checkbox v-model="form.is_light_touch" :checked="form.is_light_touch" @change="lightTouchChange()" :key="update">Light touch<br/><span style="color: #a9a9a9">Main procurement category must be services.</span></el-checkbox></span>
                    <span class="row"><el-checkbox v-model="form.is_utilities" :checked="form.is_utilities" @change="utilityChange()" :key="update">Utilities</el-checkbox></span>
                    <span class="row">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;OR</span>
                    <span class="row"><el-checkbox v-model="form.is_no_special_regime" :checked="form.is_no_special_regime" @change="noRegimeChange()" :key="update">No special regime</el-checkbox></span>
                </div>
                <div class="col-md-12">
                    <label>What is the main procurement category?</label>
                    <br/><label style="color: #a9a9a9">Select the main type if more than one applies</label>
                    <div :class="{ 'has-error-field': v.form.procurement_category.$invalid }">
                        <el-radio-group v-model="form.procurement_category">
                            <el-radio :disabled="form.is_concession || form.is_light_touch" :value="0">Goods</el-radio>
                            <el-radio :value="1">Services</el-radio>
                            <el-radio :disabled="form.is_light_touch" :value="2">Works</el-radio>
                        </el-radio-group>
                    </div>
                    <div v-if="v.form.procurement_category.$invalid" class="error-text" >Category is required</div>
                </div>
                <div class="col-md-12">
                    <br/><label>Is the total value of the procurement above or below the relevant threshold?</label>
                    <div class="row" v-if="tpp_procurement_type == 'UK2'">
                        <el-radio-group v-model="form.threshold" border>
                            <el-radio :value="0">Above threshold</el-radio>
                            <el-radio :value="1">Below threshold</el-radio>
                        </el-radio-group>
                        <div class="col-md-3">
                            <el-button type="primary" @click="form.threshold = null">Clear selection</el-button>
                        </div>
                    </div>
                    <div class="row" v-if="tpp_procurement_type == 'UK4'">
                        <div :class="{ 'has-error-field': v.form.threshold.$invalid }">
                        <el-radio-group v-model="form.threshold" border class="col-md-4">
                            <el-radio :value="0">Above threshold - Open procedure</el-radio>
                            <el-radio :value="1">Above threshold - Competitive flexible procedure</el-radio>
                            <el-radio :value="2">Below threshold - Open procedure</el-radio>
                            <el-radio :value="3">Below threshold - Using dynamic market</el-radio>
                        </el-radio-group>
                    </div>
                    <div v-if="v.form.threshold.$invalid" class="error-text" >Threshold is required</div>
                </div>
                <div class="row" v-if="tpp_procurement_type == 'UK4'">
                    <div class="col-md-12">
                            <br/><label>Is a reduced tendering period being used?</label>
                            <br/><label style="color: #a9a9a9">Starting the day after tenders are invited and ending the day they must be submitted by. Normal minimum is 25 days, with electronic submission and if all associated tender documents are provided with the tender notice.</label>
                            <div :class="{ 'has-error-field': v.form.reduced_tendering_period.$invalid }">
                            <el-radio-group v-model="form.reduced_tendering_period" border>
                                <el-radio :value="true">Yes</el-radio>
                                <el-radio :value="false">No</el-radio>
                            </el-radio-group>
                        </div>
                        <div v-if="v.form.reduced_tendering_period.$invalid" class="error-text" >Yes/No is required</div>
                    </div>
                    <div class="col-md-6" v-if="form.reduced_tendering_period">
                        <br/><label>Justification for reduced tendering period</label>
                        <div :class="{ 'has-error-field': v.form.reduced_justification.$invalid }">
                            <el-radio-group v-model="form.reduced_justification" border>
                                <el-radio :value="0">State of urgency - minimum 10 days</el-radio>
                            </el-radio-group>
                        </div>
                        <div v-if="v.form.reduced_justification.$invalid" class="error-text" >Justification is required</div>
                    </div>
                    <div class="col-md-6">
                        <br/><label>Has preliminary market engagement been carried out?</label>
                        <div :class="{ 'has-error-field': v.form.market_engagement_carried.$invalid }">
                            <el-radio-group v-model="form.market_engagement_carried" border>
                                <el-radio :value="true">Yes</el-radio>
                                <el-radio :value="false">No</el-radio>
                            </el-radio-group>
                        </div>
                        <div v-if="v.form.market_engagement_carried.$invalid" class="error-text" >Yes/No is required</div>
                    </div>
                    <div class="col-md-6">
                        <br/><label>Justification for not publishing a preliminary market engagement notice</label>
                        <el-input v-model="form.market_engagement_justification" type="textarea" :rows="3"></el-input>
                    </div>
                </div>
            </div>
            </div>
        </el-card>
    </div>
    <span class="col-md-3 mb-3" v-if="is_not_submitted"><el-button type="success" :disabled="v.form.$invalid" @click="saveForm()">Save and go to Next Section</el-button></span>
 </template>
 
 <script>
    import { mapState } from 'vuex'
     import {
     useVuelidate
     } from '@vuelidate/core'
     import {
     required,
     requiredIf
     } from '@vuelidate/validators'
 
     const PROCEDURE = 'PROCEDURE';

     export default {
         props: ['previous_form', 'saved_form', 'is_not_submitted', 'tpp_procurement_type'],
         data() {
             return {
                form: {
                    is_concession: null, 
                    is_defense_and_security: null, 
                    is_utilities: null, 
                    is_no_special_regime: null, 
                    procurement_category: null, 
                    threshold: null,
                    //UK4
                    reduced_tendering_period: null,
                    reduced_justification: null,
                    market_engagement_carried: null,
                    market_engagement_justification: null
                },
                update: 0
             }
         },
        setup: () => ({
             v: useVuelidate()
        }),
        created() {
            if(this.saved_form) this.form = this.saved_form

            console.log('tpp procurement type', this.tpp_procurement_type)

        },
        computed: {
        ...mapState(['TPPNoticeTypes', 'TPPFormParts']),
        },
        watch: {
            saved_form: {
                handlers(form){
                    this.form = form
                },
                deep: true
            }
        },
        validations() {
            let rules = {
                form: {
                    procurement_category: {
                        required,
                        $autoDirty: true
                    },

                }
            }


            if(this.tpp_procurement_type == this.TPPNoticeTypes.UK4.value) {
                let uk4 = {
                    threshold: {
                        required,
                        $autoDirty: true
                    },
                    reduced_tendering_period: {
                        required,
                        $autoDirty: true
                    },
                    reduced_justification: {
                        required: requiredIf(function () {return this.form.reduced_tendering_period}),
                        $autoDirty: true
                    },
                    market_engagement_carried: {
                        required,
                        $autoDirty: true
                    },
                    market_engagement_justification: {
                        required,
                        $autoDirty: true
                    },
                }

                rules.form = {...rules.form, ...uk4}
            }
            return rules
        },
        methods: {
            saveForm() {
                this.$emit('save', PROCEDURE, this.form)
            },
            getPrevious() {
                delete this.previous_form.id
                this.form = this.previous_form
            },
            concessionChange() {
                if(this.form.procurement_category === 0)this.form.procurement_category = null
                this.form.is_no_special_regime = null
                this.update++
            },
            defenseChange() {
                this.form.is_no_special_regime = null
                this.update++
            },
            lightTouchChange() {
                this.form.procurement_category = null
                this.form.is_no_special_regime = null
                if(this.form.is_light_touch)this.form.procurement_category = 1
                this.update++
            },
            utilityChange() {
                this.form.is_no_special_regime = null
                this.update++
            },
            noRegimeChange() {
                this.form.is_concession = null
                this.form.is_defense_and_security = null
                this.form.is_utilities = null
                this.form.is_light_touch = null
                this.update++
            }
        }

     }
 </script>