<template>
    <button class="">Jason Btn</button>
</template>
  
  <script>
  

  export default {
    components: {
        
    },
    props: {
        
    },
    data() {
        return {
            
        }
    },
    watch: {
        
    },
    computed: {
        
    },
    created: function () {
        
    },
    methods: {
        
    }
  }
  </script>
  