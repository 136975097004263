<template>
    <div class="card-body">
        <!-- <a @click="step != 0 ? step = 0 : step = null" :disabled="true">
            <el-card shadow="never" style="background: #337ecc" class="mb-2 p-0 border-0"  >
                <span class="m-0 text-white" style="font-size: 20px; font-weight: 700;">Notice Linking</span>
                    <span class="m-0 text-white" style="float: right; font-size: 15px; font-weight: 700;">{{ checkEachFormStatus(forms.preliminary) }}</span>
            </el-card>
        </a>
        <div class="row" v-if="step == 0">
            <notice-linking :procurement_status="procurement.status" :tpp_procurement_type="tpp_procurement_type" :saved_form="forms.preliminary" :previous_form="previous_forms?.preliminary" :user="savedUser" :is_not_submitted="is_not_submitted" @save="saveForm"></notice-linking>
        </div> -->

        <!-- <div v-if="forms.notice_linking"> -->
            <a @click="step != 1 ? step = 1 : step = null">
                <el-card shadow="never" style="background: #337ecc" class="mb-2 p-0 border-0"  >
                    <span class="m-0 text-white" style="font-size: 20px; font-weight: 700;">Contracting Authorities</span>
                    <span class="m-0 text-white" style="float: right; font-size: 15px; font-weight: 700;">{{ checkEachFormStatus(forms.contracting_authorities) }}</span>
                </el-card>
            </a>
            <div class="row" v-if="step == 1 || open_all">
                <contracting-authorities 
                    :tpp_procurement_type="tpp_procurement_type" 
                    :procurement_status="procurement.status" 
                    :key="step" 
                    :is_not_submitted="is_not_submitted"  
                    :saved_form="forms.contracting_authorities"
                    @save="saveForm">
                </contracting-authorities>
            </div>

            <div style="margin-left: 2em;">
                <div v-if="forms.contracting_authorities && forms.contracting_authorities.number_of_ca">
                    <div v-for="(authorities, index) in (forms.contracting_authorities.number_of_ca)" :key="authorities.id">
                        <a @click="step_contracting != index ? step_contracting = index : step_contracting = null">
                            <el-card shadow="never" style="background: #337ecc" class="mb-2 p-0 border-0"  >
                                <span class="m-0 text-white" style="font-size: 20px; font-weight: 700;">Contracting Authority {{ (forms.contracting_authorities.number_of_ca > 1) ? index + 1 : ''}}</span>
                                <span class="m-0 text-white" style="float: right; font-size: 15px; font-weight: 700;">{{ checkEachFormStatus(forms.contracting_authorities_info ? (forms.contracting_authorities_info[index] !== undefined ? forms.contracting_authorities_info[index] : 0) : 0) }}</span>
                            </el-card>
                        </a>
                        <div v-if="static_data && index == step_contracting || open_all">
                            <contracting-authority-info 
                                :tpp_procurement_type="tpp_procurement_type" 
                                :saved_form="forms.contracting_authorities_info ? (forms.contracting_authorities_info[index] !== undefined ? forms.contracting_authorities_info[index] : null) : null" 
                                :previous_form="previous_forms ? (previous_forms.contracting_authorities_info[index]  ? previous_forms.contracting_authorities_info[index] : null) : null" 
                                :is_not_submitted="is_not_submitted" 
                                :country_choices="static_data.countries", 
                                :index="index"
                                @save="saveForm">
                            </contracting-authority-info >
                        </div>
                    </div>
                </div>
            </div>

            <div style="margin-left: 2em;">
                <div v-if="forms.contracting_authorities && forms.contracting_authorities.has_other_organisation">
                    <div v-for="(org, index) in (forms.contracting_authorities.number_other_org)" :key="org.id">
                        <a @click="step_other_org != index ? step_other_org = index : step_other_org = null">
                            <el-card shadow="never" style="background: #337ecc" class="mb-2 p-0 border-0"  >
                                <span class="m-0 text-white" style="font-size: 20px; font-weight: 700;">Other Organisation {{ (forms.contracting_authorities.number_other_org > 1) ? index + 1 : ''}}</span>
                                <span class="m-0 text-white" style="float: right; font-size: 15px; font-weight: 700;">{{ checkEachFormStatus(forms.other_organisation ? (forms.other_organisation[index] !== undefined ? forms.other_organisation[index] : 0) : 0) }}</span>
                            </el-card>
                        </a>
                        <div v-if="static_data && index == step_other_org || open_all">
                            <other-organisation 
                                :tpp_procurement_type="tpp_procurement_type" 
                                :saved_form="forms.other_organisation ? (forms.other_organisation[index] !== undefined ? forms.other_organisation[index] : null) : null" 
                                :previous_form="previous_forms ? (previous_forms.other_organisation[index]  ? previous_forms.other_organisation[index] : null) : null" 
                                :is_not_submitted="is_not_submitted" 
                                :index="index"
                                :country_choices="static_data.countries" 
                                @save="saveForm">
                            </other-organisation>
                        </div>
                    </div>
                </div>
            </div>
            <div v-if="forms.contracting_authorities && (forms.contracting_authorities_info.length > 1 || forms.other_organisation.length)">
                <a @click="step != 2 ? step = 2 : step = null">
                    <el-card shadow="never" style="background: #337ecc" class="mb-2 p-0 border-0"  >
                        <span class="m-0 text-white" style="font-size: 20px; font-weight: 700;">Contact Organisation</span>
                        <span class="m-0 text-white" style="float: right; font-size: 15px; font-weight: 700;">{{ forms.contact_organisation ? 'SAVED' : 'DRAFT' }}</span>
                    </el-card>
                </a>
                <div class="row" v-if="step == 2 || open_all">
                    <contact-organisation 
                        :tpp_procurement_type="tpp_procurement_type" 
                        :is_not_submitted="is_not_submitted"  
                        :saved_form="forms.contact_organisation"
                        :organisations="forms.contracting_authorities?.has_other_organisation ? forms.contracting_authorities_info.concat(forms.other_organisation) : forms.contracting_authorities_info"
                        @save="saveForm">
                    </contact-organisation >
                </div>
            </div>
            <a @click="step != 3 ? step = 3 : step = null">
                <el-card shadow="never" style="background: #337ecc" class="mb-2 p-0 border-0"  >
                    <span class="m-0 text-white" style="font-size: 20px; font-weight: 700;">Procedure</span>
                    <span class="m-0 text-white" style="float: right; font-size: 15px; font-weight: 700;">{{ checkEachFormStatus(forms.procedure) }}</span>
                </el-card>
            </a>
            <div class="row" v-if="step == 3 || open_all">
                <procedure 
                    :tpp_procurement_type="tpp_procurement_type" 
                    :procurement_status="procurement.status" 
                    :is_not_submitted="is_not_submitted",
                    :saved_form="forms.procedure"  
                    @save="saveForm">
                </procedure>
            </div>
            <a @click="step != 4 ? step = 4 : step = null">
                <el-card shadow="never" style="background: #337ecc" class="mb-2 p-0 border-0"  >
                    <span class="m-0 text-white" style="font-size: 20px; font-weight: 700;">Scope</span>
                    <span class="m-0 text-white" style="float: right; font-size: 15px; font-weight: 700;">{{ checkEachFormStatus(forms.scope) }}</span>
                </el-card>
            </a>
            <div class="row" v-if="step == 4 || open_all">
                <scope 
                    :tpp_procurement_type="tpp_procurement_type" 
                    :procurement_status="procurement.status" 
                    :is_not_submitted="is_not_submitted",
                    :cpv_code_choices="static_data.cpv_code"
                    :currency_choices="static_data.currencies"
                    :nuts_code_choices="static_data.nuts_code"
                    :saved_form="forms.scope"
                    :concession="forms.procedure?.is_concession"
                    :utilities="forms.procedure?.is_utilities"
                    @save="saveForm">
                </scope>
            </div>

            <a @click="step != 5 ? step = 5 : step = null">
                <el-card shadow="never" style="background: #337ecc" class="mb-2 p-0 border-0"  >
                    <span class="m-0 text-white" style="font-size: 20px; font-weight: 700;">Participation</span>
                    <span class="m-0 text-white" style="float: right; font-size: 15px; font-weight: 700;">{{ checkEachFormStatus(forms.participation) }}</span>
                </el-card>
            </a>
            <div class="row" v-if="step == 5 || open_all">
                <participation 
                    :tpp_procurement_type="tpp_procurement_type" 
                    :procurement_status="procurement.status" 
                    :is_not_submitted="is_not_submitted",
                    :saved_form="forms.participation"
                    @save="saveForm">
                </participation>
            </div>

            <a @click="step != 6 ? step = 6 : step = null">
                <el-card shadow="never" style="background: #337ecc" class="mb-2 p-0 border-0"  >
                    <span class="m-0 text-white" style="font-size: 20px; font-weight: 700;">Engagement</span>
                    <span class="m-0 text-white" style="float: right; font-size: 15px; font-weight: 700;">{{ checkEachFormStatus(forms.engagement) }}</span>
                </el-card>
            </a>
            <div class="row" v-if="step == 6 || open_all">
                <engagement 
                    :tpp_procurement_type="tpp_procurement_type" 
                    :procurement_status="procurement.status" 
                    :is_not_submitted="is_not_submitted",
                    :saved_form="forms.engagement"
                    @save="saveForm">
                </engagement>
            </div>

            <a @click="step != 7 ? step = 7 : step = null">
                <el-card shadow="never" style="background: #337ecc" class="mb-2 p-0 border-0"  >
                    <span class="m-0 text-white" style="font-size: 20px; font-weight: 700;">Submission</span>
                    <span class="m-0 text-white" style="float: right; font-size: 15px; font-weight: 700;">{{ checkEachFormStatus(forms.submission) }}</span>
                </el-card>
            </a>
            <div class="row" v-if="step == 7 || open_all">
                <submission 
                    :tpp_procurement_type="tpp_procurement_type" 
                    :procurement_status="procurement.status" 
                    :is_not_submitted="is_not_submitted",
                    :saved_form="forms.submission"
                    :contract_start_date="forms.scope?.start_date"
                    :engagement_deadline="forms.engagement?.engagement_deadline"
                    @save="saveForm">
                </submission>
            </div>
    </div>
</template>

<script>
import { mapState } from 'vuex'
import { Tpp } from '@/api_services/fts_tpp'
import Swal from 'sweetalert2';

import NoticeLinking from '../sections/NoticeLinkingComponent'
import ContractingAuthorities from '../sections/ContractingAuthorities';
import ContractingAuthorityInfo from '../sections/ContractingAuthorityInfo';
import ContactOrganisation from '../sections/ContactOrganisation';
import Engagement from '../sections/Engagement';
import OtherOrganisation from '../sections/OtherOrganisation';
import Participation from '../sections/Participation';
import Procedure from '../sections/Procedure';
import Scope from '../sections/Scope';
import Submission from '../sections/Submission';

export default {
    data(){
        return {
            index: 0,
            update: 0,
            step: null,
            step_contracting: null,
            step_other_org: null,
            form_id: null,
            static_data: null,
            previous_forms: null,
            previous_all_form: null,
            forms: {
                contact_organisation: null,
                contracting_authorities: null,
                contracting_authorities_info: [],
                engagement: null,
                notice_linking: [],
                other_organisation: [],
                participation: [],
                procedure: null,
                scope: null,
                submission: null,
            },
            all_form: null
        } 
    },
    props: ['procurement', 'tpp_procurement_type', 'is_not_submitted', 'open_all'],
    components: {
        NoticeLinking,
        ContactOrganisation,
        ContractingAuthorities,
        ContractingAuthorityInfo,
        Engagement,
        OtherOrganisation,
        Participation,
        Procedure,
        Scope,
        Submission
    },
    computed: {
        ...mapState(['savedUser', 'ProcurementProjectStatuses', 'TPPNoticeTypes', 'TPPFormParts']),
        validated() {
            let validated = false;
            
            return validated
        },

        checkAllFormStatus() {
            if(this.all_form) {
                switch (this.all_form.overall_form_status) {
                    case 0: return "Not Started"
                    case 1: return "In Progress"
                    case 2: return "Submitted"
                    case 3: return "Published"
                    case 9: return "Error"
                }
            }
            
            else return 'Not Started'
        }
    },
    created() {
        this.getAllForms()
        this.getStaticData()    

    },
    methods: {
        saveForm(form_type, form_values) {
                let procurement_type = this.tpp_procurement_type
                form_values.form_type = form_type;
                form_values.form_status = 1; // 1 is completed
                form_values.procurement_type = procurement_type
                form_values.procurement_project_id = this.procurement.id 
                form_values.procurement_forms_id = this.form_id 


                
                Tpp.create(procurement_type, form_values).then(result => {
                    this.$toast.success('Saved successfully')
                    this.getAllForms()
                    if(form_type == this.TPPFormParts.CONTRACTING_AUTH_INFO ) 
                    {
                        if(this.forms.contracting_authorities.number_of_ca > this.forms.contracting_authorities_info.length)
                            this.step_contracting++
                        else {
                            this.step_contracting = null
                            if(this.forms.contracting_authorities.has_other_organisation) this.step_other_org = 0
                            else this.step = 3
                        }   
                    }
                    else if(form_type == this.TPPFormParts.OTHER_ORG) {
                        if(this.forms.contracting_authorities.number_other_org > this.forms.other_organisation.length)
                        this.step_other_org++
                        else {
                            this.step_other_org = null
                            this.step = 2
                        }
                    }
                    else if(form_type == this.TPPFormParts.CONTRACTING_AUTH) {
                        if(this.forms.contracting_authorities.number_of_ca > 1 || this.forms.contracting_authorities.has_other_organisation) {
                            this.step_contracting = 0
                            this.step = null;
                        } 
                        else {
                            this.step++
                        }
                    }
                    else {
                            this.step_other_org = null;
                            this.step_contracting = null;
                            this.step++
                    }
                })
        },
        getAllForms() {
            Tpp.getAllForms(this.procurement.id, this.tpp_procurement_type).then(result => {
                if(result.data.procurement_project_all_form != null) {
                    this.forms = result.data
                    this.all_form = result.data.procurement_project_all_form;

                    this.forms.contracting_authorities_info.forEach(org => {
                        if(org.is_main_contact) {
                            this.forms.contact_organisation = org
                        }
                    })
                    this.forms.other_organisation.forEach(org => {
                        if(org.is_main_contact) {
                            this.forms.contact_organisation = org
                        }
                    })
                }

                else this.step = 0;
            })
        },
        getPreviousForms() {
            Tpp.getAllForms(this.procurement.id, this.tpp_procurement_type, 1).then(result => {
                if(result.data.procurement_project_all_form != null) {
                    this.previous_forms = result.data
                    this.previous_all_form = result.data.procurement_project_all_form;

                }

                else this.step = 0;

            })
        },
        getStaticData() {
            Tpp.getStaticData().then(result => {                
                this.static_data = result.data
            })
        },
        checkEachFormStatus(form_type) {
            if(form_type && "id" in form_type) {
                switch(form_type.form_status) {
                    case 0: return "DRAFT"
                    case 1: return "SAVED"
                }
            }
            
            return "DRAFT"
        },
    }
}
</script>