<template>
    <div>
        <el-button type="primary" @click="getPrevious()" v-if="previous_form">Fetch Previous Data</el-button>
    </div>
    <div class="card-body">
        <el-card>
            <div class="row">
                <div class="col-md-12">
                    <h4>Notice Linking</h4>
                    <br/><label>Have any other notices been published about this procurement?</label>
                    <div :class="{ 'has-error-field': v.common.has_published_procurement.$invalid }">
                        <el-radio-group v-model="common.has_published_procurement" placeholder="Select" style="width: 100%;">
                            <el-option :label="'Yes'" :value="true"></el-option>
                            <el-option :label="'No'" :value="false"></el-option>
                        </el-radio-group>
                    </div>
                    <div v-if="v.commin.has_published_procurement.$invalid" class="error-text" >Yes/No is required.
                </div>
                <!-- <div class="col-md-12">
                    <br/><label>Search for the title</label>
                    <br/><label style="color: #a9a9a9">Also searches for the OCID, for example, ocds-h6vhtk-1234a5</label>
                    <div :class="{ 'has-error-field': v.common.procurement_link.$invalid }">
                        <el-input v-model="common.procurement_link" style="width: 100%;"></el-input>
                    </div>
                    <div v-if="v.common.procurement_link.$invalid" class="error-text" >Link is required</div>
                </div> -->
            </div>
            </div>
        </el-card>
    </div>
    <span class="col-md-3 mb-3" v-if="is_not_submitted"><el-button type="success" :disabled="v.form.$invalid" @click="saveForm()">Save and go to Next Section</el-button></span>
 </template>
 
 <script>
    import { mapState } from 'vuex'
     import {
     useVuelidate
     } from '@vuelidate/core'
     import {
     required, requiredIf
     } from '@vuelidate/validators'
 
     const NOTICE_LINKING = 'NOTICE_LINKING';

     export default {
         props: ['previous_form', 'user', 'procurement_id', 'saved_form', 'is_not_submitted', 'procurement_status', 'fat_procurement_type'],
         data() {
             return {
                common: {
                    has_published_procurement: null,
                    procurement_link: null,
                }
             }
         },
        setup: () => ({
             v: useVuelidate()
        }),
        created()  {
            if(this.saved_form) this.form = this.saved_form

        },
        computed: {
        ...mapState(['TPPNoticeTypes', 'TPPFormParts']),
        },
        watch: {
            saved_form: {
                handlers(form){
                    this.form = form
                },
                deep: true
            }
        },
        validations() {
            let rules = {
                has_published_procurement: {
                    required,
                    $autoDirty: true
                },
                procurement_link: {
                    required: requiredIf(() => { return this.common.has_published_procurement == true})
                }
            }
            return rules
        },
        methods: {
            saveForm() {
                this.$emit('save', NOTICE_LINKING, this.form)
            },
            getPrevious() {
                delete this.previous_form.id
                this.form = this.previous_form
            }
        }

     }
 </script>
 