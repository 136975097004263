<style>
    .el-color-dropdown__main-wrapper{
        display: none !important;
    }
    .el-color-predefine__color-selector{
      border: 1px solid silver
    }
</style>
<template>
    <el-input v-model="model.value" @change="change" />
  </template>
  
  <script>

  export default {
    computed: {
        user() {
          return this.$store.state.savedUser // get state
        }
    },
    emits :{
        
    },
    props: ['data', 'main_property_name', 'property_name', 'main_key', 'secondary_key'],
    data() {
      return {
        model: { value: null },
        theme: null,
      }
    },
    watch: {
      
    },
    created: function() {
        if(this.data?.value){
            if(this.data?.value && this.data?.value != "default"){
              this.model = this.data
            }
        }

        this.theme = this.user?.selected_theme?.theme_content
    },
    methods: {
        change(value){
            if(this.theme[this.main_property_name][this.property_name]){
                this.theme[this.main_property_name][this.property_name].value = value
            }
        }
    }
  }
  </script>
  