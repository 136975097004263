
<template>
  <aside id="sidenav-main"
    class="sidebar_background sidenav navbar navbar-vertical navbar-expand-xs border-0 border-radius-xl my-3 ms-4 fixed-start ms-3">
    <div class="sidenav-header">
      <i id="iconSidenav"
        class="top-0 p-3 cursor-pointer fas fa-times text-secondary opacity-5 position-absolute end-0 d-none d-xl-none"
        aria-hidden="true"></i>
      <span class="m-0 navbar-brand navigation_title_font_color">

        <template v-if="theme?.logo" v-for="(logo, main_key, main_index) in theme.logo">
          <template v-if="main_key == 'sidebar_logo'" v-for="(logo_value, key, index) in logo.value">
            <img v-if="logo_value.is_selected" :src="logo_value?.default == '1' ? default_logo : logo_value.url" class="navbar-brand-img h-100" alt="main_logo" />
          </template>
        </template> 
        
        <span class="ms-2 font-weight-bold">{{ theme?.navigation_title?.text?.value }}</span><br><span style="margin-left: 35px;font-size: 12px;"
          class="font-weight-bold">Procurement Platform</span> 
      </span>
    </div>

    <hr class="mt-0 horizontal dark" />

    <div id="sidenav-collapse-main" class="collapse navbar-collapse w-auto h-auto h-100">
      <ul class="navbar-nav">
        <sidenav-item class="navigation_font_color" v-if="isViewable('dashboard')" text="Dashboard" to="/main/dashboard">
          <template #icon>
            <i class="ni ni-spaceship menu-icon-color text-sm opacity-10"></i>
          </template>
        </sidenav-item>

        <sidenav-collapse class="navigation_font_color" text="Procurement" :active="false" v-if="isViewable('products')">
          <template #icon>
            <i class="fa-solid fa-cart-plus menu-icon-color text-sm opacity-10"></i>
          </template>
          <template #list>
            <ul class="nav ms-4">

              <sidenav-collapse-item class="navigation_font_color" mini-icon="P" text="My procurement progress" v-if="isViewable('buyer')"
                to="/main/product_search_queries/list"></sidenav-collapse-item>
              <sidenav-collapse-item class="navigation_font_color" mini-icon="P" text="My Saved Searches" v-if="isViewable('buyer')"
                to="/main/saved_searches"></sidenav-collapse-item>
              <sidenav-collapse-item class="navigation_font_color" mini-icon="C" text="My Call-off Documents" v-if="isViewable('buyer')"
                to="/main/buyer-call-off-documents"></sidenav-collapse-item>
              <sidenav-collapse-item class="navigation_font_color" mini-icon="M" text="Correspondence" v-if="isViewable('buyer')"
                to="/main/product_search_messages"></sidenav-collapse-item>
            </ul>
          </template>
        </sidenav-collapse>


        <sidenav-collapse text="BCP" class="navigation_font_color" :active="false" v-if="isViewable('bcp')" @click="showSidebar">
          <template #icon>
            <i class="fa-solid fa-gauge menu-icon-color text-sm opacity-10"></i>
          </template>
          <template #list>
            <ul class="nav ms-4">
              <sidenav-collapse-item class="navigation_font_color" @click="closeSidebar" mini-icon="S" text="Overview" icon="fas fa-eye"
                to="/main/bcp/overview"></sidenav-collapse-item>
              <sidenav-collapse-item class="navigation_font_color" @click="closeSidebar" mini-icon="C" text="Suppliers" icon="fas fa-list"
                to="/main/bcp/suppliers" v-if="isViewable('buyer') "></sidenav-collapse-item>
            </ul>
          </template>
        </sidenav-collapse>


        <sidenav-collapse class="navigation_font_color" text="Account" :active="false">
          <template #icon>
            <i class="fa-regular fa-user menu-icon-color text-sm opacity-10"></i>
          </template>
          <template #list>
            <ul class="nav ms-4">
              <sidenav-collapse-item class="navigation_font_color" mini-icon="S" text="Settings" to="/main/account-setting"></sidenav-collapse-item>
              <sidenav-collapse-item class="navigation_font_color" mini-icon="M" text="Manage Account"
                to="/main/user_account"></sidenav-collapse-item>

              <sidenav-collapse-item class="navigation_font_color" mini-icon="S" text="Company Details" to="/main/supplier"></sidenav-collapse-item>
            </ul>
          </template>
        </sidenav-collapse>


        <sidenav-item class="navigation_font_color" v-if="isViewable('dashboard')" text="Contact Support Team" to="/main/support-ticket">
          <template #icon>
            <i class="ni ni-headphones menu-icon-color text-sm opacity-10"></i>
          </template>
        </sidenav-item>
      </ul>
    </div>

    <div class="mt-5 sidenav-footer">
      <sidenav-card :card="{
          links: [
            {
              label: 'Logout',
              route: '/logout',
              color: 'outline-danger'
            }
          ]
        }" />
    </div>
  </aside>
  <button @click="toggleSidebar" class="btn d-block d-xl-none btn-appearance">
    <i class="fas fa-bars" id="hamburger"></i>
  </button>

  <div class="d-flex flex-column flex-shrink-0 bg-light navbar-vertical navbar-expand-xs custom-navbar"
    id="custom-sidebar" style="width: 3.75rem;">
    <div id="sidenav-collapse-main" class="collapse navbar-collapse w-auto h-auto h-100" style="margin-top: 5rem">
      <ul class="navbar-nav">
        <sidenav-item v-if="isViewable('dashboard')" text="Dashboard" to="/main/dashboard" @click="closeSidebar">
          <template #icon>
            <i class="ni ni-spaceship menu-icon-color text-sm opacity-10 icon-sidebar"></i>
          </template>
        </sidenav-item>

        <sidenav-collapse text="BCP" :active="false" v-if="isViewable('bcp')" @click="showSidebar">
          <template #icon>
            <i class="fa-solid fa-gauge menu-icon-color text-sm opacity-10"></i>
          </template>
          <template #list>
            <ul class="nav ms-4">
              <sidenav-collapse-item @click="closeSidebar" mini-icon="S" text="Overview" icon="fas fa-eye"
                to="/main/bcp/overview"></sidenav-collapse-item>
              <sidenav-collapse-item @click="closeSidebar" mini-icon="C" text="Suppliers" icon="fas fa-list"
                to="/main/bcp/suppliers" v-if="isViewable('suppliers')"></sidenav-collapse-item>
            </ul>
          </template>
        </sidenav-collapse>

        <sidenav-collapse text="Procurement" :active="false" v-if="isViewable('products')" @click="showSidebar">
          <template #icon>
            <i class="fa-solid fa-cart-plus menu-icon-color text-sm opacity-10 icon-sidebar"></i>
          </template>
          <template #list>
            <ul class="nav ms-4">

              <sidenav-collapse-item @click="closeSidebar" mini-icon="P" text="My procurement progress"
                v-if="isViewable('buyer')" to="/main/product_search_queries/list"></sidenav-collapse-item>
              <sidenav-collapse-item @click="closeSidebar" mini-icon="P" text="My Saved Searches"
                v-if="isViewable('buyer')" to="/main/saved_searches"></sidenav-collapse-item>
              <sidenav-collapse-item @click="closeSidebar" mini-icon="C" text="My Call-off Documents"
                v-if="isViewable('buyer')" to="/main/buyer-call-off-documents"></sidenav-collapse-item>

              <sidenav-collapse-item @click="closeSidebar" mini-icon="F" text="My Call-Off Documents"
                v-if="isViewable('supplier')" to="/main/call-offs"></sidenav-collapse-item>
              <sidenav-collapse-item @click="closeSidebar" mini-icon="M" text="Correspondence"
                v-if="isViewable('buyer')" to="/main/product_search_messages"></sidenav-collapse-item>
            </ul>
          </template>
        </sidenav-collapse>

        <sidenav-collapse text="Account" :active="false" @click="showSidebar">
          <template #icon>
            <i class="fa-regular fa-user menu-icon-color text-sm opacity-10 icon-sidebar"></i>
          </template>
          <template #list>
            <ul class="nav ms-4">

              <sidenav-collapse-item @click="closeSidebar" mini-icon="S" text="Settings"
                to="/main/account-setting"></sidenav-collapse-item>
              <sidenav-collapse-item @click="closeSidebar" mini-icon="M" text="Manage Account"
                to="/main/user_account"></sidenav-collapse-item>

              <sidenav-collapse-item @click="closeSidebar" mini-icon="S" text="Company Details"
                to="/main/supplier"></sidenav-collapse-item>
            </ul>
          </template>
        </sidenav-collapse>


        <sidenav-item v-if="isViewable('dashboard')" text="Contact Support Team" to="/main/support-ticket">
          <template #icon>
            <i class="ni ni-headphones menu-icon-color text-sm opacity-10 icon-sidebar"></i>
          </template>
        </sidenav-item>
      </ul>
    </div>
  </div>

</template>

<script>
import SidenavItem from './SidenavItem.vue'
import SidenavCollapse from './SidenavCollapse.vue'
import SidenavCollapseItem from './SidenavCollapseItem.vue'
import SidenavCard from './SidenavCard.vue'
import { mapState } from 'vuex'
import CPRAS_logo from '@/assets/CPRAS_logo.png'

export default {
  components: {
    SidenavItem,
    SidenavCollapse,
    SidenavCollapseItem,
    SidenavCard
  },
  data() {
    return {
      root_path: process.env.VUE_APP_API_ROOT,
      default_logo: CPRAS_logo,
      theme_type: null,
      theme: null,
      route_name: null,
      is_website_bcp: process.env.VUE_APP_IS_BCP
    }
  },
  watch: {
    $route(to, from) {
      this.route_name = to.name
    },
    'user.selected_theme.theme_content':{
      handler(value){
        this.theme_type = this.user?.selected_theme?.type
        this.theme = this.user?.selected_theme?.theme_content
      },
      deep:true
    },
  },
  created: function () {
    this.route_name = this.$route.name

    if(this.user?.selected_theme?.theme_content){
      this.theme_type = this.user?.selected_theme?.type
      this.theme = this.user?.selected_theme?.theme_content
    }
  },
  computed: {
    ...mapState(['ApplicationThemeTypes']),
    user() {
      return this.$store.state.savedUser // get state
    },
    isCardTransactionsActive() {
      return [
        'card_transactions',
        'card_types',
        'card_type_terms',
        'card_imports',
        'invoices'
      ].includes(this.route_name)
    },
    isBankReportsActive() {
      return [
        'br_transaction_list',
        'br_transaction_reports',
        'br_transaction_history',
        'br_tariff_rates',
        'br_freebankings',
        'br_imports',
        'br_invoices',
        'br_exports'
      ].includes(this.route_name)
    },
    isSettingsActive() {
      return [
        'users',
        'roles',
        'permissions',
        'specifications',
        'authorization_fees'
      ].includes(this.route_name)
    },
    isBankingToolActive() {
      return [
        'banking_supplier_prospects',
        'banking_tariff_rates',
        'banking_cost_comparison',
        'banking_cost_comparison_edit',
        'banking_reports'
      ].includes(this.route_name)
    },
    isFinanceModuleActive() {
      return ['finance_module_calendar'].includes(this.route_name)
    }
  },
  methods: {
    toggleSidebar() {
      const sidenavMain = document.getElementById('custom-sidebar');
      const hamburgerBtn = document.getElementById('hamburger');

      if (sidenavMain.classList.contains('show-sidebar')) {
        sidenavMain.classList.remove('show-sidebar');
        hamburgerBtn && hamburgerBtn.classList.remove('fa-close');
        hamburgerBtn && hamburgerBtn.classList.add('fa-bars');
      } else {
        sidenavMain.classList.add('show-sidebar');
        hamburgerBtn && hamburgerBtn.classList.add('fa-close');
        hamburgerBtn && hamburgerBtn.classList.remove('fa-bars');
      }
    },
    showSidebar() {
      const sidenavMain = document.getElementById('custom-sidebar');
      const hamburgerBtn = document.getElementById('hamburger');

      sidenavMain.classList.add('show-sidebar');
      hamburgerBtn && hamburgerBtn.classList.add('fa-close');
    },
    closeSidebar() {
      const sidenavMain = document.getElementById('custom-sidebar');
      const hamburgerBtn = document.getElementById('hamburger');

      setTimeout(function () {
        sidenavMain.classList.remove('show-sidebar');
        hamburgerBtn && hamburgerBtn.classList.remove('fa-close');
        hamburgerBtn && hamburgerBtn.classList.add('fa-bars');
      }, 1)

    },
    isActive(name) {
      return this.route_name === name
    },
    isViewable(name) {
      var response = false

      if (this.user?.bcp_status == 1 && this.isBuyer()) {
        let listAllowed = ['DASHBOARD', 'BCP', 'BUYER', 'PRODUCTS']
        if (!listAllowed.includes(name.toUpperCase())) return false
      }


      if (this.user) {
        if (this.user.permissions) {
          this.user.permissions.forEach(function (permission) {
            if (
              permission.action === 'VIEW' &&
              permission.module.toLowerCase() === name
            ) {
              response = true
            }
          })
        }
      }
      return response
    },
    isBuyer() {
      let isBuyer = false
      this.user.role_names.forEach(function (role) {
        if (role.toUpperCase() == 'BUYER') isBuyer = true
      })

      return isBuyer
    },
    isSupplier() {
      let isSupplier = false
      this.user.role_names.forEach(function (role) {
        if (role.toUpperCase() == 'SUPPLIER') isSupplier = true
      })

      return isSupplier
    }
  }
}
</script>

<style scoped>

.menu-icon-color{
  color: v-bind('theme?.navigation?.icon_color?.value') !important
}

.navbar-vertical.navbar-expand-xs {
  z-index: 1039;
}

.icon-sm i.fa,
.icon-sm i.fa-solid,
.icon-sm i.fas {
  top: 0;
}

.navbar-vertical.navbar-expand-xs .navbar-nav ::v-deep .nav-link {
  font-size: 0.835rem;
}

.btn-appearance {
  position: fixed;
  z-index: 9999;
  left: 0.25rem;
}

.icon-sidebar {
  padding-right: 1.1rem !important;
}

@media (min-width: 1200px) {
  .btn-appearance {
    display: hidden;
  }

  .custom-navbar {
    left: -18.5rem !important;
  }
}

.show-sidebar {
  width: 18.5rem !important;
  transition: 0.2s;
}
</style>