<template>
    <div>
        <el-button type="primary" @click="getPrevious()" v-if="previous_form">Fetch Previous Data</el-button>
    </div>
    <div class="card-body">
        <el-card>
            <div class="row">
                <h3 class="col-md-12">Section IV. Procedure</h3>
                <div v-if="this.fat_procurement_type == this.FATNoticeTypes.F02.value">
                    <h4 class="col-md-12">IV.1. Description</h4>
                    <!-- <div class="col-md-12">
                        <label>IV.1.1. Procedure type</label>
                        <br/>Procedure type in preliminary questions
                    </div> -->
                    <div>
                        <br/><el-checkbox v-model="form.accelerated_procedure">Accelerated procedure (optional)</el-checkbox>
                    </div>
                    <div class="col-md-12" v-if="form.accelerated_procedure">
                        <label>Justification</label>
                        <br/>
                        <div :class="{ 'has-error-field': v.form.accelerated_justification.$invalid }">
                            <el-input type="textarea" :rows="3" v-model="form.accelerated_justification"></el-input>
                        </div>
                        <div v-if="v.form.accelerated_justification.$invalid" class="error-text" >Operator selection is required</div>
                    </div>
                    <div class="col-md=12">
                        <br/><label>IV.1.3. Information about a framework agreement or a dynamic purchasing system</label>                    
                        <br/><el-checkbox v-model="form.involves_framework_agreement" :checked="form.involves_framework_agreement">The procurement involves the establishment of a framework agreement (optional)</el-checkbox>
                        <!-- <br/><el-checkbox v-model="form.involves_dynamic_purchasing_system" :checked="form.involves_dynamic_purchasing_system">The procurement involves the setting up of a dynamic purchasing system (optional)</el-checkbox> -->
                    </div>
                    <div v-if="form.involves_framework_agreement">
                        <div class="col-md-6">
                            <br/><label>How many operators will be included in the framework agreement?</label>
                            <br/><div :class="{ 'has-error-field': v.form.operators.$invalid }">
                                <el-radio-group v-model="form.operators">
                                    <el-radio border :value=0 label="0">Framework agreement with a single operator</el-radio>
                                    <el-radio border :value=1 label="1">Framework agreement with several operators</el-radio>
                                </el-radio-group>
                            </div>
                            <div v-if="v.form.operators.$invalid" class="error-text" >Operator selection is required</div>
                        </div>
                        <div v-if="form.operators == 1" div class="col-md-6">
                            <br/><label>Envisaged maximum number of participants to the framework agreement (optional)</label>
                            <div :class="{ 'has-error-field': v.form.number_of_participants.$invalid }">
                                <el-input v-model="form.number_of_participants"></el-input>
                            </div>
                            <div v-if="v.form.number_of_participants.$invalid" class="error-text" >Number of participants is required</div>
                        </div>

                        <div class="col-md-12">
                            <br/><label>In the case of framework agreements, provide justification for any duration exceeding 4 years (optional)</label>
                            <br/><el-input type="textarea" :rows="3" v-model="form.justification_exceeding_four_years"></el-input>
                        </div>
                    </div>
                    <div class="col-md=12">
                        <br/><label>IV.1.6. Information about electronic auction</label>                    
                        <br/>
                            <el-checkbox v-model="form.electronic_auction_used" :checked="form.electronic_auction_used">An electronic auction will be used (optional)</el-checkbox>
                    </div>
                    <div class="col-md-12" v-if="form.electronic_auction_used">
                        <label>Additional information about electronic auction (option)</label>
                        <el-input type="textarea" :rows="3" v-model="form.electronic_auction_used_description"></el-input>
                    </div>
                </div>

                <div class="col-md-4">
                    <h4>IV.1.8. Information about the Government Procurement Agreement (GPA)</h4>
                    <div :class="{ 'has-error-field': v.form.covered_by_gpa.$invalid }">
                        <el-radio-group v-model="form.covered_by_gpa">
                            <el-radio border :label="1" :value="1">Yes</el-radio>
                            <el-radio border :label="0" :value="0">No</el-radio>
                        </el-radio-group>
                    </div>
                    <div v-if="v.form.covered_by_gpa.$invalid" class="error-text" >GPA selection is required</div>
                </div>

                <div v-if="this.fat_procurement_type == this.FATNoticeTypes.F02.value" class="row">
                    <!-- <div class="col-md-12">
                        <h4>IV.2. Administrative information</h4>
                        <label>Search for your notice (optional)</label>
                        <el-input v-model="form.search_notice" :label="'Can include words from the notice title or the publication reference'"></el-input>
                    </div> -->
                    <!-- CHECK LINKED NOTICE LINK??? -->
                    <!-- <div class="col-md-12">
                        <br/><label>IV.2.1. Previous publication concerning this procedure</label>
                        <br/>
                        <el-radio-group v-model="form.operators">
                            <el-radio border :value="'TED'" label="0">Published on TED</el-radio>
                            <el-radio border :value="'FTS'" label="1">Published on Find a Tender</el-radio>
                        </el-radio-group>
                    </div> -->
                
                    <span class="col-md-12"><br/><label class="col-md-12">IV.2.2. Time limit for receipt of tenders or requests to participate</label></span>
                    <div class="col-md-6">
                        <label>Date</label>
                        <div :class="{ 'has-error-field': v.form.time_limit_expression_of_interest.$invalid }">
                            <el-date-picker v-model="form.time_limit_expression_of_interest" format="YYYY/MM/DD" value-format="YYYY-MM-DD"></el-date-picker>
                        </div>
                        <div v-if="v.form.time_limit_expression_of_interest.$invalid" class="error-text" >Date is required</div>
                    </div>
                    <div class="col-md-6">
                        <label>Local Time (optional)</label>
                        <el-select v-model="form.time_limit_expression_of_interest_time">
                            <el-option label="00:00" value="00:00"></el-option>
                            <el-option label="01:00" value="01:00"></el-option>
                            <el-option label="02:00" value="02:00"></el-option>
                            <el-option label="03:00" value="03:00"></el-option>
                            <el-option label="04:00" value="04:00"></el-option>
                            <el-option label="05:00" value="05:00"></el-option>
                            <el-option label="06:00" value="06:00"></el-option>
                            <el-option label="07:00" value="07:00"></el-option>
                            <el-option label="08:00" value="08:00"></el-option>
                            <el-option label="09:00" value="09:00"></el-option>
                            <el-option label="10:00" value="10:00"></el-option>
                            <el-option label="11:00" value="11:00"></el-option>
                        </el-select>
                    </div>

                    <div class="col-md-12">
                        <br/><label>IV.2.4. Languages in which tenders or requests to participate may be submitted</label>
                        <div :class="{ 'has-error-field': v.form.language.$invalid }">
                            <el-select v-model="form.language" placeholder="Select" style="width: 100%;">
                                <el-option v-for="(name, abbrev) in languages" :value="abbrev" :label="name"></el-option>
                            </el-select>
                        </div>
                        <div v-if="v.form.language.$invalid" class="error-text" >Language is required</div>
                    </div>
                    <div class="col-md-6">
                        <br/><label>IV.2.6. Minimum time frame during which the tenderer must maintain the tender (optional)</label>
                        <br/><el-radio-group v-model="form.minimum_time_frame">
                            <el-radio border :value="0" label="0">Tender must be valid until</el-radio>
                            <el-radio border :value="1" label="1">Duration in months (from the date stated for receipt of tender)</el-radio>
                        </el-radio-group>
                        <br/><a href="#" @click="form.minimum_time_frame = null">Clear selection</a>
                    </div>
                    
                    <div class="col-md-6" v-if="form.minimum_time_frame == 0">
                        <div class="col-md-6">
                            <br/><label>Date</label>
                            <div :class="{ 'has-error-field': v.form.minimum_time_frame_date.$invalid }">
                                <el-date-picker v-model="form.minimum_time_frame_date" format="YYYY/MM/DD" value-format="YYYY-MM-DD"></el-date-picker>
                            </div>
                            <div v-if="v.form.minimum_time_frame_date.$invalid" class="error-text" >Date is required</div>
                        </div>
                    </div>
                    <div class="col-md-6" v-if="form.minimum_time_frame == 1">
                        <br/><label>Duration in months</label>
                        <div :class="{ 'has-error-field': v.form.duration_in_months.$invalid }">
                            <el-input v-model="form.duration_in_months"></el-input>
                        </div>
                        <div v-if="v.form.duration_in_months.$invalid" class="error-text" >Duration in months is required</div>
                    </div>

                    <div class="col-md-12">
                        <br/><label>IV.2.7. Conditions for opening of tenders</label>
                        <br/><label style="color: #a9a9a9">The opening of tenders must be after the tender/participation request deadline</label>
                    </div>
                    <div class="col-md-6">
                        <label>Date</label>
                        <div :class="{ 'has-error-field': v.form.opening_of_tenders_date.$invalid }">
                            <el-date-picker v-model="form.opening_of_tenders_date" format="YYYY/MM/DD" value-format="YYYY-MM-DD"></el-date-picker>
                        </div>
                        <div v-if="v.form.opening_of_tenders_date.$invalid" class="error-text" >Date is required</div>
                    </div>
                    <div class="col-md-6">
                        <label>Local time (hh:mm)</label>
                        <div :class="{ 'has-error-field': v.form.opening_of_tenders_time.$invalid }">
                            <el-select v-model="form.opening_of_tenders_time">
                                <el-option label="00:00" value="00:00"></el-option>
                                <el-option label="01:00" value="01:00"></el-option>
                                <el-option label="02:00" value="02:00"></el-option>
                                <el-option label="03:00" value="03:00"></el-option>
                                <el-option label="04:00" value="04:00"></el-option>
                                <el-option label="05:00" value="05:00"></el-option>
                                <el-option label="06:00" value="06:00"></el-option>
                                <el-option label="07:00" value="07:00"></el-option>
                                <el-option label="08:00" value="08:00"></el-option>
                                <el-option label="09:00" value="09:00"></el-option>
                                <el-option label="10:00" value="10:00"></el-option>
                                <el-option label="11:00" value="11:00"></el-option>
                            </el-select>
                        </div>
                        <div v-if="v.form.opening_of_tenders_time.$invalid" class="error-text" >Time is required</div>

                    </div>
                    <div class="col-md-6">
                        <br/><label>Place (optional)</label>
                        <br/><el-input v-model="form.opening_of_tenders_place" type="textarea" :rows="3"></el-input>
                    </div>
                    <div class="col-md-6">
                        <br/><label>Information about authorised persons and opening procedure (optional)</label>
                        <br/><el-input v-model="form.person_procedure_info" type="textarea" :rows="3"></el-input> 
                    </div>
                </div>
            </div>
        </el-card>
    </div>
    <span class="col-md-3 mb-3" v-if="is_not_submitted"><el-button type="success" :disabled="v.form.$invalid" @click="saveForm()">Save and go to Next Section</el-button></span>
 </template>
 
 <script>
    import { mapState } from 'vuex'
     import {
     useVuelidate
     } from '@vuelidate/core'
     import {
     required, requiredIf
     } from '@vuelidate/validators'
import { FATNoticeTypes } from '../../../../constants';

     const SECTION_FOUR = 4
 
     export default {
         props: ['languages', 'previous_form', 'user', 'procurement_id', 'saved_form', "is_not_submitted", 'fat_procurement_type'],
         data() {
             return {
                form: {
                    covered_by_gpa: null,
                    search_notice: null,
                    time_limit_expression_of_interest: null,
                    time_limit_expression_of_interest_time: null,
                    schedule_date_award_procedures: null,
                    involves_dynamic_purchasing_system: null,
                    involves_framework_agreement: null,
                    electronic_auction_used: null,
                    operators: null,
                    number_of_participants: null,
                    minimum_time_frame: null,
                    minimum_time_frame_date: null,
                    duration_in_months: null,
                    opening_of_tenders_date: null,
                    opening_of_tenders_time: null,
                    opening_of_tenders_place: null,
                    person_procedure_info: null,
                    language: null,
                    accelerated_procedure: null,
                    justification_exceeding_four_years: null,
                    electronic_auction_used_description: null,
                }
             }
         },
        setup: () => ({
            v: useVuelidate()
        }),
        mounted()  {
            if(this.saved_form) this.form = this.saved_form
        },
        computed: {
            ...mapState(['savedUser', 'ProcurementProjectStatuses', 'FATNoticeTypes']),
        },
        validations() {
            let rules = {
                form: {
                    covered_by_gpa: {
                        required,
                        $autoDirty: true
                    },
                    time_limit_expression_of_interest: {
                        required: requiredIf(function () {return this.fat_procurement_type == FATNoticeTypes.F02.value}),
                        $autoDirty: true
                    },
                    // schedule_date_award_procedures: {
                    //     required,
                    //     $autoDirty: true
                    // },
                    operators: {
                        required: requiredIf(function () {return this.fat_procurement_type == FATNoticeTypes.F02.value && this.form.involves_framework_agreement}),
                        $autoDirty: true
                    },
                    number_of_participants: {
                        required: requiredIf(function () {return this.form.operators == 1}),
                        $autoDirty: true
                    },
                    minimum_time_frame_date: {
                        required: requiredIf(function () {return this.fat_procurement_type == FATNoticeTypes.F02.value && this.form.minimum_time_frame == 0}),
                        $autoDirty: true
                    },
                    opening_of_tenders_date: {
                        required: requiredIf(function () {return this.fat_procurement_type == FATNoticeTypes.F02.value}),
                        $autoDirty: true
                    },
                    opening_of_tenders_time: {
                        required: requiredIf(function () {return this.fat_procurement_type == FATNoticeTypes.F02.value}),
                        $autoDirty: true
                    },
                    duration_in_months: {
                        required: requiredIf(function () {return this.fat_procurement_type == FATNoticeTypes.F02.value && this.form.minimum_time_frame == 1}),
                        $autoDirty: true
                    },
                    accelerated_justification: {
                        required: requiredIf(function () {return this.fat_procurement_type == FATNoticeTypes.F02.value && this.form.accelerated_procedure}),
                        $autoDirty: true
                    },
                    language: {
                        required: requiredIf(function () {return this.fat_procurement_type == FATNoticeTypes.F02.value}),
                        $autoDirty: true
                    },
                }
            }

            return rules
        },
        methods: {
            saveForm() {
                if(!this.form.involves_framework_agreement) this.form.operators = null
                if(this.form.operators != 1) this.form.number_of_participants = null

                this.$emit('save', SECTION_FOUR, this.form)
            },
            getPrevious() {
                delete this.previous_form.id
                this.form = this.previous_form;
            },
        }
     }
 </script>
 