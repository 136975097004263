<template>
    <div class="row">
        <div class="col-md-12">
            <h5 class="ms-3">{{ is_new ? "Create" : "Manage" }} Email Template</h5>
        </div>
    </div>
    
    <div class="row">
        <div class="col-md-12">
            <el-card class="border-0" shadow="never">
                <div v-if="this.form_type == 'global' && $store.state.isAdmin()" class="row">
                    <div class="col-md-12 mb-4">
                        <el-text size="large">Code:</el-text>
                        <el-input v-model="common_form.code" />
                    </div>
                </div>
                <div v-if="this.form_type == 'global' && $store.state.isAdmin()" class="row">
                    <div class="col-md-12 mb-4">
                        <el-text size="large">Display Path:</el-text>
                        <el-input v-model="common_form.display_path" />
                    </div>
                </div>
                <div class="row">
                    <div class="col-md-12 mb-4">
                        <el-text size="large">Template Name:</el-text>
                        <el-input v-model="common_form.name" />
                    </div>
                </div>
                <!--<div class="row">
                    <div class="col-md-12 mb-4">
                        <el-text size="large">Email Subject:</el-text>
                        <el-input v-model="common_form.subject" />
                    </div>
                </div>-->
                <div class="row">
                    <div class="col-md-12 mb-4">
                        <div class="row">
                            <div class="col-md-9 mb-2">
                                <el-text size="large">Content:</el-text>
                            </div>
                            <div class="col-md-3 mb-2">
                                <el-switch
                                    size="large"
                                    v-model="show_variable_sample_values"
                                    :active-action-icon="View"
                                    :inactive-action-icon="Hide"
                                    active-text="Show Sample Values"
                                    class="float-end"
                                    @change="variableSwitchChange"
                                />
                            </div>
                        </div>
                        
                        <TemplateEditor
                            :variables="selected_global_template?.variables"
                            :custom_toolbar="custom_toolbar"
                            @change="editorChange"
                            :height="500"
                            :key="'template_editor_' + selected_global_template?.id + (selected_email_template ? selected_email_template?.id : 0)"
                            :selected_content="common_form.content"
                            :converted_template_content="converted_template_content"
                            
                        ></TemplateEditor>
                    </div>
                </div>
                <div class="row">
                    <div class="col-md-12 mb-4">
                        <el-button @click="save" type="success" ><i class="fa fa-save"></i> {{ is_new ? "Create" : "Update" }}</el-button>
                    </div>
                </div>
            </el-card>
        </div>
    </div>
</template>
  
  <script>
   function drag(ev) {
  ev.dataTransfer.setData("Text", ev.target.id);
}

  import TemplateEditor from '@/components/common/TemplateEditor.vue'
  import { EmailTemplate } from '@/api_services/email_templates'
  import { GlobalEmailTemplate } from '@/api_services/global_email_templates'
  import Swal from 'sweetalert2'
  import CustomToolbar from './CustomToolbar.vue'
  import { Hide, View } from '@element-plus/icons-vue'
  import { h, shallowRef } from 'vue';
import Quill from 'quill';

  export default {
    components: {
        TemplateEditor, CustomToolbar
    },
    props: {
        prop_latest_version:{
            type: Number,
            default: 1
        },
        prop_is_new:{
            type: Number,
            default: 1
        },
        prop_form_type: {
            type: String,
            default: "global"
        },
        prop_selected_global_template: {
            type: Object,
            default: null
        },
        prop_email_templates: {
            type: Object,
            default: null
        },
        prop_selected_email_template: {
            type: Object,
            default: null
        },
        prop_is_global: {
            type: Number,
            default: 0
        },
    },
    data() {
        return {
            converted_template_content: null,
            Hide: shallowRef(Hide),
            View: shallowRef(View),
            variable_unconverted_selected_template: null,
            show_variable_sample_values: false,
            is_global: 0,
            is_new: 1,
            custom_toolbar: 'CustomToolbar',
            latest_version: null,
            form_type: null,
            selected_global_template: [],
            email_templates: [],
            selected_email_template: [],
            common_form: {
                global_template_id: null,
                id: null,
                code: null,
                display_path: null,
                name: null,
                subject: null,
                content: ""
            }
        }
    },
    watch: {
        prop_latest_version: {
            handler(newValue) {
                this.latest_version = newValue
            },
            deep: true
        },
        prop_form_type: {
            handler(newValue) {
                this.form_type = newValue
                this.addDefaultCommonFormValue()
            },
            deep: true
        },
        prop_selected_global_template: {
            handler(newValue) {
                this.selected_global_template = newValue
                this.addDefaultCommonFormValue()
            },
            deep: true
        },
        prop_email_templates: {
            handler(newValue) {
                this.email_templates = newValue
            },
            deep: true
        },
        prop_selected_email_template: {
            handler(newValue) {
                this.selected_email_template = newValue
                this.addDefaultCommonFormValue()
            },
            deep: true
        },
        prop_is_new: {
            handler(newValue) {
                this.is_new = newValue
            },
            deep: true
        },
        prop_is_global: {
            handler(newValue) {
                this.is_global = newValue
            },
            deep: true
        },
    },
    computed: {
        
    },
    created: function () {
        this.selected_global_template = this.prop_selected_global_template
        this.email_templates = this.prop_email_templates
        this.selected_email_template = this.prop_selected_email_template
        this.form_type = this.prop_form_type
        this.latest_version = this.prop_latest_version
        this.is_new = this.prop_is_new
        this.is_global = this.prop_is_global

        this.addDefaultCommonFormValue()
    },
    methods: {
        variableSwitchChange(){
            if(this.show_variable_sample_values){
                var wrapper = document.createElement('div')
                wrapper.innerHTML = this.common_form.content

                let content = wrapper

                this.selected_global_template.variables.forEach(function(variable){
                    content.querySelectorAll('.embeded_variable[data-key="'+ variable.backend_name +'"]').forEach(item => {
                        item.outerHTML = variable.sample_value
                    })
                })

                this.converted_template_content = content.innerHTML
            }
            else{
                this.converted_template_content = null
            }
        },
        editorChange(value){
            this.common_form.content = value
        },
        save(){
            if(this.converted_template_content){
                Swal.fire({
                    title: 'Warning!',
                    text: "Please turn off the 'Show Sample Values' first before you can save the template. ",
                    icon: 'warning',
                })
                return false
            }

            Swal.fire({
                title: (this.is_new ? "Create":"Update") + " this template?",
                text: 'By clicking continue, you\'re '+ (this.is_new ? "creating":"updating") +' this template. Do you want to proceed?',
                icon: 'info',
                showCancelButton: true,
                confirmButtonColor: '#3085d6',
                cancelButtonColor: '#d33',
                confirmButtonText: 'Continue',
                cancelButtonText: 'Cancel',
            }).then((result) => {
                if (result.isConfirmed) {
                this.globalState.loading = true

                let save = null
                this.common_form.global_template_id = this.selected_global_template?.id
                if(this.is_new){
                    if(this.form_type == "new_version"){
                        save = EmailTemplate.storeEmailTemplate(this.common_form)
                    }
                }
                else{
                    if(this.form_type == "new_version"){
                        this.common_form.id = this.selected_email_template?.id
                        save = EmailTemplate.updateEmailTemplate(this.common_form.id, this.common_form)
                    }

                    if(this.form_type == "global"){
                        this.common_form.id = this.selected_global_template?.id
                        save = GlobalEmailTemplate.updateGlobalEmailTemplate(this.common_form.id, this.common_form)
                    }
                }

                save.then(result => {
                    this.$toast.success('Success')
                    this.$emit('save', this.selected_global_template)
                })
                .catch(error => {
                    console.log(error)
                })
                .finally(() => {
                    this.globalState.loading = false
                })
                
                }
            });
        },
        addDefaultCommonFormValue(){
            if(this.form_type == "global"){
                this.common_form.code = this.selected_global_template?.code
                this.common_form.display_path = this.selected_global_template?.display_path
                this.common_form.name = this.selected_global_template?.name
                this.common_form.subject = this.selected_global_template?.subject
                this.common_form.content = this.selected_global_template?.content

                if(this.is_new){
                    this.common_form = {
                        global_template_id: null,
                        id: null,
                        code: null,
                        display_path: null,
                        name: null,
                        subject: null,
                        content: "Create your template here..."
                    }
                }
            }
            if(this.form_type == "new_version"){
                this.common_form.name = (this.selected_email_template && !this.is_new ? this.selected_email_template?.name : this.selected_global_template?.name) + (this.is_new ? ": v" + this.latest_version : "")
                this.common_form.subject = (this.selected_email_template && !this.is_new ? this.selected_email_template?.subject : this.selected_global_template?.subject)
                this.common_form.content = (this.selected_email_template && !this.is_new ? this.selected_email_template?.content : this.selected_global_template?.content)

                if(this.is_new){
                    this.common_form.name = this.selected_global_template?.name + (this.is_new ? ": v" + this.latest_version : "")
                    this.common_form.subject = (this.selected_email_template && !this.is_global ? this.selected_email_template?.subject : this.selected_global_template?.subject)
                    this.common_form.content = (this.selected_email_template && !this.is_global ? this.selected_email_template?.content : this.selected_global_template?.content)
                }

                
            }
        }
    }
  }
  </script>
  