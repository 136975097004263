<template>
    <div class="flex gap-2 form-control">
    <el-tag
    class="me-2"
      v-for="tag in tag_data"
      :key="tag"
      closable
      :disable-transitions="false"
      @close="handleClose(tag)"
      size="large"
    >
      {{ tag }}
    </el-tag>
    <el-input
      v-if="inputVisible"
      ref="tagInput"
      v-model="inputValue"
      class="w-20"
      size="default"
      @keyup.enter="handleInputConfirm"
      @blur="handleInputConfirm"
    />
    <el-button v-else class="button-new-tag" size="default" @click="showInput">
      + {{ add_text }}
    </el-button>
  </div>
  </template>
  
  <script>

  export default {
    watch: {
      tag_data: {
        handler(newValue, oldValue) {
          if(newValue && newValue != "{}"){
            this.$emit('update', newValue.join(','))
          }
        },
        deep: true
      },
    },
    emits: ['update'],
    props: {
        data: {
            type: Array,
            required: true,
            default: []
        },
        add_text: {
            type: String,
            required: false,
            default: "Add"
        },
        input_type: {
            type: String,
            required: false,
            default: "string"
        },
    },
    data() {
          return {
            inputValue: null,
            tag_data: [],
            inputVisible: false
          }
      },
      created: function() {
        if(this.data && this.data != "{}"){
          this.tag_data = this.data
        }
      },
      methods: {
        validMail(email){
            return /^(([^<>()\[\]\.,;:\s@\"]+(\.[^<>()\[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()\.,;\s@\"]+\.{0,1})+([^<>()\.,;:\s@\"]{2,}|[\d\.]+))$/.test(email);
        },
        handleClose(tag){
            this.tag_data.splice(this.tag_data.indexOf(tag), 1)
        },
        showInput(){
            this.inputVisible = true
        },
        handleInputConfirm (){
            if (this.inputValue) {


                if(this.input_type == "email"){
                    if(!this.validMail(this.inputValue)){
                        this.$toast.warning("Provided input is not a valid email!")

                        this.inputVisible = false
                        this.inputValue = ''
                        return false
                    }
                }
                this.tag_data.push(this.inputValue)
            }
            this.inputVisible = false
            this.inputValue = ''
        }
      }
  }
  </script>
  
  <style lang="scss" scoped></style>
  