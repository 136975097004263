<style>
.el-aside{
  overflow: visible !important
}
.el-footer{
  height: auto !important;
  /*background-color: whitesmoke;*/
}
.thread_body:hover{
  background-color: whitesmoke !important;
}
</style>
<template>
  
    <div class="row thread_body pt-3 pb-2">
      <div class="col-md-8">
        <div class="row">
          <div class="col-md-12">
            <el-badge :offset="[13, 6]" :value="!data?.user_viewed_this_thread ? 'New' : ''" type="danger" class="item">
            <h5 class="mb-0 pb-1 fw-bold">
              <el-tag
                v-if="if_searched"
                class="me-2 "
                type="primary"
                effect="dark"
              >{{ data?.category_name }}</el-tag>
              <el-tag
                class="me-2 "
                type="success"
                effect="dark"
              >THREAD</el-tag>
              <a :href="'/forum/reply/' + data.id" @click="navigateToPage">{{ data.title }}</a><!--&nbsp;<i class="text-sm opacity-75" >({{ data.total_views }} Viewer{{ (data.total_views > 1 ? "s" : "") }})</i>--></h5></el-badge></div>
        </div>
        <div class="row">
          <div class="col-md-12"><p class="mb-0">
            <el-text class="mx-1" size="default">
            <i class="fa fa-user"></i>
            {{ data.user }} - 
            <el-tooltip
                class="box-item"
                effect="dark"
                :content="$filters.datetimeFormatFilter(data.created_at)"
                placement="right-end"
            >
            <timeago :autoUpdate="60" :datetime="data?.created_at"/>
            </el-tooltip>
            &nbsp;
            <i class="fa fa-eye" ></i> {{ data.total_views }} Viewer{{ (data.total_views > 1 ? "s" : "") }}
          </el-text>
        </p>
        <el-tag
                    v-if="data.tag"
                    effect="light"
                    class="me-1"
                    v-for="tag in data.tag"
                    :key="tag"
                    :disable-transitions="false"
                    size="large"
                    round
                    type="primary"
                    >
                    #{{ tag }}
                </el-tag>
      </div>
        </div>

      </div>
      

      <div class="col-md-4">

        <div class="row">
          <div class="col-md-5">
            <div class="row">
                    <div class="col-md-12">
                      <p class="float-start text-sm text-primary mb-1"><i class="	fa fa-thumbs-o-up"></i>&nbsp;{{ data.total_likes }} Like{{ (data.total_likes > 1 ? "s" : "") }}</p>
                    </div>
                  </div>
                  <div class="row">
                    <div class="col-md-12">
                      <p class="float-start text-sm text-primary mb-3"><i class="	far fa-comment"></i>&nbsp;{{ data.total_replies }} {{ (data.total_replies > 1 ? "Replies" : "Reply") }}</p>
                    </div>
                  </div>
          </div>
          <div class="col-md-7">
            <div class="row flex-column h-100">
              <div class="col flex-grow-0">
                <el-tooltip
                        class="box-item"
                        effect="dark"
                        content="Edit"
                        placement="top"
                    >
                  <el-button v-if="$store.state.isAdmin() || data.user_id == user?.id" @click="edit(data)" size="small" class="float-start mb-2" type="info"><i class="fa fa-edit"></i></el-button>
                </el-tooltip>
                  <el-tooltip
                        class="box-item"
                        effect="dark"
                        content="Delete"
                        placement="top"
                    >
                  <el-button v-if="$store.state.isAdmin() || data.user_id == user?.id" @click="deleteThread(data)" size="small" class="float-start mb-2" type="danger"><i class="fa fa-trash"></i></el-button>
                </el-tooltip>
                </div>
              <div class="col flex-grow-0">
                  <p  class="float-start text-sm">
                    
                  <el-tooltip
                        v-if="data?.recent_activity_date"
                        class="box-item"
                        effect="dark"
                        :content="'Recent Activity: ' + $filters.datetimeFormatFilter(data.recent_activity_date)"
                        placement="right-end"
                    >
                    <el-text class="mx-1" size="default"><i class="fa fa-clock"></i>&nbsp;<timeago :autoUpdate="60" :datetime="data?.recent_activity_date"/></el-text>
                  </el-tooltip>
                  </p>
                </div>
            </div>
          </div>
        </div>

      </div>
    </div>
    <el-divider class="mt-0 mb-0" />
</template>

<script>
  import Swal from 'sweetalert2'
  import { Forum } from '@/api_services/forum'

  export default {
    props: {
      data: {
        type: Object,
        required: true,
        default: []
      },
      if_searched: {
        type: Number,
        required: false,
        default: 0
      }
    },
    data() {
      return {

      } 
    },
    created: function () {

    },
    watch: {
      
    },
    computed: {
      user() {
        return this.$store.state.savedUser // get state
      }
    },
    methods: {
      deleteThread(data){
        
        Swal.fire({
          icon: 'warning',
          title: 'Remove this thread?',
          text:
            'Permanently remove this thread including its uploaded files?',
            showCancelButton: true,
            confirmButtonText: 'Proceed',
            cancelButtonText: 'Cancel',  
          }).then(result => {
                    if (result.isConfirmed) {
                      this.globalState.loading = true
                      let delete_form = {
                        id: data.id,
                        delete_target: 1
                      }

                      Forum.delete(delete_form)
                      .then(result => {
                        this.$toast.success("Successfully removed!")
                        this.$emit("delete_thread", data)
                      })
                      .catch(error => {
                        console.log(error)
                      })
                      .finally(() => {
                        this.globalState.loading = false
                      })
                    }
                    else{
                        return false
                    }
                })
      },
      edit(data){
        this.$emit("edit", data)
      },
      navigateToPage(data){
        this.$emit("navigateToPage", data)
      }
    }
  }
</script>
