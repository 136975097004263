<template>
    <el-card>
      <div class="card-header">
        <div class="category">{{ form && form.id ? 'Edit' : 'New' }}</div>
      </div>
      <div class="card-body">
        <form>
          <div class="form-group">
            <label :invalid-feedback="invalidFeedback('name')" :state="validation('name')">
              Name
            </label>
            <input type="text" class="form-control" v-model="form.name" :state="validation('name')" trim />
          </div>
  
          <div class="form-group">
            <label :invalid-feedback="invalidFeedback('description')" :state="validation('description')">
                Description
            </label><br>
            <input type="text" class="form-control" v-model="form.description" :state="validation('description')" trim />
          </div>
  
          <div class="form-group">
            <label :invalid-feedback="invalidFeedback('global_email_code')" :state="validation('global_email_code')">
                Global Email Template Code
            </label><br>
            <input type="text" class="form-control" v-model="form.global_email_code" :state="validation('global_email_code')" trim />
          </div>

          <hr>
          <el-button type="success" @click="onSubmit" :disabled="!validateAll">
            Save
          </el-button>
          <el-button @click="onClose()">
            Cancel
          </el-button>
        </form>
      </div>
    </el-card>
  </template>
  
  <script>
  import { GlobalEmailTemplate } from '@/api_services/global_email_templates'
  
  export default {
    props: ['email_template_data'],
    data() {
      return {
        validate: ['name', 'description', 'global_email_code'],
        form: {
          id: null,
          name: null,
          description: null,
          global_email_code: null,
          settings_code: null
        },
      }
    },
    watch: {
      setting: function () {
        this.loadEmailTemplateSettingsData()
      }
    },
    created: function () {
      this.loadEmailTemplateSettingsData()
    },
    methods: {
      handleChange() {
      },
      remove(index) {
        this.form.value.splice(index, 1);
      },
      loadEmailTemplateSettingsData() {
        if (this.email_template_data && this.email_template_data.id) this.form = Object.assign({}, this.email_template_data)
      },
      validation(field) {
        switch (field) {
          case 'name':
            if (!this.form.name) this.form.name = ''
            return this.form.name.length > 0
          case 'description':
            if (!this.form.description) this.form.description = ''
            return this.form.description.length > 0
          case 'global_email_code':
            if (!this.form.global_email_code) this.form.global_email_code = ''
            return this.form.global_email_code.length > 0
          default:
            return true
        }
      },
      invalidFeedback(field) {
        if (!this.validation(field) && field == 'name')
          return 'Name is required'
        
        if (!this.validation(field) && field == 'description')
          return 'Description is required'

        if (!this.validation(field) && field == 'global_email_code')
          return 'Global Email Code is required'

        return ''
      },
      onClose() {
        this.$emit('update')
      },
      onSubmit(evt) {
        evt.preventDefault()
        GlobalEmailTemplate.updateEmailTemplateSettings(this.form.id, this.form)
          .then(result => {
            this.$toast.success('Saved successfully')
            this.$emit('update', this.form)
          })
          .catch(error => { })
      }
    },
    computed: {
      validateAll() {
        var self = this
        var response = true
        this.validate.forEach(function (field) {
          if (!self.validation(field)) response = false
        })
        return response
      }
    }
  }
  </script>