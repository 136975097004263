
<template>
    <div class="">
      <div class="pb-0 card-header">
        <h5 class="font-weight-bolder mb-0">{{title}}</h5>
      </div>
      <div class="card-body">
      </br>
        <form @submit.prevent.prevent="" @reset.prevent="onCancel">

          <div v-if="show_category_selection" class="form-group">
            <label class="form-label">Category *</label>
            <el-select 
            value-key="name"
            :class="{ 'is-invalid': v$.selected_category_id.$errors.length, '': true }"
            v-model="selected_category_id" >
                <el-option
                  v-for="item in categories"
                  :key="item.id"
                  :label="item.name"
                  :value="item.id"
                  />
            </el-select>
  
            <div class="input-errors" v-for="error of v$.selected_category_id.$errors" :key="error.$uid">
              <div class="error-text">Category {{ error.$message }}</div>
            </div>
          </div>
  
          <div class="form-group">
            <label class="form-label">Title *</label>
            <input type="text" v-model.trim="form.title"
            :class="{ 'is-invalid': v$.form.title.$errors.length, 'form-control form-control-sm': true }">
  
            <div class="input-errors" v-for="error of v$.form.title.$errors" :key="error.$uid">
              <div class="error-text">Tile {{ error.$message }}</div>
            </div>
          </div>

          <div class="form-group">
            <label class="form-label">Content *</label>

            <quill-editor @ready="onEditorReady($event)" id="content_quill" contentType="html"
                    v-model:content="form.content" :options="editorOptions" @change="contentChangeEvnt($event)"></quill-editor>
  
            <div class="input-errors" v-for="error of v$.form.content.$errors" :key="error.$uid">
              <div class="error-text">Content {{ error.$message }}</div>
            </div>
          </div>

          <div class="form-group">
            <label class="form-label">Hashtags <i>(For search relevance)</i></label>
            <ElTagInput v-model.trim="form.tag"
            :data="form.tag"
            :add_text="'Add'"
            @update="updateTags"></ElTagInput>

          </div>

          <div v-if="!show_category_selection" class="form-group">
            <label class="form-label">Relate this Thread to (Optional)</label>
            
            <ModelInformation
              :key="'model_info_' + model_info_counter"
              :if_single_selection="true"
              :filter_placeholder="'Search...'"
              :transfer_data="[]"
              :right_button_text="null"
              :model_data="prop_selected_related_info"
              :right_middle_button_text="'Select'"
              @update_value="relatedThreadInfoSelection"
          ></ModelInformation>
          </div>

          <div class="form-group">
            <label class="form-label">Attatchments (Optional)</label>
            <ElUpload 
            :quick_remove="isNew"
            v-model="form.attachments"
            :data="form.attachments"
            @removeFile="removeAttachment"
            @uploadChange="updateAttachmentsValue"></ElUpload>

          </div>

          <div class="form-group">
            <label class="form-label">Access Ristrictions (Optional)</label>
            <br>
            <div class="row mb-0">
              <div class="col-md-12 mb-2 border ms-3 me-3 pt-2  ">
                <p class="mb-0 text-sm">This Thread can only be accessed by? (Optional, default: All Roles)</p>
                <el-checkbox-group
                  v-model="form.specific_roles"
                  @change="checkChange"
                >
                  <el-checkbox v-if="$store.state.isAdmin() || $store.state.isUserABuyer()" :label="'BUYER'" :value="'BUYER'" />
                  <el-checkbox v-if="$store.state.isAdmin() || $store.state.isUserASupplier()" :label="'SUPPLIER'" :value="'SUPPLIER'" />
                </el-checkbox-group>
              </div>
            </div>
            <div class="row mb-0">
              <div class="col-md-12 mb-2 border ms-3 me-3">
                <el-checkbox class="mb-0" v-model="form.only_user_company" :label="'Users at the company: ' " size="large" />
                <el-tag effect="dark" class="ms-1 text-sm mb-1"  type="primary">{{ user?.company?.name }}</el-tag>
              </div>
            </div>
            <div v-if="user?.companies" class="row">
              <div class="col-md-12 border ms-3 me-3 pb-2">
                <el-checkbox v-model="form.only_company_user" label="Users at the companies below:" size="large" />
                <br>
                <template class="ms-4" v-if="user?.companies">
                  <el-tag effect="dark" class="ms-1 text-sm mb-1"  type="primary"
                    v-for="company in user?.companies"
                  >{{ company.name }}</el-tag>
                </template>
              </div>
            </div>
          </div>
  
          <div class="pt-4 text-end form-buttons">
            <button type="reset" class="btn btn-secondary" :disabled="isSaving">Cancel</button>
            <button type="button" @click="onSubmit" class="btn btn-success" :disabled="isSaving">{{isSaving ? 'Saving...' : 'Save'}}</button>
          </div>
        </form>
      </div>
    </div>
    
  </template>
  
  <script>
    import { Forum } from '@/api_services/forum'
    import { useVuelidate } from '@vuelidate/core'
    import { required, email, requiredIf } from '@vuelidate/validators'
    import { quillEditor } from 'vue3-quill'
    import 'quill/dist/quill.core.css';
    import 'quill/dist/quill.snow.css'; 
    import 'quill/dist/quill.bubble.css';
    import ElTagInput from '@/components/common/ElTagInput'
    import ElUpload from '@/components/common/ElUpload'
    import ModelInformation from '@/components/common/model_information/ModelInformationSelectionComponent'

    export default {
      emits: ["save", "cancel"],
      setup () {
        return { v$: useVuelidate() }
      },
      props: {
        selected: {
          type: Object,
          required: true,
          default: []
        },
        category_id: {
          type: Number,
          required: false
        },
        show_category_selection: {
          type: Boolean,
          required: false,
          default: false
        },
        prop_selected_related_info: {
          type: Object,
          required: false,
          default: []
        },
      },
      components: {
        quillEditor, ElTagInput, ElUpload, ModelInformation
      },
      data() {
        return {
          model_info_counter: 1,
          if_single_related_info: 0,
          categories: [],
          selected_category_id: null,
          selected_related_info: {},
          isSaving: false,
          form:{
            title: null,
            content: null,
            tag: "",
            attachments: null,
            only_company_user: 0,
            only_user_company: 0,
            specific_roles: []
          },
          editorOptions: {
            placeholder: '....',
            theme: 'snow',
            modules: {
              toolbar: [
              ['italic', 'underline', 'strike'],        // toggled buttons
              ['blockquote', 'code-block'],
              ['link', 'formula'],

              [{ 'header': 1 }, { 'header': 2 }],               // custom button values
              [{ 'list': 'ordered'}, { 'list': 'bullet' }, { 'list': 'check' }],
              [{ 'script': 'sub'}, { 'script': 'super' }],      // superscript/subscript
              [{ 'indent': '-1'}, { 'indent': '+1' }],          // outdent/indent
              [{ 'direction': 'rtl' }],                         // text direction

              [{ 'size': ['small', false, 'large', 'huge'] }],  // custom dropdown
              [{ 'header': [1, 2, 3, 4, 5, 6, false] }],

              [{ 'color': [] }, { 'background': [] }],          // dropdown with defaults from theme
              [{ 'font': [] }],
              [{ 'align': [] }],

              ['clean'] 
              ]
            },
          }
        }
      },
      validations() {
        return {
          selected_category_id: {
            required: requiredIf(function () {
              return (!this.show_category_selection) ? false : true
            }),
            $autoDirty: true
          },
          form: {
            title: {
              required,
              $autoDirty: true
            },
            content: {
              required,
              $autoDirty: true
            }
          },
        }
      },
      created: function () {
        if(this.selected){
          this.form = JSON.parse(JSON.stringify(this.selected))
          if(!this.form.specific_roles || this.form.specific_roles == "" || this.form.specific_roles?.length == 0){
            this.form.specific_roles = []
          }
          else{
            this.form.specific_roles = JSON.parse(this.form.specific_roles)
          }
        }
        if(this.category_id){
          this.selected_category_id = this.category_id
        }
        if(this.show_category_selection){
          this.loadCategories()
        }
        if(this.prop_selected_related_info.length > 0){
          this.selected_related_info = this.prop_selected_related_info
        }
        else{
          this.model_info_counter++
        }
      },
      watch: {
        selected(selected) {
          if(selected){
            this.form = JSON.parse(JSON.stringify(selected))
            if(!this.form.specific_roles || this.form.specific_roles == "" || this.form.specific_roles?.length == 0){
              this.form.specific_roles = []
            }
            else{
              this.form.specific_roles = JSON.parse(this.form.specific_roles)
            }
          }
        },
        prop_selected_related_info: {
          handler(newValue) {
            this.selected_related_info = newValue
            this.model_info_counter++
          },
          deep: true
        }
      },
      computed: {
        title() {
          return this.isNew ? 'Add New Forum Thread' : `Manage Forum Thread`
        },
        isNew() {
          return !this.selected?.id
        },
        user() {
          return this.$store.state.savedUser // get state
        }
      },
      methods: {
        checkChange(value){
          
        },
        loadCategories(){
            this.globalState.loading = true
                Forum.loadForumData({
                    params: {
                        page: null,
                        search_value: "",
                        type: "category",
                        target_id: null,
                        only_main: 0,
                        get_sub: 1,
                        parent_id: null,
                        order: "name,asc",
                        all_data: 1
                    }
                })
                .then(result => {
                    if (result.data.forum_data){
                        this.categories = result.data.forum_data
                    }
                })
                .catch(error => {
                    console.log(error)
                })
                .finally(() => {
                    this.globalState.loading = false
                })
            
        },
        relatedThreadInfoSelection({data, if_single_related_info}){
          this.selected_related_info = data
          this.if_single_related_info = if_single_related_info
        },
        updateAttachmentsValue(files){
          this.form.attachments = files
        },
        removeAttachment(file){

          if(file.path && !this.isNew){
            this.globalState.loading = true
            let formData = new FormData()
            formData.append('path', file.path)
            formData.append('type', 0)

            Forum.removeFile(this.form.id, formData)
            .then(resp => {
              if(resp.data.response == 1){
                this.$toast.success("Successfully removed!")
              }
              else{
                this.$toast.warning("Failed to remove!")
              }
            })
            .catch(error => {
              console.error('error:', error)
            })
            .finally(() => {
              this.globalState.loading = false
            })
          }
        },
        updateTags(value){
            this.form.tag = value
        },
        contentChangeEvnt({ quill, html, text }){
          this.form.content = html
        },
        onEditorReady (e) {
          var this_id = e.container.id
          if(this_id == "content_quill") e.container.querySelector('.ql-blank').innerHTML = this.form.content ? this.form.content : ""
        },
        async onSubmit(evt) {
  
          evt.preventDefault()
  
          const result = await this.v$.$validate()
          if (!result) return
          
          if (!Array.isArray(this.form.tag)) {
            this.form.tag = (this.form.tag && this.form.tag != "" ? this.form.tag.split(',') : this.form.tag)
          }
          let formData = new FormData()
          formData.append('category_id', this.selected_category_id)
          formData.append('title', this.form.title)
          formData.append('content', this.form.content)
          formData.append('if_single_related_info', this.if_single_related_info)
          if(this.form.tag){
            formData.append('tag', JSON.stringify(this.form.tag))
          }
          if(this.form.attachments && this.form.attachments.length > 0){
            if(typeof this.form.attachments != "object"){
              this.form.attachments = JSON.parse(this.form.attachments)
            }
            this.form.attachments.forEach(function(file) {
              if(file.raw){
                formData.append('files[]', file.raw)
              }
            }) 
          }

          this.form.only_company_user = (this.form.only_company_user ? 1 : 0)
          this.form.only_user_company = (this.form.only_user_company ? 1 : 0)

          formData.append('only_company_user', this.form.only_company_user)
          formData.append('only_user_company', this.form.only_user_company)

          if (!Array.isArray(this.form.specific_roles)) {
            this.form.specific_roles = (this.form.specific_roles && this.form.specific_roles != "" ? this.form.specific_roles.split(',') : this.form.specific_roles)
          }
          if(this.form.specific_roles){
            formData.append('specific_roles', JSON.stringify(this.form.specific_roles))
          }

          if(this.selected_related_info && this.selected_related_info.length > 0){
            var selected_data = []
            this.selected_related_info.forEach(function(data) {
              var object = {}
              object["target_id"] = (data?.key?.id > 0 ? data?.key?.id : data?.target_id)
              object["model_id"] = data?.model_id

              selected_data.push(object)
            }) 
            formData.append('related_information', JSON.stringify(selected_data))
          }

          if(!this.isNew){
            //formData.append("_method", "PUT")
          }

          this.isSaving = true
          const promise = this.isNew ? Forum.storeThread(formData) : Forum.updateThread(this.form.id, formData)
          promise
            .then(resp => {
              
              this.$toast.success(resp.data.message || 'Forum Thread has been saved successfully')
              this.$emit('save', this.form)
            })
            .catch(error => {
              console.error('error:', error)
            })
            .finally(() => {
              this.isSaving = false
            })
        },
        onCancel() {
          this.$emit('cancel')
        },
      }
    }
  </script>
  