import api from './api'

const API = '/api/invoices'

export const Invoice = {
  get: function(params) {
    return api.get(API, params)
  },
  getFollowUpEmailContent: function(id, params) {
    return api.get(API + "/get-follow-up-email-content/" + id, params)
  },
  getPurchaseOrderList: function(params) {
    return api.get(API+'/search_purchase_orders', params)
  },
  create: function(params) {
    return api.create(API, params)
  },
  sendFollowUpEmail: (params) => {
    return api.create(API + "/send-follow-up-email", params)
  },
  update: function(params) {
    return api.update(API+'/'+params.id, params)
  },
  show: function(id) {
    return api.get(API+'/'+id)
  },
  delete: function(id) {
    return api.delete(`${API}/${id}`)
  },
  download: function(id) {
    return api.get(API+'/'+id+'/download', {'base64':true})
  },
  getTotalAmounts: function(params) {
    return api.get(`${API}/total_amounts`, params)
  },
  emailInvoice: function(params) {
    return api.create(`${API}/${params.id}/email_invoice`, params)
  }
}
