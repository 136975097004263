
<style>
.ql-snow{
.ql-picker{
    &.ql-size{
        .ql-picker-label,
        .ql-picker-item{
            &::before{
                content: attr(data-value) !important;
            }
        }
    }
}
}

#globalContainer {
    display: flex;
}

#editor-container {
  flex: 1;
  display: flex;
  flex-direction: column;
}

#sidebar {
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.embeded_variable {
  padding: 2px;
  padding-left: 7px;
  padding-right: 7px;
  border-radius: 3px;
  background-color: #67C23A;
  color: white;
  font-weight: normal !important;
  cursor: pointer;
}

.embeded_variable_item {
  cursor: pointer;
}
</style>
<template>
    <div v-if="content">
        <div id="globalContainer">
            <div @dragover="onOverDrop" id="editor-container">
                <div  id="editor"></div>
            </div>
    <div v-if="variables" id="sidebar h-100">
        <el-card class="ms-2 h-100 " body-class="pt-2" shadow="never">
            <h6>Template Variables 
                <el-tooltip content="Drag the variable to the editor or click it!" placement="top">
                    <i class="fa fa-info-circle"></i>
                </el-tooltip></h6>
            <el-divider class="m-0"></el-divider>
            <ul class="mt-2 list-group">
                <template v-for="variable in variables">
                    <li v-if="variable.if_display" class="list-group-item">
                        <span :data-key="variable.backend_name" @dragstart="onDrag" draggable="true" class="text-sm embeded_variable_item">{{ variable.display_label }}</span>
                    </li>
                </template>
            </ul>
        </el-card>
    
    </div>
</div>
    </div>
</template>
  
  <script>
    import { quillEditor } from 'vue3-quill'
    import 'quill/dist/quill.core.css';
    import 'quill/dist/quill.snow.css';
    import 'quill/dist/quill.bubble.css';

    var quill
    
  export default {
    mounted(){

        const Embed = Quill.import('blots/embed')

        Quill.register(class extends Embed {
        static create (data) {
            let node = super.create()
            node.setAttribute('data-key', data.key)
            node.setAttribute('dragabble', true)
            node.setAttribute('contenteditable', false)

            node.innerHTML = `${data.value}`
            return node
        }
        
        static value (node) {
            return node.dataset.key
        }
        
        static blotName = 'embeded_variable'
        static className = 'embeded_variable'
        static tagName = 'span'
        })

        const fontSizeArr = ['8px','9px','10px','12px','14px','16px','20px','24px','32px','42px','54px','68px','84px','98px'];

        var Size = Quill.import('attributors/style/size');
        Size.whitelist = fontSizeArr;
        Quill.register(Size, true);

        quill = new Quill('#editor', {
            placeholder: 'Compose an epic...',
            theme: 'snow',  // or 'bubble'
            modules: {
                toolbar: [
                    ['bold', 'italic', 'underline', 'strike'],        // toggled buttons
                    ['blockquote', 'code-block'],
                    ['link', 'formula'],

                    [{ 'header': 1 }, { 'header': 2 }],               // custom button values
                    [{ 'list': 'ordered'}, { 'list': 'bullet' }, { 'list': 'check' }],
                    [{ 'script': 'sub'}, { 'script': 'super' }],      // superscript/subscript
                    [{ 'indent': '-1'}, { 'indent': '+1' }],          // outdent/indent
                    [{ 'direction': 'rtl' }],                         // text direction

                    [{ 'size': fontSizeArr }],  // custom dropdown
                    [{ 'header': [1, 2, 3, 4, 5, 6, false] }],

                    [{ 'color': [] }, { 'background': [] }],          // dropdown with defaults from theme
                    [{ 'font': [] }],
                    [{ 'align': [] }],

                    ['clean']  
                ]
            }
        })

        quill.root.innerHTML = this.content
        this.original_content = this.content

        document.getElementById('editor-container').ondrop = (ev) => {
            ev.preventDefault();
            var range = quill.getSelection()
            var index = (range?.index ? range.index : 0)
            quill.insertEmbed(index, 'embeded_variable', {key: this.dragged_variable?.key, value: this.dragged_variable?.value})
        }

        var variable_items = document.getElementsByClassName('embeded_variable_item');
        for(var x = 0; x < variable_items.length; x++) {

            variable_items[x].addEventListener("click",function(ev){
                this.dragged_variable = { key: ev.target.getAttribute("data-key"), value: ev.target.innerHTML}
                var range = quill.getSelection()
                var index = (range?.index ? range.index : 0)
                quill.insertEmbed(index, 'embeded_variable', {key: this.dragged_variable?.key, value: this.dragged_variable?.value})

            },false);
        }

        quill.on('editor-change', (eventName, ...args) => {
            var content = quill.root.innerHTML
            if(!this.converted_template_content){
                this.$emit("change", content)
            }
        });

    },
    components: {
        quillEditor 
      },
    props: {
        converted_template_content:{
            required: false,
        },
        custom_toolbar: {
            required: false,
        },
        selected_content: {
            type: String,
            required: true,
            default: ""
        },
        height: {
            type: Number,
            required: false,
            default: 300
        },
        variables:{
            type: Object,
            required: false,
            default: null
        }
    },
    data() {
        return {
            original_content: null,
            dragged_variable: null,
            quill: null,
            content: null,
        }
    },
    watch: {
        selected_content: {
            handler(newValue, oldValue) {
                if(newValue && newValue != ""){
                    if(oldValue != newValue){
                        this.content = newValue
                        this.original_content = this.content
                    }
                }
            },
            deep: true
        },
        converted_template_content: {
            handler(newValue, oldValue) {
                if(newValue){
                    quill.root.innerHTML = newValue
                    quill.enable(false)
                }
                else{
                    quill.root.innerHTML = this.original_content
                    quill.enable()
                }
            },
            deep: true
        },
    },
    computed: {
        
    },
    created: function () {
        if(this.selected_content && this.selected_content != ""){
            this.content = this.selected_content
        }
    },
    methods: {
        onOverDrop(ev) {
            ev.preventDefault();
        },
        onDrag(ev){
            this.dragged_variable = { key: ev.target.getAttribute("data-key"), value: ev.target.innerHTML}
        },
    }
  }
  </script>
  