<template>
    <div>
      <div class="row">
        <div class="col-md-12">
          <div class="card my-3">
            
            <div class="card-body px-0 pb-2">
              <div class="table-responsive">
                <table class="table table-fluid align-items-center mb-0">
                  <thead>
                    <tr>
                      <th class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7">Name</th>
                      <th class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7">Role</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr v-if="!likes.length">
                      <td v-if="isLoading" colspan="100%" class="text-sm">
                        <i class="fas fa-cog fa-spin me-2"></i>Loading...
                      </td>
                      <td v-else colspan="100%">No data</td>
                    </tr>
                    <tr v-else class="text-sm" v-for="like in likes" :key="like.id">
                      <td>{{ like.user }}</td>
                      <td>{{ like.user_role }}</td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
            <div class="card-footer pt-0">
              <pagination :meta="like_meta" v-on:update="viewLikes()" />
            </div>
          </div>
        </div>
        
      </div>
      
    </div>
  </template>
  
  <script>
  
  import { Forum } from '@/api_services/forum'

  export default {
    props: {
        like_param: {
          type: Object,
          required: true,
          default: {
            id: null,
            target_type: null
          }
        },
      },
    watch: {
      like_param: {
        handler(value) {
            if(value){
                this.target_id = value.id
                this.target_type = value.target_type
                this.viewLikes()
            }
        },
        deep: true
      }
    },
    components: {  },
    data() {
      return {
        isLoading: true,
        like_meta: {
          page: 1,
          total: 0,
          pages: 1
        },
        target_id: null,
        target_type: null,
        likes: [],
      }
    },
    created: function () {
        if(this.like_param){
            this.target_id = this.like_param.id
            this.target_type = this.like_param.target_type
            this.viewLikes()
        }
    },
    methods: {
        viewLikes(){
        this.globalState.loading = true
        this.isLoading = true
        Forum.viewLikeList({
                    params: {
                        page: this.like_meta.page,
                        parent_type: this.target_type,
                        parent_id: this.target_id,
                        order: "created_at,desc",
                        all_data: 0
                    }
                })
                .then(result => {
                    if (result.data.likes){
                        this.likes = result.data.likes
                        this.like_meta = result.data.meta
                    }
                })
                .catch(error => {
                    console.log(error)
                })
                .finally(() => {
                    this.globalState.loading = false
                    this.isLoading = false
                })
      },
    },
    computed: {
        
    }
  }
  </script>