  <style>
  .el-aside{
    overflow: visible !important
  }
  .el-footer{
    height: auto !important;
  }
  .body-category{
    background-color: whitesmoke !important;
    border-left: 5px solid silver;
  }
  </style>
  <template>
    
      <div class="row">
        <div class="col-md-8">
          <div class="row">
            <div class="col-md-12"><h5 class="mb-0 pb-1" >
              <el-tag
                class="me-2 "
                type="primary"
                effect="dark"
              >Category</el-tag>
              <a :href="'/forum/thread/' + data.id" @click="navigateToPage">{{ data.name }}</a>&nbsp;<i class="text-sm opacity-75" >({{ data.total_views }} Viewer{{ (data.total_views > 1 ? "s" : "") }})</i></h5></div>
          </div>
          <div class="row mb-4">
            <div class="col-md-12">{{ data.description }}</div>
          </div>
        </div>
        <div class="col-md-1"></div>
        <div class="col-md-3">
          
          <div class="row flex-column h-100">
                <div class="col flex-grow-0">
                  <el-button size="small" class="float-start mb-2" type="success" @click="viewRules(data)"><i class="fa fa-eye"></i>&nbsp;View Rules</el-button>
                  <el-button v-if="$store.state.isAdmin()" size="small" class="float-start mb-2" type="warning" @click="edit(data)"><i class="fa fa-edit"></i>&nbsp;Edit</el-button>
                  <el-button v-if="$store.state.isAdmin()" size="small" class="float-start mb-2" type="danger" @click="deleteCategory(data)"><i class="fa fa-trash"></i>&nbsp;Delete</el-button>
                </div>
                <div class="col flex-fill">
                  <p class="float-start text-primary"><i class="fa fa-list-ul"></i>&nbsp;{{ data.total_threads }} Thread{{ (data.total_threads > 1 ? "s" : "") }}</p>
                </div>
                <div class="col flex-grow-0">
                  <p  class="float-start text-sm">
                    Recent Activity: 
                  <el-tooltip
                        v-if="data?.recent_activity_date"
                        class="box-item"
                        effect="dark"
                        :content="$filters.datetimeFormatFilter(data.recent_activity_date)"
                        placement="right-end"
                    >
                    <el-text class="mx-1" size="default"><timeago :autoUpdate="60" :datetime="data?.recent_activity_date"/></el-text>
                  </el-tooltip>
                  </p>
                </div>
          </div>

        </div>
      </div>
      <el-divider class="mt-1" />
  </template>
  
  <script>
    import Swal from 'sweetalert2'
    import { Forum } from '@/api_services/forum'

    export default {
      props: {
        data: {
          type: Object,
          required: true,
          default: []
        },
      },
      data() {
        return {

        } 
      },
      created: function () {
        
      },
      watch: {
        
      },
      computed: {
        
      },
      methods: {
        deleteCategory(data){
          Swal.fire({
          icon: 'warning',
          title: 'Remove this category?',
          text:
            'Permanently remove this category including its threads, replies and files?',
            showCancelButton: true,
            confirmButtonText: 'Proceed',
            cancelButtonText: 'Cancel',  
          }).then(result => {
                    if (result.isConfirmed) {
                      this.globalState.loading = true
                      let delete_form = {
                        id: data.id,
                        delete_target: 2
                      }

                      Forum.delete(delete_form)
                      .then(result => {
                        this.$toast.success("Successfully removed!")
                        this.$emit("delete_category", data)
                      })
                      .catch(error => {
                        console.log(error)
                      })
                      .finally(() => {
                        this.globalState.loading = false
                      })
                    }
                    else{
                        return false
                    }
                })
        },
        edit(data){
          this.$emit("edit", data)
        },
        navigateToPage(data){
          this.$emit("navigateToPage", data)
        },
        viewRules(data){
          this.$emit("viewRules", data.rules)
        }
      }
    }
  </script>
  