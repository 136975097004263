<template>
    <div class="border p-2">
        <el-select
            value-key="display_label"
            class="mb-2"
            v-model="selected_model"
            filterable
            placeholder="Select Information Type"
        >
            <el-option
            v-for="item in models"
            :key="item.id"
            :label="item.display_label"
            :value="item"
            />
        </el-select>

        <el-select
            @change="singleSelectChange"
            v-if="if_single_selection"
            value-key="label"
            class="mb-2"
            v-model="transfer"
            filterable
            placeholder="......"
        >
            <el-option
            v-for="item in data"
            :key="item.id"
            :label="item.label"
            :value="item"
            />
        </el-select>
        
      <a-transfer
        v-else
        v-model:target-keys="transfer"
        :data-source="data"
        :disabled="disabled"
        :show-search="showSearch"
        :filter-option="filterOption"
        @change="onChange"
        :operations="[right_middle_button_text, left_middle_button_text]"
        :titles="[left_title, right_title]"
      >
        <template
          #children="{
            direction,
            filteredItems,
            selectedKeys,
            disabled: listDisabled,
            onItemSelectAll,
            onItemSelect,
          }"
        >
          <a-table
            :pagination="false"
            :scroll="{ y: 250 }"
            :row-selection="
              getRowSelection({
                disabled: listDisabled,
                selectedKeys,
                onItemSelectAll,
                onItemSelect,
              })
            "
            :row-class-name="(_record, index) => (_record.hide ? 'disabled_row' : null)"
            :columns="direction === 'left' ? leftColumns : rightColumns"
            :data-source="filteredItems"
            size="small"
            :style="{ pointerEvents: listDisabled ? 'none' : null }"
            :custom-row="
              ({ key, disabled: itemDisabled }) => ({
                onClick: () => {
                  if (itemDisabled || listDisabled) return;
                  onItemSelect(key, !selectedKeys.includes(key));
                },
              })
            "
          />
        </template>
      </a-transfer>
    </div>
  </template>

<script>

import { filterOption } from 'ant-design-vue/es/vc-mentions/src/util';
import { ModelInformation } from '@/api_services/model_information'

    export default {
        created: function () {
            this.loadModelInfo()
        },
        props: {
            transfer_data: {
                required: true,
            },
            filter_placeholder: {
                required: true,
            },
            model_data: {
                type: Object,
                required: false,
                default: null
            },
            left_middle_button_text: {
                required: false,
                default: "Remove"
            },
            right_middle_button_text: { 
                required: false,
                default: "Select"
            },
            if_parent_only:{
                required: false,
                default: 1
            },
            if_single_selection:{
                required: false,
                default: false
            }
        },
        watch: {
            selected_model: {
                handler(newValue) {
                    this.setDataDisable(newValue.id)
                    if(!this.selected_model_array.includes(newValue.id)){
                        this.selected_model_array.push(newValue.id)
                        this.loadModelTargetData(newValue)   
                    }
                    this.left_title = ": List of " + newValue.display_label + "s"
                },
                deep: true
            },
            transfer_data: {
                handler(newValue) {
                    let self = this
                    this.data = []
                    newValue.forEach(function(row){
                        self.data.push({
                            key: row.id,
                            label: row?.name,
                            model: row?.model
                        })
                    })
                },
                deep: true
            },
            model_data: {
                handler(newValue) {
                    //this.transfer = newValue
                },
                deep: true
            },
        },
        data() {
            const generateData = _ => {
                const data = [];
                this.transfer_data.forEach(function(row){
                    data.push({
                        key: row.id,
                        label: row?.main_display_col_name,
                        model: row?.model
                    });
                });

                return data;
            }
            const leftTableColumns = [
                {
                    dataIndex: 'model',
                    title: '',
                },
                {
                    dataIndex: 'label',
                    title: 'Name',
                }
            ];
            const rightTableColumns = [
                {
                    dataIndex: 'model',
                    title: '',
                },
                {
                    dataIndex: 'label',
                    title: 'name',
                }
            ];
            return {
                left_title: ": List",
                right_title: ": Selected",
                selected_model: null,
                models: [],
                selected_data: [],
                data: generateData(),
                transfer: [],

                disabled: false,
                showSearch: true,
                leftColumns: leftTableColumns,
                rightColumns: rightTableColumns,
                selected_model_array: []
            };
        },
        methods: {
            singleSelectChange(){
                this.selected_data = [this.transfer]
                this.$emit("update_value", {data: this.selected_data, if_single_related_info: 1})
            },
            setDataDisable(model_id){
                let self = this
                this.data.forEach(function(row){
                   if(row.model_id != model_id){
                    const check_if_selected = obj => obj.id === row.key.id
                    if(!self.selected_data.some(check_if_selected)){
                        row.hide = true
                    }
                   }
                   else{
                    row.hide = false
                   }
                })
            },
            loadModelTargetData(model){
                this.globalState.loading = true
                ModelInformation.getModelTargetData({
                    params: {
                        model_id: model.id,
                        target_id: 0
                    }
                })
                .then(result => {
                    if(result.data.data){
                        
                        let self = this
                        let loaded_data = result.data.data
                        loaded_data.forEach(function(row){
                            self.data.push({
                                model_id: row?.model_id,
                                key: row,
                                label: row?.main_display_col_name,
                                model: row?.model,
                                disabled: false,
                                hide: false
                            })

                            const check_if_in_model_data = obj => obj?.target_id === row?.id && row?.model_id === obj?.model_id
                            
                            if(self.model_data.some(check_if_in_model_data)){
                                
                                self.transfer = {
                                    model_id: row?.model_id,
                                    key: row,
                                    label: row?.main_display_col_name,
                                    model: row?.model,
                                    disabled: false,
                                    hide: false
                                }
                            }
                        })
                    }
                })
                .catch(error => {
                    //this.$toast.error('An error occured')
                    console.error('error:', error)
                })
                .finally(() => {
                    this.globalState.loading = false
                })
            },
            loadModelInfo(){
                this.globalState.loading = true
                ModelInformation.get({params: {if_parent_only: this.if_parent_only}})
                .then(result => {
                    this.models = result.data.models
                    this.selected_model = this.models[0]
                })
                .catch(error => {
                    this.$toast.error('An error occured')
                    console.error('error:', error.response.data)
                })
                .finally(() => {
                    this.globalState.loading = false
                })
            },
            filterOption(inputValue, item){
                return item.label.indexOf(inputValue) !== -1
            },
            onChange(value, direction, moveKeys){
                this.selected_data = value
                this.$emit("update_value", value)
            },
            getRowSelection({
                disabled,
                selectedKeys,
                onItemSelectAll,
                onItemSelect,
            }){
                return {
                    getCheckboxProps: (item) => ({
                        disabled: disabled || item.disabled,
                    }),
                    onSelectAll(selected, selectedRows) {
                        const treeSelectedKeys = selectedRows
                        .filter(item => !item.disabled)
                        .map(({ key }) => key);
                        onItemSelectAll(treeSelectedKeys, selected);
                    },
                    onSelect( {key}, selected ) {
                        onItemSelect(key, selected);
                    },
                    selectedRowKeys: selectedKeys,
                };
            },
        }
    }
</script>
<style>
.el-transfer-panel{
    width: 35% !important;
    height: 100% !important;
}

.el-transfer-panel__item, .el-transfer-panel__header{
    margin-left: 0px !important;
}
.ant-transfer-list-footer{
    padding-right:3px !important;
    padding-bottom:4px !important
}
.disabled_row{
    background-color: rgba(0, 0, 0, 0.04) !important;
    display: none;
}
</style>