<style>
  .reply_body{
    background-color: whitesmoke !important;
  }
  .forum_logo{
    height: 50px !important;
  }
  .forum_body{
    background-color: #f6f8fa !important;
  }
  .forum_breadcrumb{
    background-color: white !important;
  }
  .forum_header{
    background-color: rgb(209.4, 236.7, 195.9) !important;
  }
  .forum_search .el-input-group__append {
    background-color: rgb(82.4, 155.2, 46.4) !important;
    color: white !important;
    -webkit-box-shadow: none;
    -moz-box-shadow: none;
    box-shadow: none;
  }
  .filter_collapse .el-collapse-item__header{
    border-bottom: 0px !important
  }
</style>
<template>
  <el-card shadow="never" body-class="forum_body" class="border-0">
    <el-card shadow="never" class="mb-3 forum_header border-0">
      <div class="row">
        <div class="col-md-8">
          <h4 class="mb-0" ><img :src="require('@/assets/CPRAS_logo.png')" class="forum_logo navbar-brand-img" alt="main_logo" />Forums</h4>
        </div>
        <div class="col-md-4">
          <el-button size="large" v-if="$store.state.isAdmin() && ($route.params.type == 'category')" @click="showAddCategory" class="mb-0 float-end" type="primary" >
              <i class="fa fa-plus"></i>&nbsp;Add Category
            </el-button>

            <el-button size="large" v-if="($store.state.isUserASupplier() || $store.state.isUserABuyer() || $store.state.isAdmin()) && ($route.params.type == 'thread')" @click="showAddThread" class="mb-0 float-end" type="success" >
              <i class="fa fa-edit"></i>&nbsp;Post a Thread
            </el-button>
        </div>
      </div>
    </el-card>

      <el-card v-if="$route.params.type != 'category'" shadow="never" body-class="p-0 ps-4 pt-3 pb-2" class="mb-3 forum_breadcrumb border-0">
      <el-breadcrumb class="h6" :separator-icon="ArrowRight">
          <el-breadcrumb-item :to="{ path: '/forum/category/' }">
            <el-button link type="warning" size="large" v-if="$route.params.id == 'search' && search_query">
              <i class="fa fa-arrow-left"></i>&nbsp;Back to Forum</el-button>
            <b v-else>Forum</b>
          </el-breadcrumb-item>
          <el-breadcrumb-item v-for="(breadcrumb, index) in breadcrumbs" 
            :to="{ path: (index < breadcrumbs.length - 1 ? '/forum/'+ breadcrumb.type +'/' + breadcrumb.id : null) }">
            {{ breadcrumb.name }}
          </el-breadcrumb-item>
          
        </el-breadcrumb>
    </el-card>

    <el-card body-class="pb-1" shadow="never" class="mb-3 border-0">
    <el-input
        v-on:keyup.enter="searchForum"
        v-model="search_value"
        size="large"
        placeholder="Quick search....."
        :prefix-icon="Search"
        class="mb-2 border-0 forum_search"
      >
      <template #append>
        <el-button @click="searchForum">Search</el-button>
      </template>
    </el-input>

    <el-collapse v-model="filter_collapse" class="filter_collapse mb-3 border ps-3 pe-2 pb-1" accordion>
      <el-collapse-item name="1">
        <template #title>
          
          <el-icon class="header-icon">
            <i class="fa fa-light fa-filter"></i>
          </el-icon>
          <el-text>&nbsp;Thread Filters</el-text>
        </template>
        <Transition>
          <ForumFilter
            :key="'forum_filter_thread_1'"
            :type="'thread'"
            v-if="filter_collapse == 1"
            :selected_category="selected_category_param"
            :selected_sort_type="selected_sort_type_param"
            :selected_sort_value="selected_sort_value_param"
          ></ForumFilter>
        </Transition>
      </el-collapse-item>
    </el-collapse>
      
    </el-card>

    <el-card v-if="$route.params.id == 'search' && search_query" shadow="never" body-class="p-0 ps-4 pt-3 pb-2" class="mb-3 forum_breadcrumb border-0">
      <p>Search results for query: <b><i>{{ search_query }}</i></b></p>
    </el-card>

    <el-card shadow="never" class="mb-3 border-0">

      
        <div class="row">
          <div class="col-md-12">

            <div v-if="$route.params.type == 'reply'">
              <ForumThreadHeader @view_likes="viewLikes" :data="thread_data"></ForumThreadHeader>
            </div>

            <el-collapse v-model="reply_filter_collapse" v-if="$route.params.type == 'reply'" class="filter_collapse mb-3 border ps-3 pe-2 pb-1" accordion>
              <el-collapse-item name="1">
                <template #title>
                  
                  <el-icon class="header-icon">
                    <i class="fa fa-light fa-filter"></i>
                  </el-icon>
                  <el-text>&nbsp;Reply Filters</el-text>
                </template>
                <ForumFilter
                  :key="'forum_filter_reply_!'"
                  :type="'reply'"
                  :param_id="$route.params.id"
                  :selected_category="selected_category_param"
                  :selected_sort_type="selected_sort_type_param"
                  :selected_sort_value="selected_sort_value_param"
                ></ForumFilter>
              </el-collapse-item>
            </el-collapse>

            <template v-if="forum_data.length > 0"> 
              <div class="row">
                <div class="col-md-12">
                  <pagination :hide_prev_next_when_ends="true" :prev_button_content="'Previous'" :next_button_content="'Next'" class="mb-3" v-if="$route.params.type != 'category'" :reverse_total="true" :meta="meta" v-on:update="loadForumData" />
                  <el-divider v-if="$route.params.type != 'category'" class="mt-0 mb-0" />
                </div>
              </div>
              <div  class="" v-for="data in forum_data">
                <div class="">
                  <div class="row">
                    <div class="col-md-12">
                  
                        <forum-category-item @delete_category="removeData" @edit="showCategoryEdit" @viewRules="viewRules" v-if="$route.params.type == 'category'" @navigateToPage="navigateToPage(data)" :data="data"></forum-category-item>
                        <forum-thread-item @delete_thread="removeData" :if_searched="(search_query || !$route.params.id) ? 1 : 0" @edit="showThreadEdit" v-if="$route.params.type == 'thread'" @navigateToPage="navigateToPage(data)" :data="data"></forum-thread-item>
                        <forum-reply-item @view_likes="viewLikes" :if_done_edit="if_reply_done_edit" @delete_reply="removeData" @edit="showReplyEdit" @reply="reply" v-if="$route.params.type == 'reply'" :data="data"></forum-reply-item>

                    </div>
                  </div>
                </div>
              </div>
              
              <div class="row">
                <div class="col-md-12">
                  <pagination class="mt-2" :hide_prev_next_when_ends="true" :prev_button_content="'Previous'" :next_button_content="'Next'" :reverse_total="true" :meta="meta" v-on:update="loadForumData" />
                </div>
              </div>
            </template>
            <div v-else>
              <el-card v-if="$route.params.id == 'search' && search_query" shadow="never">No records found based on query!</el-card>
              <el-card v-else shadow="never">No {{ $route.params.type }} yet, please create one.</el-card>
            </div>  
              <ForumReplyForm @cancelEdit="cancelReplyEdit" :replying_content="replying_content" @update_main_component_reply="updateSelectedReply" :key="'reply_form_' + reply_form_counter" @save="afterReplySave" v-if="$route.params.type == 'reply'" :selected="selected_reply" :thread_id="$route.params.id"></ForumReplyForm>  
              <div ref="reply_form" ></div>
          </div>
        </div>
    </el-card>

    <el-drawer v-model="category_drawer" :show-close="true" size="70%" direction="rtl"
      :append-to-body="true">
      <div class="container">
        <ForumCategoryForm :key="'category_form_' + category_form_counter" :selected="selected_category" @save="afterCategorySave" @cancel="handleClose">
        </ForumCategoryForm>
      </div>
    </el-drawer>

    <el-drawer v-model="thread_drawer" :show-close="true" size="70%" direction="rtl"
      :append-to-body="true">
      <div class="container">
        <ForumThreadForm :prop_selected_related_info="selected_thread?.related_information" :key="'thread_form_' + thread_form_counter" :category_id="this.$route.params.id" :selected="selected_thread" @save="afterThreadSave" @cancel="handleClose">
        </ForumThreadForm>
      </div>
    </el-drawer>

    <el-dialog id="rules-modal" v-model="rules_modal" width="60%">
      <div v-html="selected_rules"></div>
    </el-dialog>

    <el-dialog id="likes-modal" v-model="likes_modal" width="60%">
      <ForumLikeList
        :key="'like_list_component_' + like_component_counter"
        :like_param="like_param"
      ></ForumLikeList>
    </el-dialog>
  </el-card>
  

</template>
<script>
    import { Forum } from '@/api_services/forum'
    import ForumCategoryItem from '@/components/common/forum/ForumCategoryItem.vue'
    import ForumThreadItem from '@/components/common/forum/ForumThreadItem.vue'
    import ForumReplyItem from '@/components/common/forum/ForumReplyItem.vue'
    import { ArrowRight, Search } from '@element-plus/icons-vue'
    import ForumCategoryForm from './ForumCategoryForm.vue'
    import ForumThreadForm from './ForumThreadForm.vue'
    import ForumReplyForm from './ForumReplyForm.vue'
    import ForumThreadHeader from './ForumThreadHeader.vue'
    import ForumLikeList from '@/components/common/forum/ForumLikeList.vue'
    import ForumFilter from './ForumFilter.vue'

    export default {
      components: {
        ForumCategoryItem, ForumFilter, ForumLikeList, ForumThreadItem, ForumReplyItem, ForumCategoryForm, ForumThreadForm, ForumReplyForm, ForumThreadHeader
      },
      data() {
        return {
          if_after_reply_save: 0,
          selected_category_param: null,
          selected_sort_type_param: null,
          selected_sort_value_param: null,

          reply_filter_collapse: null,
          filter_collapse: null,
          like_component_counter: 1,
          like_param: {
            id: null,
            target_type: null
          },
          likes_modal: false,
            if_reply_done_edit: true,
            search_query: null,
            target_thread_drawer: "add",
            rules_modal: false,
            selected_rules: null,
            category_form_counter: 1,
            thread_form_counter: 1,
            thread_data: null,
            replying_content: [],
            search_value: null,
            selected_reply: {
              content: "",
              target_reply_ids: [],
              attachments: null
            },
            reply_form_counter: 1,
            selected_thread: null,
            thread_drawer: false,
            breadcrumbs: null,
            selected_category: null,
            category_drawer: false,
            meta: {
              page: 1,
              total: 0,
              pages: 1
            },
            forum_data: [],
            ArrowRight: ArrowRight,
            Search: Search
        }
      },
      created: function () {
        if(this.validateType()){
            this.loadForumData()
        }
      },
      watch: {
        '$route.params.type':{
          handler(value){
            if(value){
              this.meta = {
                page: 1,
                total: 0,
                pages: 1
              }
              this.loadForumData()
            }
          },
          deep:true
        },
        '$route.params.id':{
          handler(value){
            if(value){
              this.meta = {
                page: 1,
                total: 0,
                pages: 1
              }
              this.loadForumData()
            }
          },
          deep:true
        }
      },
      computed: {
        
      },
      methods: {
        viewLikes({id, type}){
          this.like_component_counter++
          this.like_param.id = id
          this.like_param.target_type = type
          this.likes_modal = true
        },
        removeData(data){
          this.forum_data = this.forum_data.filter(item => item.id !== data.id)
        },
        searchForum(){
          if((this.search_value && this.search_value.length > 2)){
            window.location.href = '/forum/thread/search?query=' + this.search_value
          }
          else if(this.search_value == ""){
            window.location.href = '/forum/category/'
          }
        },
        cancelReplyEdit(){
          this.if_reply_done_edit = true
          this.reply_form_counter++
          this.selected_reply = {
              content: "",
              target_reply_ids: [],
              attachments: null
            }
        },
        showReplyEdit(data){
          this.if_reply_done_edit = false
          this.reply_form_counter++
          this.selected_reply = data

          this.$refs.reply_form?.scrollIntoView() 
        },
        showAddThread(){
          if(this.target_thread_drawer == "edit"){
            this.thread_form_counter++
            this.target_thread_drawer = "add"
          }
          
          this.selected_thread = null
          this.thread_drawer = true
        },
        showThreadEdit(data){
          this.target_thread_drawer = "edit"
          this.selected_thread = data
          this.thread_drawer = true
        },
        showAddCategory(){
          this.category_form_counter++
          this.selected_category = null
          this.category_drawer = true
        },
        showCategoryEdit(data){
          this.selected_category = data
          this.category_drawer = true
        },
        storeView(view_type, category_id, thread_id){

          let view_form = {
            view_type: view_type,
            category_id: category_id,
            thread_id: thread_id
          }

          Forum.storeView(view_form)
            .then(resp => {

            })
            .catch(error => {
              
            })
            .finally(() => {
              
            })
        },
        viewRules(rules){
          this.selected_rules = rules
          this.rules_modal = true
        },
        updateSelectedReply({form, replying_content}){
          this.selected_reply = form
          this.replying_content = replying_content
        },
        reply(data){
          if(!this.selected_reply.target_reply_ids.includes(data.id)){
            this.selected_reply.target_reply_ids.push(data.id)
            this.replying_content.push(data)

            this.$refs.reply_form?.scrollIntoView() 
          }
        },
        afterReplySave(data) {
          this.if_reply_done_edit = true
          this.selected_reply = {
              content: "",
              target_reply_ids: [],
              attachments: null
            }
          this.replying_content = []
          this.if_after_reply_save = 1
          this.loadForumData()
        },
        afterThreadSave(data) {
          window.location.href = '/forum/thread/' + this.$route.params.id
        },
        loadForumData(){
          let get_sub = 0
          let only_main = 1
          let parent_id = null
          if(this.$route.params.id && this.$route.params.id > 0){
            get_sub = 1
            only_main = 0
            parent_id = this.$route.params.id
          }

          let default_order = "asc"
          let default_type = "created_at"

          if(this.$route.params.type == "thread"){
            default_order = "desc"
          }

          this.loadData(parent_id, only_main, get_sub, default_type + "," + default_order)
        },
        navigateToPage(data){
          if(this.$route.params.type == "category" && data.sub_category_count > 0){
            //this.$router.push({ path: '/forum/category/' + data.id })

            if(!data?.user_viewed_this_category){
              this.storeView(1, data.id, null)
            }
          }
          else if(this.$route.params.type == "category" && data.sub_category_count == 0){
            //this.$router.push({ path: '/forum/thread/' + data.id })

            if(!data?.user_viewed_this_category){
              this.storeView(1, data.id, null)
            }
          }
          else if(this.$route.params.type == "thread"){
            //this.$router.push({ path: '/forum/reply/' + data.id })

            if(!data?.user_viewed_this_thread){
              this.storeView(0, null, data.id)
            }
          }
        },
        handleClose() {
          this.category_drawer = false
          this.thread_drawer = false
        },
        afterCategorySave() {
          window.location.href = '/forum/category/' + this.$route.params.id
        },
        validateType(){
            const types = ["category", "thread", "reply", "search"]
            if (!this.$route.params.type || !types.includes(this.$route.params.type)) {
                this.$router.push({ path: '/' })
            }
            return true
        },
        loadData(parent_id, only_main, get_sub, order){
            this.validateType()
            this.globalState.loading = true
            this.search_value = null
            this.search_query = null
                const urlParams = new URL(location.href).searchParams;
                let search_param = urlParams.get('query');
                if(search_param){
                  this.search_value = search_param
                  this.search_query = search_param
                }

                let category_param = urlParams.get('category');
                let sort_type_param = urlParams.get('sort_type');
                let sort_value_param = urlParams.get('sort_value');
                
                if(category_param){
                  this.selected_category_param = category_param
                } 

                if(sort_type_param && sort_value_param){
                  order = sort_type_param + "," + sort_value_param

                  this.selected_sort_type_param = sort_type_param
                  this.selected_sort_value_param = sort_value_param
                }

                Forum.loadForumData({
                    params: {
                        category: category_param,
                        page: this.meta.page,
                        search_value: (this.$route.params.id == "search" ? search_param : ""),
                        type: this.$route.params.type,
                        target_id: this.$route.params.id,
                        only_main: only_main,
                        get_sub: get_sub,
                        parent_id:parent_id,
                        order: order,
                        all_data: 0
                    }
                })
                .then(result => {
                    if (result.data.forum_data){
                        this.forum_data = result.data.forum_data
                        this.meta = result.data.meta
                        this.breadcrumbs = result.data.breadcrumb
                        if(this.if_after_reply_save == 1){ 
                          this.meta.page = this.meta.pages 
                          this.if_after_reply_save = 0
                        }
                    }
                    this.thread_data = result.data.thread_data
                    if(urlParams.get('filters') && urlParams.get('filters') == 1)
                      this.filter_collapse = "1"
                    else  
                      this.filter_collapse = null

                    if(urlParams.get('reply_filters') && urlParams.get('reply_filters') == 1)
                      this.reply_filter_collapse = "1"
                    else  
                      this.reply_filter_collapse = null
                })
                .catch(error => {
                    console.log(error)
                })
                .finally(() => {
                    this.globalState.loading = false
                })
            
        }
      }
    }
  </script>