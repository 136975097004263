<template>
    <div class="">
        <div class="">

          <el-tabs @tab-click="tabClick" stretch type="border-card" class="demo-tabs">
            <el-tab-pane>
              <template #label>
                <span class="custom-tabs-label">
                  <el-icon><grid /></el-icon>
                  <span>Basic Interface</span>
                </span>
              </template>
              
              <template v-for="(main_theme, main_key, main_index) in user_theme">
                
                <template v-for="(theme, key, index) in main_theme" v-if="main_theme.category == 'basic_interface'">

                  <div v-if="theme.input_type == 'picture_card'" class="form-group pb-5 mb-2">
                  <el-text>{{ key.replace("_", " ").toUpperCase() }}</el-text>
                  <hr>
                  <PictureFileTypeInput
                  :data="user_theme?.logo?.sidebar_logo?.value"
                  :has_one_selected_button="1"
                  :select_button_text="'Set as Main Logo'"
                  :selected_text="'Selected Logo'"

                  :main_key="main_key"
                  :secondary_key="key"
                  :main_property_name="main_key"
                  :property_name="key"
                  ></PictureFileTypeInput>
                  
                  </div>
                  
                  <div v-if="theme.input_type == 'color_picker'" class="form-group form-control">
                    <el-text style="text-underline-offset: 3px;"><u>{{ main_key.replace("_", " ").toUpperCase() + " " + key.replace("_", " ").toUpperCase() }}</u></el-text>
                    <br v-if="main_theme?.description">
                    <el-text>{{ main_theme?.description }}</el-text>
                    <hr>

                    <ColorPickerTypeInput
                    :main_key="main_key"
                    :secondary_key="key"
                    :data="theme"
                    :main_property_name="main_key"
                    :property_name="key"
                    ></ColorPickerTypeInput>
                  </div>

                  <div v-if="theme.input_type == 'text'" class="form-group form-control">
                      <el-text>{{ main_key.replace("_", " ").toUpperCase() + " " + key.replace("_", " ").toUpperCase() }}</el-text>
                      <hr>

                      <TextTypeInput
                      :main_key="main_key"
                      :secondary_key="key"
                      :data="theme"
                      :main_property_name="main_key"
                      :property_name="key"
                      ></TextTypeInput>
                    </div>

                </template>

                </template>

            </el-tab-pane>

            <el-tab-pane>
              <template #label>
                <span class="custom-tabs-label">
                  <el-icon><postcard /></el-icon>
                  <span>Email Components</span>
                </span>
              </template>
              <div class="row">
              <div class="col-md-5">
                <template v-for="(main_theme, main_key, main_index) in user_theme">
                
                  <template v-for="(theme, key, index) in main_theme" v-if="main_theme.category == 'email_component'">

                    <div v-if="theme.input_type == 'picture_card'" class="form-group pb-5 mb-2">
                    <el-text>{{ key.replace("_", " ").toUpperCase() }}</el-text>
                    <hr>
                    <PictureFileTypeInput
                    :data="user_theme?.logo2?.email_logo?.value"
                    :has_one_selected_button="1"
                    :select_button_text="'Set as Email Logo'"
                    :selected_text="'Selected Logo'"

                    :main_key="main_key"
                    :secondary_key="key"
                    :main_property_name="main_key"
                    :property_name="key"
                    ></PictureFileTypeInput>
                    
                    </div>
                    
                    <div v-if="theme.input_type == 'color_picker'" class="form-group form-control">
                      <el-text>{{ main_key.replace("_", " ").toUpperCase() + " " + key.replace("_", " ").toUpperCase() }}</el-text>
                      <hr>

                      <ColorPickerTypeInput
                      :main_key="main_key"
                      :secondary_key="key"
                      :data="theme"
                      :main_property_name="main_key"
                      :property_name="key"
                      ></ColorPickerTypeInput>
                    </div>

                    <div v-if="theme.input_type == 'text'" class="form-group form-control">
                      <el-text>{{ main_key.replace("_", " ").toUpperCase() + " " + key.replace("_", " ").toUpperCase() }}</el-text>
                      <hr>

                      <TextTypeInput
                      :main_key="main_key"
                      :secondary_key="key"
                      :data="theme"
                      :main_property_name="main_key"
                      :property_name="key"
                      ></TextTypeInput>
                    </div>

                  </template>

                  </template>
              </div>
              <div class="col-md-7">
                  <EmailTemplate 
                  :data="user_theme"
                  ></EmailTemplate>
              </div>
            </div>
            </el-tab-pane>
          </el-tabs>

            
            
        </div>
    </div>
    
    </template>
    
    <script>
    import { mapState } from 'vuex'
    import PictureFileTypeInput from './config_inputs/PictureFileTypeInput.vue'
    import ColorPickerTypeInput from './config_inputs/ColorPickerTypeInput.vue'
    import TextTypeInput from './config_inputs/TextTypeInput.vue'
    import EmailTemplate from './email_components/EmailTemplate.vue'

    export default {
      components: {
        PictureFileTypeInput, ColorPickerTypeInput, EmailTemplate, TextTypeInput
      },
      computed: {
        ...mapState(['ApplicationThemeTypes']),
        user() {
          return this.$store.state.savedUser // get state
        }
      },
      props: {
        selected_theme:{
          type: Object,
          default: null
        }
      },
      data() {
        return {
          user_theme: null
        }
      },
      watch: {
        
      },
      created: function () {
        if(this.selected_theme){
          this.user_theme = this.selected_theme

          //alert(this.theme["navigation_title_font_color"].value)
        }
      },
      methods: {
        tabClick(tab, event){
          if(tab.index == 1)
            this.$emit('updateDrawerSize', 90)
          else
            this.$emit('updateDrawerSize', 40)
        }
      }
    }
    </script>
    
    <style></style>
    