<template>
    <div class="card mb-3 bg-white">
        <div class="card-body">
            <h5 class="mb-3">{{ title }}</h5>
        <template v-for="data in thread_data">
            <div class="row border pt-2">
                <div class="col-md-6">
                    <div class="row">
                    <div class="col-md-12">
                        <p class="mb-0 pb-1">
                          <el-tag
                            class="me-2 "
                            type="primary"
                            effect="dark"
                          >{{ data?.category_name }}</el-tag>
                        <el-tag
                            class="me-2 "
                            type="success"
                            effect="dark"
                        >THREAD</el-tag>
                        <a :href="'/forum/reply/' + data.id" >{{ data.title }}</a></p></div>
                    </div>
                    <div class="row">
                    <div class="col-md-12"><p class="text-sm">
                        <i class="fa fa-user"></i>
                        {{ data.user }} - 
                        <el-tooltip
                            class="box-item"
                            effect="dark"
                            :content="$filters.datetimeFormatFilter(data.created_at)"
                            placement="right-end"
                        >
                        <timeago :autoUpdate="60" :datetime="data?.created_at"/>
                        </el-tooltip>
                        
                    </p></div>
                    </div>
                </div>
                </div>
        </template>
    </div>
    </div>
  </template>
  
  <script>

    export default {
      props: {
        data: {
          type: Object,
          required: true,
          default: []
        },
        title:{
            type: String,
            required: true,
            default: ""
        }
      },
      components: {
        
      },
      data() {
        return {
            thread_data: []
        }
      },
      created: function () {
        if(this.data){
          this.thread_data = this.data
        }
      },
      watch: {
        data(value) {
          if(value){
            this.thread_data = value
          }
        },
      },
      computed: {
        
      },
      methods: {

      }
    }
  </script>
  