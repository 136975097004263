import api from './api'

const API = '/api/model_information'

export const ModelInformation = {
  get: function(params) {
    return api.get(API, params)
  },
  getModelTargetData: function(params) {
    return api.get(API + "/get-model-target-data", params)
  },
}
