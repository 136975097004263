<style>
  .el-upload-dragger{
    height: 100%;
  }
  .el-upload-list__item.is-ready, .el-upload-list__item.is-success{
    overflow: visible !important;
  }
</style>
<style>
  .el-upload--picture-card, .el-upload-list__item{
    margin-top: 40px !important;
  }
</style>
<template>
    <el-upload
        ref="picture_upload"
        drag
        v-model:file-list="input_upload"
        list-type="picture-card"
        :on-exceed="handleExceed"
        :on-change="onUploadChange"
        :before-upload="beforeUpload"
        :http-request="customHttp"
        :on-preview="onPreview"
        :before-remove="beforeRemove"
        :limit="max_pictures"
        accept="image/*"
    >
    <el-icon style="height: 100%;"><Plus /></el-icon>

    <template class="mt-5" #file="{ file }">
      <div>
        
        <img class="el-upload-list__item-thumbnail" :src="file.url" alt="" />

        <span class="el-upload-list__item-actions">
          
          <span
            class="el-upload-list__item-preview"
            @click="onPreview(file)"
          >
            <el-icon><zoom-in /></el-icon>
          </span>
          
          <span
            v-if="file?.default != 1"
            class="el-upload-list__item-delete"
            @click="beforeRemove(file)"
          >
            <el-icon><Delete /></el-icon>
          </span>
        </span>

        <el-button @click="setSelected(file)" v-if="has_one_selected_button == 1 && (!file.is_selected || file.is_selected == false)" 
        type="primary" class="mt-2 w-100"
        >{{ select_button_text }}</el-button>

        <el-tag size="large" class="mt-2 w-100" v-if="file.is_selected || file.is_selected == true">Selected</el-tag>

      </div>
    </template>

    </el-upload>
    
  </template>
  
  <script>
import { ApplicationTheme } from '@/api_services/application_theme'

  export default {
    computed: {
        user() {
          return this.$store.state.savedUser // get state
        }
    },
    emits :{
      uploadChange: null,
    },
    props: ['data', 'has_one_selected_button', 'select_button_text', 'selected_text', 'main_property_name', 'property_name', 'main_key', 'secondary_key'],
    data() {
      return {
        root_path: process.env.VUE_APP_API_ROOT,
        theme: null,
        max_pictures: 4,
        if_file_disable: false,
        input_upload: [],
        if_disable: false,
        size_limit: 15,
        if_from_max_limit: 0,
        theme_param: {
          path: null,
          primary_property: null,
          secondary_property: null
        }
      }
    },
    watch: {
      
    },
    created: function() {
        
      this.theme = this.user?.selected_theme?.theme_content
      
      if(this.data){
        var files = this.data
        for(var x = 0; x <= files.length - 1; x++){
            
            this.input_upload.push({
              uid: files[x].uid,
              //url: ( files[x]?.default == 1 ? (files[x].url) : (this.root_path + '/storage/' + files[x].backend_path) ),
              url: files[x].url,
              backend_path: files[x].backend_path,
              size: files[x].size,
              name: files[x].name,
              uploaded: files[x].uploaded,
              is_selected: files[x].is_selected,
              default: ( files[x]?.default ? files[x]?.default : 0 ),
              //is_saved: ( files[x]?.is_saved ? files[x]?.is_saved : 1 ),
            })
        }
        
      }
    },
    methods: {
      setSelected(file){
        this.input_upload.forEach(function(row){
          row.is_selected = false
        })
        file.is_selected = true

        if(this.theme[this.main_property_name][this.property_name]){
          this.theme[this.main_property_name][this.property_name].value = this.input_upload
        }
      },
      beforeRemove(file, filelist){
        if(this.if_from_max_limit == 0){

          return new Promise((resolve, reject) => {
          this.$confirm('Are you sure you want to delete this file?')
            .then(_ => {
              
              this.globalState.loading = true
              var id = this.user?.selected_theme?.id

              this.theme_param.path = file.backend_path
              this.theme_param.id = id
              this.theme_param.primary_property = this.main_property_name
              this.theme_param.secondary_property = this.property_name

              ApplicationTheme.removeFile(
                this.theme_param
              )
              .then(result => {
                this.$toast.success(result.data.message)
                this.input_upload = this.input_upload.filter(uploaded_file => uploaded_file.uid != file.uid)
                if(this.theme[this.main_property_name][this.property_name]){
                  this.theme[this.main_property_name][this.property_name].value = this.input_upload
                }

                resolve(true)
              })
              .catch(error => {
                console.error('error:', error)
              })
              .finally(() => {
                this.globalState.loading = false
              })
            })
            .catch(_ => {
              return false
          })
        })
        }
      },
      onPreview(file){
        window.open(file.url, 'Download');
      },
      customHttp(){
        return true
      },
      beforeUpload(file, fileList){
        this.if_from_max_limit = 0 
        
        const isLimit = file.size / 1024 / 1024 < this.size_limit
        if (!isLimit) {
            this.$toast.warning('The maximum file size is '+this.size_limit+'MB!')
            this.if_from_max_limit = 1
            return false
        }

        var allowedTypes = [
          'image/jpeg', 'image/png', 'image/jpg'
        ];
        if (!allowedTypes.includes(file.type)) {
          
          this.$toast.warning('Invalid file type. Please upload an image file only.')
          this.if_from_max_limit = 1
          return false
        }

      },
      onUploadChange(file, fileList){
        var formData = new FormData()
        formData.append('file', file.raw)

        this.globalState.loading = true

        ApplicationTheme.uploadImage(formData)
        .then(result => {
          if(result.data.success){
            let uploaded_file = result.data.file[0]

            this.input_upload.forEach(function(row){
              row.is_selected = false
            })

            file.is_selected = true
            file.uploaded = 1
            file.url = this.root_path + '/storage/' + uploaded_file.backend_path
            file.backend_path = uploaded_file.backend_path
            //file.is_saved = 0
            file.default = 0

            delete file.raw
            delete file.status
            delete file.percentage

            if(this.theme[this.main_property_name][this.property_name]){
              this.theme[this.main_property_name][this.property_name].value = fileList
            }
          }
          else{
            return false
          }
        })
        .catch(error => {
          this.$toast.error('An error occured while uploading')
          console.error(error)
          return false
        })
        .finally(() => {
          this.globalState.loading = false
        })
      },
      handleExceed(files, fileList) {
        this.$message.warning(`The limit is `+ this.max_pictures +`, you selected ${files.length + fileList.length} totally, please first remove the unwanted pictures`);
      },
    }
  }
  </script>
  