<template>
    <div>
        <el-button type="primary" @click="getPrevious()" v-if="previous_form">Fetch Previous Data</el-button>
    </div>
    <div class="card-body">
        <el-card>
            <div class="row">
                <div class="row" v-if="tpp_procurement_type == TPPNoticeTypes.UK4.value">
                    <div class="col-md-12">
                        <label>Are the conditions of participation the same for all lots?</label>
                        <label style="color: #a9a9a9">Select yes if no lots have conditions of participation.</label>
                        <div :class="{ 'has-error-field': v.form.participation_conditions_same.$invalid }">
                            <el-radio-group v-model="form.participation_conditions_same">
                                <el-radio-button :value="true">Yes</el-radio-button>
                                <el-radio-button :value="false">No</el-radio-button>
                            </el-radio-group>
                        </div>
                        <div v-if="v.form.participation_conditions_same.$invalid" class="error-text" >Yes/No is required</div>
                    </div>
                    <div class="row" v-if="form.participation_conditions_same === false">
                        <div class="col-md-6">
                            <label>Legal and financial capacity conditions of participation (optional)</label>
                            <br/><label style="color: #a9a9a9">Must not require insurance before contract award, or audited accounts.</label>
                            <el-input v-model="form.legal_conditions" type="textarea" :rows="3"></el-input>
                        </div>
                        <div class="col-md-6">
                            <label>Technical ability conditions of participation (optional)</label>
                            <br/><label style="color: #a9a9a9">Must allow equivalent qualifications and standards.</label>
                            <el-input v-model="form.technical_conditions" type="textarea" :rows="3"></el-input>
                        </div>
                    </div>
                    <div class="col-md-12">
                        <br/><label>Is the suitability for small and medium-sized enterprises (SMEs) and voluntary, community and social enterprises (VCSEs) the same for all lots?</label>
                        <div :class="{ 'has-error-field': v.form.is_suitability_same.$invalid }">
                            <el-radio-group v-model="form.is_suitability_same">
                                <el-radio-button :value="true">Yes</el-radio-button>
                                <el-radio-button :value="false">No</el-radio-button>
                            </el-radio-group>
                        </div>
                        <div v-if="v.form.is_suitability_same.$invalid" class="error-text" >Yes/No is required</div>
                    </div>
                </div>
                <div class="col-md-12" v-if="tpp_procurement_type == TPPNoticeTypes.UK2.value || form.is_suitability_same">
                    <label>Is this contract particularly suitable for the following groups? (optional)</label>
                    <span class="row">
                        <el-checkbox v-model="form.is_sme" :checked="form.is_sme">Small and medium-sized enterprises (SMEs) <br/>
                            <span style="color: #a9a9a9">Fewer than 250 staff, and either turnover less than £44 million or balance sheet total less than £38 million.</span>
                        </el-checkbox>
                    </span>
                    <span class="row">
                        <el-checkbox v-model="form.is_vcse" :checked="form.is_vcse">Voluntary, community and social enterprises (VCSEs) <br/>
                            <span style="color: #a9a9a9">Non-governmental organisation that is value-driven and principally reinvests its surpluses to further social, environmental or cultural objectives.</span>
                        </el-checkbox>
                    </span>
                </div>
            </div>
        </el-card>
    </div>
    <span class="col-md-3 mb-3" v-if="is_not_submitted"><el-button type="success" @click="saveForm()">Save and go to Next Section</el-button></span>
 </template>
 
 <script>
    import { mapState } from 'vuex'
     import {
     useVuelidate
     } from '@vuelidate/core'
     import {
     required
     } from '@vuelidate/validators'
 
     const PARTICIPATION = 'PARTICIPATION';

     export default {
         props: ['previous_form', 'saved_form', 'is_not_submitted', 'tpp_procurement_type'],
         data() {
             return {
                form: {
                    is_sme: null, 
                    is_vcse: null, 
                    participation_conditions_same: null,
                    legal_conditions: null,
                    technical_conditions: null,
                    is_suitability_same: null,
                }
            }
         },
        setup: () => ({
             v: useVuelidate()
        }),
        created() {
            if(this.saved_form) this.form = this.saved_form

        },
        validations() {
            let rules = {
                form: {
                
                }
            }

            if(this.tpp_procurement_type == this.TPPNoticeTypes.UK4.value) {
                rules.form = {...rules.form, ...{
                    participation_conditions_same: {
                        required,
                        $autoDirty: true
                    },
                    is_suitability_same: {
                        required,
                        $autoDirty: true
                    }
                }}
            }
            
            return rules
        },
        computed: {
        ...mapState(['TPPNoticeTypes', 'TPPFormParts']),
        },
        watch: {
            saved_form: {
                handlers(form){
                    this.form = form
                },
                deep: true
            }
        },
        methods: {
            saveForm() {
                this.$emit('save', PARTICIPATION, this.form)
            },
            getPrevious() {
                delete this.previous_form.id
                this.form = this.previous_form
            },
        }

     }
 </script>