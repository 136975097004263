
<style>
  .reply_blackqoute{
    border-left: 4px solid #909399;
    background-color: whitesmoke !important;
    padding-left: 15px;
    padding-top: 10px;
    padding-bottom: 5px;
  }
  .bg_new{
    background-color: whitesmoke !important;
  }
  .bg_edit{
    background-color: rgb(242.5, 208.5, 157.5) !important;
  }
</style>
<template>
     <el-card shadow="never" class="forum-layout mb-3 mt-3" :class="isNew ? 'bg_new' : 'bg_edit'">
      </br>
        <form @submit.prevent.prevent="" @reset.prevent="onCancel">
          <el-card v-if="reply_content.length > 0" shadow="never" class="mb-2">
            <template #default>
              <h6>Replying to...</h6>
              <hr>
              <ReplyBodyForEditor @removeReplyItem="removeReplyItem" :replying_content="reply_content"></ReplyBodyForEditor>
            </template>
          </el-card>
          <div class="form-group bg-white">

            <quill-editor ref="quill_reply" @ready="onEditorReady($event)" id="content_quill" contentType="html"
                    v-model:content="form.content" :options="editorOptions" @change="contentChangeEvnt($event)"></quill-editor>
  
            <div class="input-errors" v-for="error of v$.form.content.$errors" :key="error.$uid">
              <div class="error-text">Content {{ error.$message }}</div>
            </div>
          </div>

          <div class="form-group ">
            <el-card shadow="never" class="p-0">
                <ElUpload 
                :quick_remove="isNew"
                :show_icon="false" :drag="false" v-model="form.attachments"
                :data="form.attachments"
                @removeFile="removeAttachment"
                @uploadChange="updateAttachmentsValue"></ElUpload>
            </el-card>
          </div>
  
          <div class="pt-0 text-end form-buttons">
            <el-button type="success" @click="onSubmit" :disabled="isSaving"><i class="fa fa-reply"></i>&nbsp;{{isSaving ? 'Posting...' : 'Post'}}</el-button>
            <el-button type="danger" @click="cancelEdit" v-if="!isNew"><i class="fa fa-times"></i>&nbsp;Cancel</el-button>
          </div>
        </form>
    </el-card>
    
  </template>
  
  <script>
    import { Forum } from '@/api_services/forum'
    import { useVuelidate } from '@vuelidate/core'
    import { required, email, requiredIf } from '@vuelidate/validators'
    import { quillEditor } from 'vue3-quill'
    import 'quill/dist/quill.core.css';
    import 'quill/dist/quill.snow.css'; 
    import 'quill/dist/quill.bubble.css';
    import ElTagInput from '@/components/common/ElTagInput'
    import ElUpload from '@/components/common/ElUpload'
    import ReplyBodyForEditor from './ReplyBodyForEditor.vue'

    export default {
      emits: ["save", "cancel", "update_main_component_reply"],
      setup () {
        return { v$: useVuelidate() }
      },
      props: {
        selected: {
          type: Object,
          required: true,
          default: []
        },
        thread_id: {
          type: String,
          required: true,
          default: 0
        },
        replying_content: {
          type: String,
          required: false,
          default: ""
        },
      },
      components: {
        quillEditor, ElTagInput, ElUpload, ReplyBodyForEditor
      },
      data() {
        return {
          quick_remove: true,
          reply_content: [],
          isSaving: false,
          form:{
            content: null,
            target_reply_ids: [],
            attachments: null
          },
          editorOptions: {
            placeholder: '....',
            theme: 'snow',
            modules: {
              toolbar: [
              ['italic', 'underline', 'strike'],        // toggled buttons
              ['blockquote', 'code-block'],
              ['link', 'formula'],

              [{ 'header': 1 }, { 'header': 2 }],               // custom button values
              [{ 'list': 'ordered'}, { 'list': 'bullet' }, { 'list': 'check' }],
              [{ 'script': 'sub'}, { 'script': 'super' }],      // superscript/subscript
              [{ 'indent': '-1'}, { 'indent': '+1' }],          // outdent/indent
              [{ 'direction': 'rtl' }],                         // text direction

              [{ 'size': ['small', false, 'large', 'huge'] }],  // custom dropdown
              [{ 'header': [1, 2, 3, 4, 5, 6, false] }],

              [{ 'color': [] }, { 'background': [] }],          // dropdown with defaults from theme
              [{ 'font': [] }],
              [{ 'align': [] }],

              ['clean'] 
              ]
            },
          }
        }
      },
      validations() {
        return {
          form: {
            content: {
              required,
              $autoDirty: true
            }
          },
        }
      },
      created: function () {
        if(this.selected){
          this.form = JSON.parse(JSON.stringify(this.selected))
        }

        if(this.replying_content){
          this.reply_content = JSON.parse(JSON.stringify(this.replying_content))
        }
      },
      watch: {
        selected: {
          handler(value){
            if(value){
              this.form = JSON.parse(JSON.stringify(value))
            }
          },
          deep:true
        },
        replying_content: {
          handler(value){
            if(value){
              this.reply_content = JSON.parse(JSON.stringify(value))
            }
          },
          deep:true
        }
      },
      computed: {
        isNew() {
          return !this.selected?.id
        },
      },
      methods: {
        cancelEdit(){
          this.$emit('cancelEdit', true)
        },
        removeReplyItem({data, id}){
          this.reply_content = data
          this.form.target_reply_ids = this.form.target_reply_ids.filter(function(item) {
              return item !== id
          })
          this.$emit('update_main_component_reply', {form: this.form, replying_content: this.reply_content})
        },
        updateAttachmentsValue(files){
          this.form.attachments = files
        },
        removeAttachment(file){
          if(file.path && !this.isNew){
            this.globalState.loading = true
            let formData = new FormData()
            formData.append('path', file.path)
            formData.append('type', 1)

            Forum.removeFile(this.form.id, formData)
            .then(resp => {
              if(resp.data.response == 1){
                this.$toast.success("Successfully removed!")
              }
              else{
                this.$toast.warning("Failed to remove!")
              }
            })
            .catch(error => {
              console.error('error:', error)
            })
            .finally(() => {
              this.globalState.loading = false
            })
          }
        },
        contentChangeEvnt({ quill, html, text }){
          this.form.content = html
          this.$emit('update_main_component_reply', {form: this.form, replying_content: this.reply_content})
        },
        onEditorReady (e) {
          var this_id = e.container.id
          if(this_id == "content_quill") e.container.querySelector('.ql-blank').innerHTML = this.form.content ? this.form.content : ""
        },
        async onSubmit(evt) {
  
          evt.preventDefault()
          const result = await this.v$.$validate()
          if (!result) return

          let formData = new FormData()
          formData.append('thread_id', this.thread_id)
          formData.append('content', this.form.content)
          if(!this.isNew){
            this.form.target_reply_ids = JSON.parse(this.form.target_reply_ids)
            this.form.target_reply_ids = this.form.target_reply_ids.split(",")
          }
          formData.append('target_reply_ids', this.form.target_reply_ids)
          if(this.form.attachments && this.form.attachments.length > 0){
            if(typeof this.form.attachments != "object"){
              this.form.attachments = JSON.parse(this.form.attachments)
            }
            this.form.attachments.forEach(function(file) {
              if(file.raw){
                formData.append('files[]', file.raw)
              }
            }) 
          }

          this.isSaving = true
          const promise = this.isNew ? Forum.storeReply(formData) : Forum.updateReply(this.form.id, formData)
          promise
            .then(resp => {
              
              this.$toast.success(resp.data.message || 'Successfully replied!')
              this.$emit('save', this.form)
            })
            .catch(error => {
              console.error('error:', error)
            })
            .finally(() => {
              this.isSaving = false
              this.form = {
                content: null,
                target_reply_ids: [],
                attachments: null
              }
            })
        },
        onCancel() {
          this.$emit('cancel')
        },
      }
    }
  </script>
  