import api from './api'

const API = '/api/forum'

export const Forum = {
    loadForumData: function(params) {
      return api.get(API + "/load-forum-data", params)
    },
    viewLikeList: function(params) {
      return api.get(API + "/view-like-list", params)
    },
    getThreadRelatedInformation: function(params) {
      return api.get(API + "/get-thread-related-info", params)
    },
    storeCategory: function(params) {
      return api.create(API + "/store-category", params)
    },
    storeThread: function(params) {
      return api.createFormData(API + "/store-thread", params)
    },
    storeReply: function(params) {
      return api.createFormData(API + "/store-reply", params)
    },
    updateLike: function(params) {
      return api.update(`${API}/update-like/${params.like_id}`, params)
    },
    storeView: function(params) {
      return api.create(API + "/store-view", params)
    },
    updateCategory: function(id, params) {
      return api.update(`${API}/update-category/${id}`, params)
    },
    updateThread: function(id, params) {
      return api.updateFormData(`${API}/update-thread/${id}`, params)
    },
    removeFile: function(id, params) {
      return api.update(`${API}/remove-file/${id}`, params)
    },
    updateReply: function(id, params) {
      return api.updateFormData(`${API}/update-reply/${id}`, params)
    },
    delete: function(params) {
      return api.delete(API+'/delete-forum-data/' + params.id, params)
    }
}
