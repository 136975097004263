<template>
    <el-upload
        ref="uploadRef"
        multiple
        :disabled="from_reply"
        :drag="drag"
        v-model:file-list="input_upload"
        :on-change="onUploadChange"
        :before-upload="beforeUpload"
        :http-request="customHttp"
        :on-preview="onPreview"
        :before-remove="beforeRemove"
        accept="application/msword, application/vnd.ms-excel, application/vnd.ms-powerpoint,text/plain, application/pdf, image/*"
    >
        <el-icon v-if="show_icon" class="el-icon--upload"><upload-filled /></el-icon>
        <div v-if="!from_reply" class="el-upload__text">
          Drop file here or <em>click to upload</em>
        </div>
        <template v-if="!from_reply" #tip>
        <div class="el-upload__tip">
            documents with a size less than or equal to {{size_limit}}MB.
        </div>
        </template>
    </el-upload>
  </template>
   
  <script>
    import Swal from 'sweetalert2'

  export default {
    emits :{
      uploadChange: null,
      removeFile: null
    },
    props: {
      data: {
        type: Object,
        required: false,
        default: []
      },
      drag: {
        type: Boolean,
        required: false,
        default: true
      },
      show_icon: {
        type: Boolean,
        required: false,
        default: true
      },
      from_reply: {
        type: Boolean,
        required: false,
        default: false
      },
      quick_remove: {
        type: Boolean,
        required: false,
        default: false
      },
    },
    data() {
      return {
        input_upload: [],
        size_limit: 30,
        if_from_max_limit: 0
      }
    },
    watch: {
      data: {
        handler(value){
          if(value){
            
          }
          else{
            this.input_upload = []
          }
        },
        deep:true
      },
    },
    created: function() {

        if(this.data){
          var files = JSON.parse(this.data)
          for(var x = 0; x <= files.length - 1; x++){
            
            this.input_upload.push({
              name: files[x].filename,
              url: process.env.VUE_APP_API_ROOT + "/storage/" + files[x].path,
              path: files[x].path
            })
          }
        }

    },
    methods: {
      beforeRemove(file, filelist){
        if(this.if_from_max_limit == 0){

          return new Promise((resolve, reject) => {
            if(!this.quick_remove){
              Swal.fire({
                icon: 'info',
                text:
                    'Remove this file?',
                    showCancelButton: true,
                    confirmButtonText: 'Proceed',
                    cancelButtonText: 'Cancel',  
                }).then(result => {
                    if (result.isConfirmed) {
                        if(this.data){
                            this.$emit('removeFile', file)
                        }
                        resolve(true)
                    }
                    else{
                        return false
                    }
                })
            }
            else{
              resolve(true)
            }
           })
        }
      },
      onPreview(file){
        window.open(file.url, 'Download');
      },
      customHttp(){
        return true
      },
      beforeUpload(file, fileList){
        this.if_from_max_limit = 0
        
        const isLimit = file.size / 1024 / 1024 < this.size_limit
        if (!isLimit) {
            this.$toast.warning('The maximum file size is '+this.size_limit+'MB!')
            this.if_from_max_limit = 1
            return false
        }

        var allowedTypes = [
          'application/msword', 'application/vnd.ms-excel', 'application/vnd.ms-powerpoint,text/plain',
          'application/pdf', 'image/jpeg', 'image/png', 'image/jpg'
        ];
        if (!allowedTypes.includes(file.type)) {
          
          this.$toast.warning('Invalid file type. Please upload a document or image file only.')
          this.if_from_max_limit = 1
          return false
        }
      },
      onUploadChange(file, fileList){
        this.$emit('uploadChange', fileList)
      },
    }
  }
  </script>
  