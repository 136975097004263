import { merge } from 'lodash-es';
import { genMixColor } from './gen-color';

const defaultColorsConfig = {
    colors: {
        primary: '#FF0000',
        info: '#eeeeee',
        warning: '#fbbd23',
        danger: '#f87272',
        success: '#36d399',
    },
};

function setStyleProperty(propName, value) {
    //document.getElementsByClassName("el-button").style.setProperty(propName, value);
    document.documentElement.style.setProperty(propName, value);
}

function updateThemeColorVar({ colors }) {
    
    for (const brand in colors) {
        updateBrandExtendColorsVar(
            colors[brand],
            brand
        );
    }

    function updateBrandExtendColorsVar(color, name) {
        const { DEFAULT, dark, light } = genMixColor(color);
        
        setStyleProperty(`--${name}-lighter-color`, light[5]);
        setStyleProperty(`--${name}-light-color`, light[3]);
        setStyleProperty(`--${name}-color`, DEFAULT);
        setStyleProperty(`--${name}-deep-color`, dark[2]);
        setStyleProperty(`--${name}-deeper-color`, dark[4]);

        setStyleProperty(`--el-color-${name}`, DEFAULT);
        setStyleProperty(`--el-color-${name}-dark-2`, dark[2]);
        setStyleProperty(`--el-color-${name}-light-3`, light[3]);
        setStyleProperty(`--el-color-${name}-light-5`, light[5]);
        setStyleProperty(`--el-color-${name}-light-7`, light[7]);
        setStyleProperty(`--el-color-${name}-light-8`, light[8]);
        setStyleProperty(`--el-color-${name}-light-9`, light[9]);
    }
}

export const setIndividualColor = (color) => {
    updateThemeColorVar(color);
}

export const setButtonColors = (data = defaultColorsConfig) => {
    updateThemeColorVar(data);
};
