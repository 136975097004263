
<template>
    <el-card shadow="never" >
        <p class="mb-2">Show only:</p>
        <el-divider v-if="type == 'thread'" class="mt-0 mb-3" />
        <div v-if="type == 'thread'" class="row ">
            <div class="col-md-12 reply_body pt-2">
                <el-form-item>
                    <p class="text-sm"><b>Category:</b></p>
                    <el-select v-model="category" placeholder="Select Category">
                        <el-option key="" label="All" value=""></el-option>
                        <el-option
                        v-for="item in categories"
                        :key="item.id"
                        :label="item.name"
                        :value="item.id"
                        />
                    </el-select>
                </el-form-item>
            </div>
        </div>
        <el-divider class="mt-0 mb-3" />
        <div class="row">
            <div class="col-md-12 reply_body pt-2">
                <p class="text-sm"><b>Sort By:</b></p>
                <el-form-item >
                    
                    <el-col :span="12">
                        <el-select  style="width: 100% !important;" v-model="sort_type" placeholder="">
                                <el-option key="" label="None" value=""></el-option>
                                <el-option v-if="type == 'thread'" key="title" label="Title" value="title"></el-option>
                                <el-option key="created_at" label="Created Date" value="created_at"></el-option>
                                <el-option v-if="type == 'thread'" key="recent_activity_date" label="Recent Activity" value="recent_activity_date"></el-option>
                                <el-option v-if="type == 'thread'" key="total_replies" label="Replies" value="total_replies"></el-option>
                                <el-option key="total_likes" label="Likes" value="total_likes"></el-option>
                                <el-option v-if="type == 'thread'" key="total_views" label="Views" value="total_views"></el-option>
                            </el-select>
                    </el-col>
                    <el-col :span="1" class="text-center">
                        <span class="text-gray-500">-</span>
                    </el-col>
                    <el-col :span="11">
                        <el-select  v-model="sort_value" placeholder="">
                                <el-option key="desc" label="Descending" value="desc"></el-option>
                                <el-option key="asc" label="Ascending" value="asc"></el-option>
                            </el-select>
                    </el-col>
                </el-form-item>
            </div>
        </div>
        <el-divider class="mt-0 mb-3" />
        <el-button v-if="type == 'thread'" plain size="large" @click="backToForum" class="mb-2 float-end ms-2" type="warning" >
            <i class="fa fa-arrow-left"></i>&nbsp;Back to Forum
        </el-button>
        <el-button plain size="large" @click="filter" class="mb-2 float-end" type="primary" >
            <i class="fa fa-filter"></i>&nbsp;Filter
        </el-button>
        
    </el-card>
</template>

  <script>
    import { Forum } from '@/api_services/forum'

    export default {
      emits: [],
      props: {
        type: {
            type: String,
            required: true,
            default: "thread"
        },
        param_id: {
            type: Number,
            required: false,
            default: 0
        },
        selected_category: {
            type: Number,
            required: false,
            default: null
        },
        selected_sort_type: {
            type: String,
            required: false,
            default: null
        },
        selected_sort_value: {
            type: String,
            required: false,
            default: null
        }
      },
      components: {
        
      },
      data() {
        return {
            category: null,
            categories: [],
            sort_type: null,
            sort_value: null
        }
      },
      created: function () {
        if(this.type == "thread"){
            this.loadCategories()
        }
        if(this.selected_sort_type){
            this.sort_type = this.selected_sort_type
        }
        if(this.selected_sort_value){
            this.sort_value = this.selected_sort_value
        }
      },
      watch: {
        selected_category: {
          handler(value){
            if(value){
              //this.category = value
            }
          },
          deep:true
        },
        sort_type: {
          handler(value){
            if(!this.sort_value){
                this.sort_value = "desc"
            }
            if(!value){
                this.sort_value = null
            }
          },
          deep:true
        }
      },
      computed: {
        
      },
      methods: {
        backToForum(){
            window.location.href = '/forum/category/'
        },
        filter(){
            let param = "?";
            if(this.category){
                if(param != "?"){
                    param += "&"
                }
                param += "category=" + this.category
            }

            if(this.sort_type && this.sort_value){
                if(param != "?"){
                    param += "&"
                }
                param += "sort_type=" + this.sort_type + "&sort_value=" + this.sort_value
            }

            if(this.type == "thread"){
                if(param != "?"){
                    param += "&filters=1"
                }
                window.location.href = "/forum/thread/" + param
            }
            else if(this.type == "reply"){
                if(param != "?"){
                    param += "&reply_filters=1"
                }
                window.location.href = "/forum/reply/" + this.param_id + param
            }
            else    
                window.location.href = "/forum/category/"
            
        },
        loadCategories(){
            this.globalState.loading = true
                Forum.loadForumData({
                    params: {
                        page: null,
                        search_value: "",
                        type: "category",
                        target_id: null,
                        only_main: 0,
                        get_sub: 1,
                        parent_id: null,
                        order: "name,asc",
                        all_data: 1
                    }
                })
                .then(result => {
                    if (result.data.forum_data){
                        this.categories = result.data.forum_data
                        if(this.selected_category){
                            this.category = parseInt(this.selected_category)
                        }
                    }
                })
                .catch(error => {
                    console.log(error)
                })
                .finally(() => {
                    this.globalState.loading = false
                })
            
        },
      }
    }
  </script>
  