<template>
  <div>
    <loading :loading="globalState.loading"  :message="globalState.message"></loading>
    <el-drawer
          v-model="show_f2a"
          direction="btt"
          :z-index="22000"
          size="100%"
          :show-close="false"
          :append-to-body="true"
          :close-on-press-escape="false"
        >
        <two-fac></two-fac>
      </el-drawer>
    <nav
      id="navbarBlur"
      class="navbar navbar-main navbar-expand-lg px-0 mx-4 border-radius-xl shadow-none"
      data-scroll="true"
    >
      <div class="px-3 py-1 container-fluid">
        <breadcrumbs
          v-if="user"
          :current-page="currentRouteName"
          :current-directory="currentDirectory"
        />

        <div
          id="navbar"
          class="mt-2 collapse navbar-collapse mt-sm-0 me-md-auto me-sm-4"
        >
          <ul class="navbar-nav justify-content-end ms-md-auto">
            <li
              v-if="user"
              class="nav-item dropdown d-flex align-items-center text-bold text-white text-sm"
            >
              <!-- <a href="/main/profile" class="px-0 nav-link font-weight-bold text-white"
                > -->
              <i class="fa fa-user me-sm-1"></i> {{ user.name }}
              <!-- </a
              > -->
            </li>
            <li v-else class="nav-item d-flex align-items-center">
              <router-link
                to="/main/help"
                class="px-0 nav-link font-weight-bold text-white"
                v-if="user"
              >
                <i class="fa-solid fa-circle-question"></i>
              </router-link>
              <!-- <router-link
                v-if="!user"
                to="/login"
                class="px-0 nav-link font-weight-bold text-white ps-3"
              >
                <i class="fa fa-user me-sm-1"></i>
                <span class="d-sm-inline d-none">Login</span>
              </router-link> -->
              <!-- <router-link
                v-if="!user"
                to="/supplier-hub/signup"
                class="px-0 nav-link font-weight-bold text-white ps-3"
              >
                <i class="fa fa-user me-sm-1"></i>
                <span class="d-sm-inline d-none">Supplier Hub Signup</span>
              </router-link> -->
            </li>
            <li
              class="nav-item dropdown d-flex align-items-center ps-3 pe-2"
              v-if="user"
            >
              <notification></notification>
            </li>
          </ul>
        </div>
      </div>
    </nav>
  </div>
</template>

<script>
import { LocalStorageService } from '@/services/LocalStorageService'
import { mapState } from 'vuex'
// English.

import { User } from '@/api_services/user'
import store from '@/store/index'
import Breadcrumbs from '../common/Breadcrumbs.vue'
import Notification from '../common/Notification.vue'
import TwoFac from './2fa/TwoFac.vue'
import CPRAS_logo from '@/assets/CPRAS_logo.png'

export default {
  components: {
    Breadcrumbs,
    Notification,
    TwoFac
  },
  data() {
    return {
      color_test: 'red',
      default_logo: CPRAS_logo,
      theme_type: null,
      theme: null,
      isLoadingPage: false,
      isUserLoaded: false,
      notifications: [],
      isUserLoggedIn: false,
      show_f2a: false
    }
  },
  created: function() {
    this.checkForPublicRoutes()

    if(this.user?.selected_theme?.theme_content){
      this.theme_type = this.user?.selected_theme?.type
      this.theme = this.user?.selected_theme?.theme_content
    }
  },
  computed: {
    '--color_test'(){
      return "red"
    },
    /*theme() {
      alert(fdsa)
      return this.user?.selected_theme?.theme_content
    },*/
    ...mapState(['isChangingRoute', 'mac_address', 'ApplicationThemeTypes']),
    user() {
      return this.$store.state.savedUser
    },
    currentDirectory() {
      return this.$route.meta.breadcrumbDir
    },
    currentRouteName() {
      return (
        this.$route.meta?.breadcrumb ||
        this.$route.name
          ?.replace(/[_,-]/g, ' ')
          ?.replace(/(?:^|\s|-)\S/g, x => x.toUpperCase())
      )
    }
  },
  watch: {
    $route(to, from) {
      this.checkForPublicRoutes(to, from)
    },
    isChangingRoute(isChangingRoute) {

      this.isLoadingPage = false
    }
  },
  methods: {
    toggle() {
      document.body.classList.toggle('sb-sidenav-toggled')
      document.getElementById('sidebar').classList.toggle('sb-sidenav-toggled')
    },
    checkForSupplierHubUser(to, from) {
      let user = this.$store.state.savedUser

      if (user.role_names.includes('SUPPLIER_HUB')) {
        if (
          this.$route.name !== 'SuppliersHubDashboard' &&
          this.isUserLoggedIn
        ) {
          if (user.company) {
            this.$router.push({ name: 'SuppliersHubDashboard' })
          }
        }
      }
    },

    checkForSupplier(to, from) {
      let user = this.$store.state.savedUser

      if (user.role_names.includes('supplier')) {
        if (this.$route.name !== 'supplier') {
          if (
            user.company &&
            user.company.supplier &&
            !user.company.supplier.has_completed_information
          ) {
            this.$router.push({ name: 'supplier' })
          }
        }
      }
    },
    async triggerLoading() {
      this.globalState.loading = true
      let self = this
      setTimeout(function () {
        self.globalState.loading = false
      }, 100);
    },
    checkForPublicRoutes(to, from) {
      this.triggerLoading()
      if (this.$route.name && !this.$route.meta.public) {
        if (!this.user) {
          this.$router.push({ name: 'Login' })
        }
      }

      if (this.user && !this.isUserLoaded) {
        store.commit('saveLoadingUser', true)
        let params = {
          mac_address: this.mac_address,
          device_type: this.$helpers.getDeviceType()
        }
        
        User.currentUser(params)
          .then(result => {
            if (result.data.user) {
              store.commit('saveLoadingUser', false)
              store.commit('saveUser', result.data.user)
              this.checkForSupplier(to, from)
              this.checkForSupplierHubUser(to, from)
              this.isUserLoaded = true
              this.isUserLoggedIn = true
            }
            if (!result.data.trusted) {
              this.show_f2a = true
            }
          })
          .catch(() => {})
          .finally(() => {
          })
      }

      if (!this.user) {
        this.isUserLoaded = false
        this.isUserLoggedIn = false
      }
    }
  }
}
</script>

<style scoped>
#notifications-dropdown-menu li:not(:last-child) {
  margin-bottom: 0.5rem;
}

.dropdown-menu {
  visibility: hidden;
}

@media (min-width: 992px) {
  .dropdown.dropdown-hover:hover > .dropdown-menu:before,
  .dropdown .dropdown-menu.show:before {
    top: -18px;
  }
}
</style>
