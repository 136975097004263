export const LocalStorageService = {
  getMacAddress: function() {
    let mac = localStorage.getItem('mac_address')
    if (!mac) {
      const chars = 'ABCDEF0123456789'
      let mac = ''

      for (let i = 0; i < 12; i++) {
        const index = Math.floor(Math.random() * chars.length)
        mac += chars[index]
        if ((i + 1) % 2 === 0 && i !== 11) {
          mac += ':'
        }
      }
      localStorage.setItem('mac_address', mac)

    }

    console.log(localStorage.getItem('mac_address'))

    return localStorage.getItem('mac_address')
  },
  setMacAddress: function(mac_address) {
    localStorage.setItem('mac_address', mac_address)
  },
  getLoadingUser: function() {
    let status = localStorage.getItem('loading_user')
    if (status == 'true') status = true
    if (status == 'false') status = false
    return status != null ? status : true
  },
  setLoadingUser: function(status) {
    localStorage.setItem('loading_user', status)
  },
  getToken: function() {
    return localStorage.getItem('token')
  },
  setToken: function(token) {
    localStorage.setItem('token', token)
  },
  getCredential: function() {
    return JSON.parse(localStorage.getItem('credential'))
  },
  setCredential: function(form) {
    localStorage.setItem('credential', JSON.stringify(form))
  },
  setSupplier: function(supplier) {
    var user = this.getUser()
    if (user) {
      if (user.company) {
        user.company.supplier = supplier
        this.setUser(user)
      }
    }
  },
  setCompany: function(company) {
    var user = localStorage.getItem('user')
    if (user) {
      user = JSON.parse(user)
      user.company = company
      this.setUser(user)
    }
  },
  getUser: function() {
    var user = localStorage.getItem('user')
    if (user) user = JSON.parse(user)
    return user
  },
  getCompanyId: function() {
    var user = localStorage.getItem('user')
    if (user == 'null') return null
    if (user) {
      user = JSON.parse(user)
      if (user.company) return user.company.id
    }

    return null
  },
  setUser: function (user) {
    console.log('Setting user ', user)
    localStorage.setItem('user', user ? JSON.stringify(user) : null)
  },
  setSelectedTheme: function(theme) {
    localStorage.setItem('selected_theme', theme ? JSON.stringify(theme) : null)
  },
  getSelectedTheme: function() {
    var theme = localStorage.getItem('selected_theme')
    if (theme) theme = JSON.parse(theme)
    return theme
  },
}
