<template>
    <div class="row">
      <div class="col-md-12">
        <el-card>
          <div class="card-header">
            <div class="category">Email Template Settings</div>
          </div>
          <div class="card-body row">
            
            <div class="col-sm-12 mt-2">
              <el-table class="table-striped" header-row-class-name="text-primary" :data="email_template_settings" style="width: 100%">
                <el-table-column class-name="td-actions" label="Settings Code">
                  <template v-slot="props">
                    {{ props.row.settings_code }}
                  </template>
                </el-table-column>
    
                <el-table-column  class-name="td-actions" label="Name">
                  <template v-slot="props">
                    {{ props.row.name }}
                  </template>
                </el-table-column>
    
                <el-table-column class-name="td-actions" label="Description">
                  <template v-slot="props">
                    {{ props.row.description }}
                  </template>
                </el-table-column>

                <el-table-column class-name="td-actions" label="Global Email Code">
                  <template v-slot="props">
                    {{ props.row.global_email_code }}
                  </template>
                </el-table-column>
    
                <el-table-column fixed="right" class-name="td-actions" label="Actions">
                  <template v-slot="props">
                    <el-button type="primary" @click="handleEdit(props.row)" size="small">Edit <i class="fa fa-pencil"></i></el-button>
                  </template>
                </el-table-column>
              </el-table>
            </div>
    
          </div>
        </el-card>
      </div>
      
      <el-drawer v-model="showForm" :show-close="true" size="50%" direction="rtl"
        :append-to-body="true">
        <EmailTemplateSettingsForm
            :email_template_data="selected"
            @update="handleClose"
        ></EmailTemplateSettingsForm>
    </el-drawer>
      
    </div>
    </template>
    
    <script>
    import { GlobalEmailTemplate } from '@/api_services/global_email_templates'
    import EmailTemplateSettingsForm from './EmailTemplateSettingsForm.vue'
    
    export default {
      components: {
        EmailTemplateSettingsForm
      },
      data() {
        return {
          showDelete: false,
          showForm: false,
          email_template_settings: [],
          selected: null,
          loading: false,
          term: null,
          page_loading: false,
        }
      },
      created: function () {
        this.getEmailTemplateSettings()
      },
      methods: {
        handleClose() {
          this.page_loading = false
          this.showDelete = false
          this.showForm = false

          this.getEmailTemplateSettings()
        },
        handleEdit(data) {
          this.selected = Object.assign({}, data)
          this.showForm = true
        },
        getEmailTemplateSettings() {
          this.loading = true
          this.email_template_settings = []
          GlobalEmailTemplate.loadEmailTemplateSettings({
              params: {
                all: 1
              }
            })
            .then(result => {
              if (result.data.email_template_settings) this.email_template_settings = result.data.email_template_settings
            })
            .catch(() => {})
            .finally(() => {
              this.loading = false
            })
        }
      }
    }
    </script>
    
    <style lang="scss"></style>
    