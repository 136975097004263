//import Vue from 'vue'
import { createApp, Vue, reactive, ref } from 'vue';
import App from './App.vue'
import router from './router/index.js'
import store from './store/index.js'
import VueToast from 'vue-toast-notification'

import '@/assets/css/theme_config.scss'

import ElementPlus from 'element-plus'

import {BootstrapVue3} from 'bootstrap-vue-3'
import 'bootstrap/dist/css/bootstrap.css'

import api from './api_services/axios'
import Vue3NativeNotification from 'vue3-native-notification'
import bFormSlider from 'vue-bootstrap-slider'

import 'bootstrap-slider/dist/css/bootstrap-slider.css'
import $ from 'jquery' // TODO: remove jquery
window.$ = window.jQuery = require('jquery') // TODO: remove jquery

import 'bootstrap-slider/dist/css/bootstrap-slider.css'
import "bootstrap/dist/css/bootstrap.min.css"
import 'vue-toast-notification/dist/theme-default.css'
import "vue-select/dist/vue-select.css";
import 'vue-search-select/dist/VueSearchSelect.css' // TODO: replace with choice?
import '@/assets/css/karumbi_font.css'
import '@/assets/css/nucleo-icons.css'
import '@/assets/css/nucleo-svg.css'
import '@/assets/scss/argon-dashboard.scss?v=3.0.1'
import '@/assets/css/custom.css'


import Cookie from '@/components/common/CookieComponent.vue'

import Spinner from '@/components/common/Spinner.vue'
import Loading from '@/components/common/LoadingComponent.vue'
import Back from '@/components/common/Back.vue'
import TextWithShowMore from '@/components/common/TextWithShowMoreComponent.vue'
import NotAllowed from '@/components/common/NotAllowedComponent.vue'
import PageNotFound from '@/components/common/PageNotFoundComponent.vue'
import Pagination from '@/components/common/Paginate.vue'
import Currency from '@/components/common/Currency.vue'
import CompanyLabel from '@/components/pages/card_transactions/company_label_component.vue'

import VueCookies from 'vue-cookies'
import { ModelListSelect } from 'vue-search-select' // TODO: replace with choice?
import { MultiListSelect } from 'vue-search-select' // TODO: replace with choice?
import GlobalComponents from './GlobalComponents';

import helpers from './mixins/helper'

const isToday = (date) => {
  const now = new Date()
  return date.getDate() === now.getDate() && date.getMonth() === now.getMonth() && date.getFullYear() === now.getFullYear()
}
import {ToastPlugin, useToast} from 'vue-toast-notification';
import 'vue-toast-notification/dist/theme-bootstrap.css';

import VueAwesomePaginate from "vue-awesome-paginate";
import "vue-awesome-paginate/dist/style.css";
import 'bootstrap/dist/js/bootstrap.bundle.js'

import '@/assets/css/tooltip.css'
import tooltip from "@/assets/js/tooltip.js";

import allowedDirective from './allowedDirective.js';

const app = createApp(App)
.use(router)
.use(ElementPlus)
.use(VueAwesomePaginate)
.use(BootstrapVue3)

import Antd from 'ant-design-vue';
app.use(Antd);
//import 'ant-design-vue/dist/antd.css';

import timeago from 'vue-timeago3'
app.use(timeago)

app.directive("tooltip", tooltip);

app.config.globalProperties.$helpers=helpers;

const toast = useToast();
app.config.globalProperties.$toast = toast;

app.use(ToastPlugin, {
    position: 'top-right'
})

api.interceptors.response.use(
  response => {
    if (response.status === 200 || response.status === 201) {
      return Promise.resolve(response)
    } else {
      return Promise.reject(response)
    }
  },
  error => {
    if (error.response?.status) {
      if (error.response.data.message) {
        if (error.response.data.message == 'Unauthenticated.') {
          toast.error('Please login to proceed')
          store.commit('saveUser', null)
          router.replace({
            path: '/login',
            query: { redirect: router.currentRoute.fullPath }
          })
          throw new axios.Cancel('Operation canceled by the user.')
        }
      }
      if (error.response.status === 500) {
        
        toast.open({
          position: 'top-right',
            duration: 3000,
            message: error.response.data.message,
            type: 'error',
        });
      }
      if (error.response.status === 400) {
        var errors = error.response.data
        for (var i in errors) {
          if (Array.isArray(errors[i])) {
            errors[i].forEach(element => {
              // toast.error(element)
              toast.open({
                position: 'top-right',
                  duration: 3000,
                  message: element,
                  type: 'error',
              })
            })
          }
        }
      }

      if (error.response.status === 404) {
        toast.open({
          position: 'top-right',
            duration: 3000,
            message: error.response.data.message || "Route Error",
            type: 'error',
        })
      }

      return Promise.reject(error)
    }
  }
)

const plugin = {
  install(app) {
    app.config.globalProperties.$helpers=helpers;
  }

}

import * as ElementPlusIconsVue from '@element-plus/icons-vue'

for (const [key, component] of Object.entries(ElementPlusIconsVue)) {
  app.component(key, component)
}

app.use(plugin)
app.config.globalProperties.$store=store;

app.use(bFormSlider)
app.use(Vue3NativeNotification, {
  requestOnNotify: true
})

const errors = reactive({errors: {} })

app.provide('errors', {
  get() {
    return errors.errors
  },
  set(value) {
    errors.errors = value
  }
})

import VueSelect from 'vue3-select'
import Typeahead from '@/components/common/Typeahead.vue'

import SimpleTypeahead from 'vue3-simple-typeahead';
import 'vue3-simple-typeahead/dist/vue3-simple-typeahead.css'; //Optional default CSS

app.use(VueToast, {
  position: 'top-right'
})
app.use(VueCookies)
app.use(GlobalComponents);
app.use(SimpleTypeahead);

app.component('Typeahead', Typeahead);
app.component('VSelect', VueSelect)
app.component("v-select", window['vue-select'])
app.component('model-list-select', ModelListSelect) // TODO: replace with choice?
app.component('model-multi-list-select', MultiListSelect) // TODO: replace with choice?
app.component('cookie', Cookie)
app.component('text-with-show-more', TextWithShowMore)
app.component('pagination', Pagination)
app.component('currency', Currency)
app.component('company-label', CompanyLabel)
app.component('back', Back)
app.component('not-allowed', NotAllowed)
app.component('page-not-found', PageNotFound)
app.component('loading', Loading)
app.component('spinner', Spinner)

app.use(store)

app.directive('allowed', allowedDirective);


import moment from 'moment'

app.config.globalProperties.$filters = {
  currencyFilter(value, opts = {}) {
    return Number(value).toLocaleString('en-GB', {
      style: 'currency',
      currency: 'GBP',
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
      ...opts
    })
  },
  formattedDateWithDash(value) {
    //for 12 hour format with AM PM
    //return moment(value).format('DD-MM-yyyy hh:mm A');

    //for 24 hour format
    return moment(value).format('DD-MM-yyyy HH:mm');
  },
  dateFormatFilter(value){
    if (!value) return
    try {
      return new Date(value).toLocaleDateString('en-GB', {
        year: 'numeric',
        month: 'short',
        day: 'numeric'
      })
    } catch (e) {
      return value
    }
  },
  monthFormatFilter(value){
    if (!value) return
    try {
      return new Date(value).toLocaleDateString('en-GB', {
        year: 'numeric',
        month: 'short'
      })
    } catch (e) {
      return value
    }
  },
  numberFormatFilter(value, opts = {}){
    return Number(value).toLocaleString('en-GB', {
      maximumFractionDigits: 16,
      ...opts
    })
  },
  datetimeFormatFilter(value, opts = {}){
    if (!value) return
    try {
      const dateValue = new Date(value)
      let format = { hour: 'numeric', minute: 'numeric', hour12: true }
      if (!(opts.hideSameDay && isToday(dateValue))) {
        format = { year: 'numeric', month: 'short', day: 'numeric', ...format }
      }
      return dateValue.toLocaleString('en-GB', format)
    } catch (e) {
      return value
    }
  }
    
}

import '@vueup/vue-quill/dist/vue-quill.snow.css';
import { QuillEditor } from '@vueup/vue-quill'
const globalOptions = {
  modules: {
    toolbar: ['bold', 'italic', 'underline']
  },
  placeholder: 'Type here',
  readOnly: false,
  theme: 'snow'
}
QuillEditor.props.globalOptions.default = () => globalOptions
app.component('QuillEditor', QuillEditor)

const globalState = reactive({
  loading: false, message: null
});

app.config.globalProperties.theme_config_drawer = false;

app.config.globalProperties.globalState = globalState;
app.provide('globalState', globalState);

app.config.globalProperties.$button_cooldown = 3

app.config.globalProperties.$button_send_test_mail_cooldown_min = 0
app.config.globalProperties.$button_send_test_mail_cooldown_sec = 0

app.config.globalProperties.$delay = function (ms = 100) {
  //Used to delay line of codes
  //example
  // console.log('load at 0')
  // await this.$delay(2000);
  // console.log('load after 2s')
  return new Promise(resolve => setTimeout(resolve, ms));
};

app.mount("#app");
