
<template>
    <blockquote v-for="data in reply_data" class="reply_blackqoute"><p class="text-sm mb-1">
      <b><u>{{ data.user }} said:</u></b>
      <button @click="removeReplyItem(data)" v-if="is_replying" type="button" class="btn btn-danger btn-sm pt-1 pb-1 ps-2 pe-2 mt-2 ms-2"><i class="fa fa-trash"></i></button>
      <template v-html="data.content"></template>
    </p>
    </blockquote>
  </template>
  
  <script>


    export default {
      emits: ["removeReplyItem"],
      props: {
        replying_content: {
          type: Object,
          required: false,
          default: ""
        },
        is_replying: {
          type: Boolean,
          required: false,
          default: true
        },
      },
      components: {
        
      },
      data() {
        return {
            reply_data: null
        }
      },
      created: function () {
        if(this.replying_content){
          this.reply_data = JSON.parse(JSON.stringify(this.replying_content))
        }
      },
      watch: {
        replying_content: {
          handler(value){
            if(value){
              this.reply_data = JSON.parse(JSON.stringify(value))
            }
          },
          deep:true
        }
      },
      computed: {
        
      },
      methods: {
        removeReplyItem(data){
          this.reply_data = this.reply_data.filter(item => item.id !== data.id)
          this.$emit('removeReplyItem', {data: this.reply_data, id: data.id})
        }
      }
    }
  </script>
  