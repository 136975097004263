<template>
    <div>
        <el-button type="primary" @click="getPrevious()" v-if="previous_form">Fetch Previous Data</el-button>
    </div>
    <div class="card-body">
        <el-card>
            <div class="row">
                <div class="col-md-6">
                    <label>Lot number</label>
                    <br/><label style="color: #a9a9a9">For example, 3A</label>
                    <div :class="{ 'has-error-field': v.form.lot_number.$invalid }">
                        <el-input v-model="form.lot_number"></el-input>
                    </div>
                    <div v-if="v.form.lot_number.$invalid" class="error-text" >Lot numberis required</div>
                </div>
                <div class="col-md-6">
                    <label>Lot title</label>
                    <br/><label style="color: #a9a9a9">For example, 3A</label>
                    <div :class="{ 'has-error-field': v.form.lot_title.$invalid }">
                        <el-input v-model="form.lot_title"></el-input>
                    </div>
                    <div v-if="v.form.lot_title.$invalid" class="error-text" >Lot title is required</div>
                </div>
                <div class="col-md-12">
                    <el-input v-model="form.lot_description" type="textarea" :rows="3"></el-input>
                </div>
                <div class="row">
                    <div class="col-md-12">
                        <label>Lot value (estimated)</label>
                        <br/><label style="color: #a9a9a9">Maximum value including all possible extensions and options. For a framework lot, if its value is restricted, the sum of the values of all contracts awarded under it, or under the same open framework scheme. For example, 200000 or 417709.28.</label>
                    </div>
                    <div class="col-md-6">
                        <label>Excluding VAT (optional)</label>
                        <el-input v-model="form.excluding_vat"></el-input>
                    </div>
                    <div class="col-md-6">
                        <label>Including VAT (optional)</label>
                        <el-input v-model="form.including_vat"></el-input>
                    </div>
                    <div class="col-md-12">
                        <br/><label>Currency</label>
                        <el-select v-model="form.currency" filterable clearable>
                            <el-option v-for="(full_name, abbrev) in currency_choices" :value="abbrev" :label="abbrev + ': ' + full_name">{{ abbrev + ': ' + full_name }}</el-option>
                        </el-select>
                    </div>
                </div>
            </div>
        </el-card>
    </div>
    <span class="col-md-3 mb-3" v-if="is_not_submitted"><el-button type="success" :disabled="v.form.$invalid" @click="saveForm()">Save and go to Next Section</el-button></span>
 </template>
 
 <script>
    import { mapState } from 'vuex'
     import {
     useVuelidate
     } from '@vuelidate/core'
     import {
     required, numeric,
     requiredIf
     } from '@vuelidate/validators'
 
     const LOTS = 'LOTS';

     export default {
         props: ['previous_form', 'saved_form', 'is_not_submitted', 'currency_choices'],
         data() {
             return {
                form: {
                    
                },
             }
         },
        setup: () => ({
             v: useVuelidate()
        }),
        created() {
            if(this.saved_form) this.form = this.saved_form

        },
        computed: {
        ...mapState(['TPPNoticeTypes', 'TPPFormParts']),
        },
        watch: {
            saved_form: {
                handlers(form){
                    this.form = form
                },
                deep: true
            }
        },
        validations() {
            let rules = {
                form: {
                    
                }
            }
            return rules
        },
        methods: {
            saveForm() {
                this.$emit('save', LOTS, this.form)
            },
            getPrevious() {
                delete this.previous_form.id
                this.form = this.previous_form
            },
        }
     }
 </script>